import React, { useEffect, useState } from 'react';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
  IconButton,
  Grid,
  TextField,
} from '@material-ui/core';
import { Link, useLocation } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from '../../app/rootReducer';
import { StyledTableCell } from '../../components/StyledTableCell';
import { StyledTableRow } from '../../components/StyledTableRow';

import AddIcon from '@mui/icons-material/Add';

import formStyles from '../../assets/jss/components/formStyles';
import EditIcon from '@mui/icons-material/Edit';
import { Autocomplete } from '@mui/material';
import { FiRefreshCw} from 'react-icons/fi';
import { MdOutlineDelete } from 'react-icons/md';
import { setSuccessMsg } from '../../slices/alertSlice';
import * as REQUESTS from '../../api/studentRequests';
import { getEntryLogList, getOrganisationList } from '../../api/programManagerRequests';
import { ENTRY_LOG_LIST } from '../../constants/routes';
import { superAdminRoutes } from '../.././components/DashboardMenu/MenuRoutes/superAdminRoutes';

interface IEntryLogListProps {}

const useStyles = makeStyles(formStyles);

const EntryLogList: React.FC<IEntryLogListProps> = ({}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const location = useLocation();

  const { student } = useSelector((state: RootState) => {
    return {
      student: state.student.student,
      //entryLog: state.entryLog.entryLog,
    };
  }, shallowEqual);
  const [rpage, setPage] = useState(1);
  const [totalRecipe, setTotalRecipe] = useState(1);
  const [recipesPage, setRecipesPage] = useState(1);
  const [sizes, setSizes] = useState(10);
  
  //const sizes = 10;

    useEffect (()=>{
      async function fetchMyAPI() {
        if(user_id)
        {
          const orgDatanxt = await getEntryLogList(rpage,sizes);
            if(orgDatanxt)
            {
              setRecipesPage(orgDatanxt.data.page);
              setSizes(orgDatanxt.data.size);
              setTotalRecipe(orgDatanxt.data.total);
              setEntryLog(orgDatanxt.data.data)
    
            }
         
        }
      }
      fetchMyAPI()
        return () => {};
  },[ rpage ])   

  const isReport = superAdminRoutes;
console.log("report name==>",isReport[1].children);

  localStorage.setItem('urlRoute', "parent_admins");
  const [orgarr,setorgarr]=useState(student);
  const user_id = localStorage.getItem('user_id');
  const [orgid,setOrgId]=useState("");
  const [entrylog,setEntryLog]=useState([]);
  const [userarr_nxt,setuserarrNxt]=useState([]);
  // const [orgid,setOrgId]=useState("");
  // const [orgid,setOrgId]=useState("");
  // const [orgid,setOrgId]=useState("");
  
 // useEffect(() => {
    //   async function fetchMyAPI() {
    //   if(user_id)
    //   {
    //     const subData = await getStudentsEntryLogs();
    //     if(subData)
    //     {
    //       setEntryLogs(subData)
    //     }
    //   }
    // }
    // fetchMyAPI()
    //   return () => {};
    // }, []);
  async function getOrganization()
  {
  const userarrNext = await getOrganisationList();
        if(userarrNext)
        {
          setuserarrNxt(userarrNext)

        }
    }
    console.log("Entry Logs--->"+JSON.stringify(entrylog))
  //console.warn("Entry log:"+entryLog);
  let renderedLogs;
  // if(userarr_nxt)
  // {
  //             if (userarr_nxt.length > 0) {
  //               renderedLogs = userarr_nxt
  //                 .map((user:any) => {
  //                   return {
  //                     label: user.name,
  //                     value: user.id,
  //                     imageUrl: "",
  //                   };
  //                 });
  //             } else {
  //               renderedLogs = [];
  //             }
  // }
  const totalNumberPages=(totalRecipe: number,sizes: number)=>{
    let pagesr =[];
    for(let x=1;x<=totalRecipe/sizes;x++)
    {
      pagesr.push(x);
    }
    return pagesr;
  }
  if (entrylog.length > 0) {
   let totalRecipe=entrylog.length;

    renderedLogs = entrylog.map((entrylog: any, index: any) => {
      const { for_user_id,
        entry_from_user_id, 
        entry_from_user_type, 
        entry_reason,
        entry_ip_address,
        createdAt,
        forusername,
        fromuser,
        browser_name,
        id } = entrylog;
       // console.warn("Users",entry_from_user_id.name);
        
      return (
        <StyledTableRow key={index}>

          <StyledTableCell component='th' scope='row'>
            {index + 1}
          </StyledTableCell>
          {/* <StyledTableCell>{referenceCode}</StyledTableCell> */}
          <StyledTableCell>{forusername?.name}</StyledTableCell>
          <StyledTableCell>{fromuser?.name}</StyledTableCell>
          <StyledTableCell>{entry_from_user_type}</StyledTableCell>
          <StyledTableCell>{entry_reason}</StyledTableCell>
          <StyledTableCell>{browser_name}</StyledTableCell>
          {/* <StyledTableCell>{entry_ip_address}</StyledTableCell> */}
          <StyledTableCell>{createdAt}</StyledTableCell>
          {/* <StyledTableCell>{updatedAt}</StyledTableCell> */}
         
        </StyledTableRow>
      );
    });
  } else if (entrylog.length === 0) { 
    renderedLogs = (
      <StyledTableRow>
        <StyledTableCell component='th' scope='row'>
          <Typography variant='subtitle2' className={classes.label}>
            No Records found.
          </Typography>
        </StyledTableCell>
      </StyledTableRow>
    );
  } else {
    renderedLogs = (
      <StyledTableRow>
        <StyledTableCell component='th' scope='row'></StyledTableCell>
      </StyledTableRow>
    );
  }
  
        return (
          <div>
            <Typography variant='h6'>Entry Logs</Typography>
          {/* <Typography variant='h6'>{isReport[1].children[0] }</Typography> */}
            <br />
           
            <TableContainer component={Paper}>
              <Table aria-label='customized table'>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>S.No.</StyledTableCell>
                    <StyledTableCell>User Name</StyledTableCell>
                    <StyledTableCell>Entry From User</StyledTableCell>
                    <StyledTableCell>Entry From User Type</StyledTableCell>
                    <StyledTableCell>Entry Reason</StyledTableCell>
                    <StyledTableCell>Entry Browser</StyledTableCell>
                    {/* <StyledTableCell>Ip Address</StyledTableCell> */}
                    <StyledTableCell>Created At</StyledTableCell>
                    {/* <StyledTableCell>Updated At</StyledTableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>{renderedLogs}</TableBody>
              </Table>
            </TableContainer>
            <br/>
      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='center'
      >
        <Grid item md={8} xs={8}>

        </Grid>
        <Grid item md={4} xs={4}>
          <nav aria-label="Page navigation example">
            <ul className="pagination justify-content-end">
              {recipesPage!==1 && <li className="page-item" onClick={()=>setPage(rpage - 1)}>
                <a className="page-link" href="javascript:void(null)" >Previous</a>
              </li>}
             {totalNumberPages(totalRecipe,sizes).map(pageNo => 
                <li className={`page-item ${pageNo===recipesPage ? 'active':''}`} key={pageNo} onClick={()=>setPage(pageNo)}>
                  <a className="page-link" href="javascript:void(null)">
                    {pageNo}
                  </a>
                </li>
              )} 
             
                {
                  totalRecipe > sizes?
                  recipesPage!==totalRecipe/sizes && <li className="page-item" onClick={()=>setPage(rpage + 1)}>
                  <a className="page-link" href="javascript:void(null)">Next</a>
                </li>:""
                }
              
            </ul>
          </nav>
        </Grid>
      </Grid>
          </div>
        );
};

export default EntryLogList;


import React, { useEffect, useState } from 'react';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  makeStyles,
  TextField,
  Typography,
  Dialog,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { FormikProps } from 'formik';
import { EnhancedAddVideoFormValues } from './EnhancedAddVideoForm';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../app/rootReducer';
import {
  addVideoToS3,
  setVideoUrl,
  setIsVideoModalOpen,
  deleteVideo,
} from '../../../slices/videoSlice';

import { DropzoneArea } from 'material-ui-dropzone';
import theme from '../../../app/theme';
import { DisplayFormikState } from '../../../components/DisplayFormikState';
import formStyles from '../../../assets/jss/components/formStyles';

interface IAddVideoFormProps {}

const useStyles = makeStyles(formStyles);

export const AddVideoForm: React.FC<
  IAddVideoFormProps & FormikProps<EnhancedAddVideoFormValues>
> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const debug = false;
  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleBlur,
    handleChange,
    setFieldValue,
    setTouched,
    setSubmitting,
  } = props;
  const { videoLoading, isVideoModalOpen, videoUrl } = useSelector(
    (state: RootState) => {
      return {
        videoLoading: state.video.loading,
        isVideoModalOpen: state.video.isVideoModalOpen,
        videoUrl: state.video.videoUrl,
      };
    },
    shallowEqual
  );

  localStorage.setItem('urlRoute', "questions");


  useEffect(() => {
    setFieldValue('videoUrl', videoUrl);
    return () => {};
  }, [videoUrl]);

  const handleClose = () => {
    console.log("from page"+values.id);

    dispatch(deleteVideo(values.id));
    dispatch(setIsVideoModalOpen(false));
  };

  const [firstnameerr, setfirstnameerr] = useState("");
  const [lastnameerr, setlastnameerr] = useState("");

  function chkValidation()
  {
    let flag=false;
    if(values.description==="")
    {
      setfirstnameerr("Description is required.");
      flag=true;
    }
   
  
   if(flag===false)
    {
      handleSubmit();
      dispatch(setIsVideoModalOpen(false));
    }  
  }

  const [disabled, setIsDisabled] = useState<boolean>(false);

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Dialog
          open={isVideoModalOpen}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            id="alert-dialog-title"
            style={{
              color: theme.palette.primary.main,
              backgroundColor: 'white',
            }}
          >
             <div className='row '>
                <div className='col-sm-6'></div>
                <div className='col-sm-4'></div>
              </div>
            <DialogContent
              style={{
                backgroundColor: 'white',
              }}
            > 
             
              <DialogContentText id="alert-dialog-description"></DialogContentText>
              <FormControl required className={classes.formControl}>
                <Typography variant="subtitle1" className={classes.label}>
                  Description{' '} <span style={{color:'red'}}>*</span>
                </Typography>
                <TextField
                  placeholder="Title"
                  type="text"
                  name="description"
                  variant="outlined"
                  size="small"
                  value={values.description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={(errors.description && touched.description) as boolean}
                  helperText={
                    errors.description &&
                    touched.description &&
                    errors.description
                  }
                  InputLabelProps={{
                    classes: {
                      root: classes.heading,
                      focused: classes.cssFocused,
                    },
                  }}
                />
                <span style={{color:'red',fontWeight:'',fontSize:'12px'}}>{firstnameerr}</span>

              </FormControl>
              <FormControl required className={classes.formControl}>
                <Typography variant="subtitle1" className={classes.label}>
                  Video URL{' '} 
                </Typography>
                {!videoUrl.startsWith('https') ? (
                  // <TextField
                  //   placeholder="Eg. https://youtu.be/url"
                  //   type="text"
                  //   name="videoUrl"
                  //   variant="outlined"
                  //   size="small"
                  //   value={values.videoUrl}
                  //   onChange={handleChange}
                  //   onBlur={handleBlur}
                  //   disabled={disabled}
                  //   error={(errors.videoUrl && touched.videoUrl) as boolean}
                  //   helperText={
                  //     errors.videoUrl && touched.videoUrl && errors.videoUrl
                  //   }
                  //   InputLabelProps={{
                  //     classes: {
                  //       root: classes.heading,
                  //       focused: classes.cssFocused,
                  //     },
                  //   }}
                  // />
                  <></>
                ) : (
                  <Typography variant="subtitle2">
                    <a href={videoUrl} target="_blank">
                      {videoUrl}
                    </a>
                  </Typography>
                )}
              </FormControl>
              <FormControl required className={classes.formControl}>
                <Typography variant="subtitle1" className={classes.label}>
                  Upload{' '} <span style={{color:'red'}}>* ( Video File must be smaller than 10MB. )</span> 
                </Typography>
                <DropzoneArea
                  acceptedFiles={['video/*']}
                  filesLimit={1}
                  maxFileSize={10042880}
                  onChange={(files) => {
                    if (files.length > 0) {
                      let file = files[0];
                      const formData = new FormData();
                      formData.append('file', file);
                      setIsDisabled(true);
                      dispatch(addVideoToS3(formData));
                    } else {
                      setIsDisabled(false);
                    }
                  }}
                  onDelete={(e) => {
                    dispatch(setVideoUrl(''));
                    setIsDisabled(true);
                  }}
                />
              </FormControl>
            </DialogContent>
            <DialogActions
              style={{
                backgroundColor: 'white',
              }}
            >
              <Button variant="text" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={(e) => {
                  chkValidation();
                  // handleSubmit();
                  // dispatch(setIsVideoModalOpen(false));
                }}
                disabled={videoLoading || values.videoUrl === ''}
                // fullWidth
              >
                Update
              </Button>
            </DialogActions>
          </DialogTitle>
        </Dialog>
        {debug ? <DisplayFormikState {...props} /> : ''}
      </form>
    </div>
  );
};

import store, { AppThunk } from './../app/store';
import * as REQUESTS from '../api/imageRequests';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { setErrorMsg } from './alertSlice';

import { LibraryImage } from './../constants/models/LibraryImage';
import { videoComplete } from './videoSlice';

export interface imageState {
  loading: boolean;
  error: string | null;
  image: LibraryImage | null;
  images: LibraryImage[];
  imageUrl: string;
  isImageModalOpen: boolean;
  imageBinary: string;
}

export const initialState: imageState = {
  loading: false,
  error: null,
  image: null,
  images: [],
  imageUrl: '',
  isImageModalOpen: false,
  imageBinary: '',
};

const imageSlice = createSlice({
  name: 'image',
  initialState,
  reducers: {
    imageStart(state) {
      state.loading = true;
      state.error = null;
    },
    setImage(state, action: PayloadAction<LibraryImage | null>) {
      state.image = action.payload;
    },
    setImages(state, action: PayloadAction<{ data: LibraryImage[] }>) {
      const { data } = action.payload;
      state.images = data.map((image) => image);
    },
    setIsImageModalOpen(state, action: PayloadAction<boolean>) {
      state.isImageModalOpen = action.payload;
    },
    setImageBinary(state, action: PayloadAction<string>) {
      state.imageBinary = action.payload;
    },
    setImageUrl(state, action: PayloadAction<string>) {
      state.imageUrl = action.payload;
    },
    imageFailure(state, action: PayloadAction<string | null>) {
      state.loading = false;
      state.error = action.payload ? action.payload : 'some error';
    },
    imageComplete(state) {
      state.loading = false;
    },
  },
});

export const {
  imageStart,
  setImage,
  setImages,
  setIsImageModalOpen,
  setImageBinary,
  setImageUrl,
  imageFailure,
  imageComplete,
} = imageSlice.actions;

export default imageSlice.reducer;

export const getImageById =
  (imageId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(imageStart());
      const image = await REQUESTS.getImageById(imageId);
      dispatch(setImage(image));
      dispatch(videoComplete());

      dispatch(imageComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(imageFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };

  export const getImagesForProgramRefresh = (user_id: string): AppThunk => async (dispatch) => {
    try {
      dispatch(imageStart());
      
  
         let program_de_id=user_id;
       
      
        if (program_de_id) {
        let programId = program_de_id;//program.id;
        const images = await REQUESTS.getImagesForProgram(programId);
        dispatch(setImages({ data: images }));
      }
      dispatch(imageComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(imageFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };
  

export const getImagesForProgram = (): AppThunk => async (dispatch) => {
  try {
    dispatch(imageStart());
    // const { program } = store.getState().program;
    //   const { parent } = store.getState().parent;
    //   const { counselor } = store.getState().counselor;
    //   const { programManager } = store.getState().programManager;

    //   const { superAdmin } = store.getState().superAdmin;

    const authlocal = localStorage.getItem('userRole');
    const user_id = localStorage.getItem('user_id');
    console.log("chk req"+user_id);
        let program_de_id=user_id;
      // if(superAdmin?.id)
      // {
      //   program_de_id=superAdmin.id;
      // } 
      // else if(parent?.id)
      // {
      //   program_de_id=parent.id;
      // }
      // else if(counselor?.id)
      // {
      //   program_de_id=counselor.id;
      // }
      // else if(programManager?.id)
      // {
      //   program_de_id=programManager.id;
      // }
      // else if(program?.id)
      // {
      //   program_de_id=program.id; 
      // }
    
      if (program_de_id) {
      let programId = program_de_id;//program.id;
      const images = await REQUESTS.getImagesForProgram(programId);
      dispatch(setImages({ data: images }));
    }
    dispatch(imageComplete());
  } catch (err: any) {
    if (err.response) {
      const { error } = err.response.data;
      dispatch(imageFailure(error));
      dispatch(setErrorMsg(error));
    }
  }
};

export const createImage =
  (description: string, imageUrl: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(imageStart());
      dispatch(setImageUrl(''));
      // const { program } = store.getState().program;
      // const { parent } = store.getState().parent;
      // const { counselor } = store.getState().counselor;
      // const { programManager } = store.getState().programManager;

      // const { superAdmin } = store.getState().superAdmin;

      const authlocal = localStorage.getItem('userRole');
    const user_id = localStorage.getItem('user_id');
    console.log("chk req"+user_id);
        let program_de_id=user_id;
      // if(superAdmin?.id)
      // {
      //   program_de_id=superAdmin.id;
      // } 
      // else if(parent?.id)
      // {
      //   program_de_id=parent.id;
      // }
      // else if(counselor?.id)
      // {
      //   program_de_id=counselor.id;
      // }
      // else if(programManager?.id)
      // {
      //   program_de_id=programManager.id;
      // }
      // else if(program?.id)
      // {
      //   program_de_id=program.id; 
      // }
    
      if (program_de_id) {
      let programId = program_de_id;//program.id;
        const image = await REQUESTS.createImage(
          description,
          imageUrl,
          programId
        );
        dispatch(setImage(image));
        dispatch(setIsImageModalOpen(true));
        dispatch(getImagesForProgram());
      }
      dispatch(imageComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(imageFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };

export const updateImage =
  (imageId: string, description: string, imageUrl: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(imageStart());
      await REQUESTS.updateImage(imageId, description, imageUrl);
      dispatch(setImageUrl(''));
      dispatch(getImagesForProgram());
      dispatch(imageComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(imageFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };

export const deleteImage =
  (id: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(imageStart());
      await REQUESTS.deleteImage(id);
      dispatch(getImagesForProgram());
      dispatch(imageComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(imageFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };

export const addLibraryImageToS3 =
  (formData: FormData): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(imageStart());
      let data = await REQUESTS.addLibraryImageToS3(formData);
      dispatch(setImageUrl(data));
      dispatch(imageComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(imageFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };

import React, { useEffect, useState } from 'react';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
  FormControl,
  IconButton,
  Grid,
} from '@material-ui/core';
import { Link, useHistory, useParams } from 'react-router-dom';
import formStyles from '../../../assets/jss/components/formStyles';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../app/rootReducer';
import { StyledTableCell } from '../../../components/StyledTableCell';
import { StyledTableRow } from '../../../components/StyledTableRow';
import { ADD_TASK, ADD_TASK_WITH_USER } from '../../../constants/routes';
import { TaskRow } from './TaskRow';
import { DeleteTaskModal } from './DeleteTaskModal';
import { ViewStudentDetails } from './ViewStudentDetails';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
//import AdapterDateFns from '@mui/lab/AdapterDateFns';
//import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { setSelectedDate, setStudentId } from '../../../slices/taskSlice';
import { getReportForStudent } from '../../../slices/studentSlice';
import { CircularProgress } from '@mui/material';
//import { DesktopDatePicker } from '@mui/x-date-pickers';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import PlusOneRoundedIcon from '@mui/icons-material/HdrPlusRounded';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import loaderImage from '../../../assets/images/loader_img.png';
interface IViewStudentTasksTableProps {}

const useStyles = makeStyles(formStyles);

const ViewStudentTasksTable: React.FC<IViewStudentTasksTableProps> = () => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const { tasks, student, selectedDate, reportUrl } = useSelector(
    (state: RootState) => {
      return {
        tasks: state.task.tasks,
        student: state.student.student,
        reportUrl: state.student.reportUrl,
        selectedDate: state.task.selectedDate,
      };
    },
    shallowEqual
  );

  useEffect(() => {
    return () => {};
  }, [reportUrl]);
  const { studentId } = useParams<{ studentId: string | undefined }>();
  let stid="";
  if(studentId)
  {
    stid=studentId;
    dispatch(setStudentId(stid))
  }
  console.log("task length-->"+tasks.length)
  let renderedTasks;
  if (tasks.length > 0) {

      renderedTasks = tasks.map((task, index) => {
        return <TaskRow task={task} index={index} />;
      });
      
  }
  else if (tasks.length === 0) {
    renderedTasks = (
      <StyledTableRow>
        <StyledTableCell></StyledTableCell>
        <StyledTableCell></StyledTableCell>
        <StyledTableCell></StyledTableCell>
        <StyledTableCell></StyledTableCell>
        <StyledTableCell component='th' scope='row'>
          <Typography variant='subtitle2' className={classes.label}>
            No task found.{' '}
            <Link to={ADD_TASK_WITH_USER} className={classes.link}>
              Add Task?
            </Link>
          </Typography>
        </StyledTableCell>
      </StyledTableRow>
    );
  } else {
    renderedTasks = (
      <StyledTableRow>
        <StyledTableCell component='th' scope='row'></StyledTableCell>
      </StyledTableRow>
    );
  }

  return (
    <>
       <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
         
            <Grid item md={2} xs={12} style={{padding:'10px'}}>
                  <Button
                    color='primary'
                    startIcon={
                      <ArrowBackIosIcon
                        fontSize='small'
                        style={{ paddingRight: '0px' }}
                      />
                    }
                    onClick={(e) => {
                      history.go(-1);
                    }}
                    size='small'
                  >
                    Back
                  </Button>
            </Grid>
            <Grid item md={2} xs={12}>

            </Grid>
            <Grid item md={4} xs={8}>

                {/* <FormControl required style={{ marginRight: '1rem' }}> */}
                  {/* <Typography variant='subtitle1' className={classes.label}>
                  Date
                </Typography> */}
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        label=''
                        inputFormat='MM/dd/yyyy'
                        value={new Date(selectedDate)}
                        disabled={loading}
                        onChange={(value: any) => {
                          //setFieldValue('date', value);
                          if (value !== null) {
                            //dispatch(setSelectedDate(new Date())); 
                            setLoading(true);
                            dispatch(setSelectedDate(value ?? selectedDate)); 
                            setLoading(false);
                          }
                        }}
                        maxDate={
                          new Date(new Date().setFullYear(new Date().getFullYear() + 1))
                        }
                        minDate={
                          new Date(new Date().setFullYear(new Date().getFullYear() - 1))
                        }
                        //disabled={isRecurring}
                        //minDate={new Date()}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            style={{ width: '100%' }}
                            size='small'
                            variant='outlined'
                          />
                        )}
                      />
                     
                    </LocalizationProvider>
                {/* </FormControl> */}
              </Grid>
              <Grid item md={2} xs={8}>

                <Button
                  className={classes.secondaryButton}
                  variant='contained'
                  color='primary'
                  disabled={loading}
                  onClick={async () => {
                    try {
                      if (student != null) {
                        setLoading(true);
                        await dispatch(getReportForStudent(selectedDate, student.id));
                        setLoading(false);
                        // if (reportUrl != null) {
                        // window.open(reportUrl, '_blank');
                        // }
                        console.log(reportUrl);
                      }
                    } catch (e) {
                      setLoading(false);
                      console.log(e);
                    }
                  }}
                  // fullWidth
                >
                  {loading && (
                    <CircularProgress
                      style={{ color: '#A3A3A3', marginRight: '0.5rem' }}
                      size='0.8rem'
                    />
                  )}{' '}
                  Get Report
                </Button>
              </Grid>
              <Grid item md={2} xs={8}>

                <Button
                  title='Add Task'
                  className={classes.secondaryButton}
                    style={{height:'40px',fontSize:'14px'}}
                    variant='outlined'
                    startIcon={<AddCircleOutlineIcon />}
                    onClick={ (e) => {
                      history.push(ADD_TASK_WITH_USER);
                    }}
                  >
                    Add Task
                </Button>
              </Grid>
      </Grid>       
      <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
         
            <Grid item md={12} xs={12} style={{padding:'10px'}}>
                <TableContainer component={Paper}>
                  <Table aria-label='customized table'>
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>
                          <strong>
                            Details
                          </strong>
                        </StyledTableCell>
                        <StyledTableCell>
                          Photo
                        </StyledTableCell>
                        <StyledTableCell colSpan={2}>
                          Task Details
                        </StyledTableCell>
                        <StyledTableCell >
                          Location
                        </StyledTableCell>
                        {/* <StyledTableCell>
                          Date
                        </StyledTableCell>
                        <StyledTableCell>
                          Task
                        </StyledTableCell> */}
                        <StyledTableCell>
                          Task Type
                        </StyledTableCell>
                        <StyledTableCell>
                          Status
                        </StyledTableCell>
                        <StyledTableCell>
                        Actions
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>{renderedTasks}</TableBody>
                  </Table>
                </TableContainer>
                <DeleteTaskModal />
            </Grid>
        </Grid>
    </>
  );
};

export default ViewStudentTasksTable;

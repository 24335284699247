import React, {useState,useEffect } from 'react';
import AsyncSelect from 'react-select/async';
import {
  Button,
  FormControl,
  InputLabel,
  InputAdornment,
  makeStyles,
  TextField,
  Typography,
  MenuItem,
  IconButton,
  Grid,
  Avatar,
  Select,
  OutlinedInput,
  TextareaAutosize
} from '@material-ui/core';

import formStyles from '../../../assets/jss/components/formStyles';
import { DropzoneDialog } from 'material-ui-dropzone';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { FormikProps } from 'formik';
import { RootState } from '../../../app/rootReducer';
import { EnhancedAddOrEditRecipeNutritionFormValues } from './EnhancedAddOrEditRecipeNutritionForm';
import { useHistory, useLocation } from 'react-router';
import { addRecipeIngredients, addRecipeSteps, ADD_RECIPE_INGREDIENT_VIEW, MY_RECIPES } from '../../../constants/routes';
//import { getDiagnosesForProgram } from '../../../slices/diagnosisSlice';
import { DisplayFormikState } from '../../../components/DisplayFormikState';
import { addRecipeToS3 } from '../../../api/recipeRequests';
import { useParams, } from 'react-router';
import AddRecipeStep from '../steps/AddRecipeStep';
import { getStepListForStudent } from '../../../slices/recipeStepsSlice';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { setSuccessMsg } from '../../../slices/alertSlice';



interface IAddOrEditRecipeNutritionFormProps {}

const useStyles = makeStyles(formStyles);

interface Option {
  label: string;
  value: string;
}

export const AddOrEditRecipeNutritionForm: React.FC<
  IAddOrEditRecipeNutritionFormProps & FormikProps<EnhancedAddOrEditRecipeNutritionFormValues>
> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  
  // useEffect(()=>{
  //   setrecipeIngredient();
  // },[])
  const isAddStudent = location.pathname === ADD_RECIPE_INGREDIENT_VIEW;
  const debug = false;
  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleBlur,
    handleChange,
    setFieldValue,
    getFieldMeta
  } = props;

  const { recipeIngridientLoading, recipeData  } = useSelector(
    (state: RootState) => {
      return {
       recipeNutrition: state.recipeNutrition.recipeNutrition,
        recipeIngridientLoading: state.recipeIngredient.loading,
        recipeData: state.recipe.recipe,
      };
    },
    shallowEqual
  );
  
  const userData = localStorage.getItem('userData');

  useEffect(() => {
    if(userData)
    {
     const userDt = JSON.parse(userData);
     console.log("call function----->"+userDt);
 
     //loadDataOnlyOnce(userData);
       // if(studentLoading)
       // {
             setFieldValue('servings',userDt.servings?userDt.servings:'')
             setFieldValue('caloriesPerServing',userDt.caloriesPerServing?userDt.caloriesPerServing:'')
             setFieldValue('totalCarbs',userDt.totalCarbs?userDt.totalCarbs:'')
             setFieldValue('protein',userDt.protein?userDt.protein:'')
             setFieldValue('totalFat',userDt.totalFat?userDt.totalFat:'')
             setFieldValue('totalSugar',userDt.totalSugar?userDt.totalSugar:'')
             setFieldValue('sodium',userDt.sodium?userDt.sodium:'')
             setFieldValue('recipeId',userDt.recipeId?userDt.recipeId:'')
            
      // }
    }
     
   }, []) 


  const customStyles = {
    control: (base: any, state: any) => ({
      ...base,
      background: '#FAFAFA',
      // match with the menu
      borderRadius: '8px',
      padding: '1.5px 1.5px',

      borderColor: state.isFocused ? '#0092BC' : '#C0C0C0',

      // Overwrittes the different states of border
      '&:hover': {
        // Overwrittes the different states of border
        borderColor: state.isFocused ? '#0092BC' : '#C0C0C0',
      },
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
    }),
    menu: (base: any) => ({
      ...base,
      background: '#FAFAFA',
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0,
    }),
    menuList: (base: any) => ({
      ...base,
      background: '#FAFAFA',
      // kill the white space on first and last option
    }),
  };
 
  //let rrid=useParams<{ recipeId: string }>();
  const { recipeId } = useParams<{ recipeId: string | undefined }>();
  let rcheck: string | number | readonly string[] | undefined;
  if(recipeId)
  {
    rcheck=recipeId;
    let formData = new FormData();  
    formData.append('recipeId', rcheck); 
  }
  function sethandlerecipeid(){
    setFieldValue('recipeId', rcheck);
  }

  let renderUnit=[];
  renderUnit=[
    { "label": "Kg",
    "value": "Kg"
  },
  { "label": "g",
  "value": "g"
  },
  { "label": "lb",
    "value": "lb"
  },
  { "label": "oz",
    "value": "oz"
  }];

  async function getSaveIndraft(id:string) {
    let crecipe=window.confirm("Do you want to save this recipe in draft.")
    if(crecipe)
    {
      history.push(MY_RECIPES);
      dispatch(setSuccessMsg(`Recipe saved in drafts successfully..!`));

    }
  }
  return ( 
     

    <div>
      <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
            <Grid item md={10} xs={12}>
                <Button
                    color='primary'
                    startIcon={
                      <ArrowBackIosIcon
                        fontSize='small'
                        style={{ paddingRight: '0px' }}
                      />
                    }
                    onClick={(e) => {
                      history.push(addRecipeIngredients(recipeId));
                    }}
                    size='small'
                  >
                    Back
                  </Button>
            </Grid>
            
        </Grid>
     
      <div style={{ margin: '0px 0px 32px' }}>
        
        <Typography variant='h4' className={classes.welcomeBackText}>
          {/* {isAddStudent ? 'Add Student to program' : 'Edit Counselor profile'} */}
          {isAddStudent ? 'Add Nutrition Facts' : 'Add Nutrition Facts'}
        </Typography>
        <Typography variant='body1' className={classes.loginText}>
          {/* {isAddStudent ? 'Enter student details' : ''} */}
          {isAddStudent ? 'Enter recipe details' : ''}
        </Typography>
      </div>
      <form onSubmit={handleSubmit}>
        
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
            
          <Grid item md={4} xs={12}>
            <FormControl
              required
              className={classes.formControl}
              style={{ width: '100%', paddingRight: '0.4em' }}
            >
              <Typography variant='subtitle1' className={classes.label}>
                Enter Servings
              </Typography>
            
              <TextField
                placeholder='Enter servings'
                type='text'
                name='servings'
                variant='outlined'
                size='small'
                value={values.servings}
                onChange={handleChange}
                onKeyUp={sethandlerecipeid}
                onBlur={handleBlur}
                error={(errors.servings && touched.servings) as boolean}
                helperText={errors.servings && touched.servings && errors.servings}
                InputLabelProps={{
                  classes: {
                    root: classes.heading,
                    focused: classes.cssFocused,
                  },
                }}
              />
            </FormControl>
          </Grid>
          <Grid item md={4} xs={12}>
            <FormControl
              required
              className={classes.formControl}
              style={{ width: '100%', paddingRight: '0.4em' }}
            >
              <Typography variant='subtitle1' className={classes.label}>
                Enter Calories Per Serving
              </Typography>
            
              <TextField
                placeholder='Enter calories Per Serving'
                type='text'
                name='caloriesPerServing'
                variant='outlined'
                size='small'
                value={values.caloriesPerServing}
                onChange={handleChange}
                onKeyUp={sethandlerecipeid}
                onBlur={handleBlur}
                InputLabelProps={{
                  classes: {
                    root: classes.heading,
                    focused: classes.cssFocused,
                  },
                }}
              />
            </FormControl>
          </Grid>
          <Grid item md={4} xs={12}>
            <FormControl
              required
              className={classes.formControl}
              style={{ width: '100%', paddingRight: '0.4em' }}
            >
              <Typography variant='subtitle1' className={classes.label}>
                Enter Total Carbs
              </Typography>
            
              <TextField
                placeholder='Enter Total Carbs'
                type='text'
                name='totalCarbs'
                variant='outlined'
                size='small'
                value={values.totalCarbs}
                onChange={handleChange}
                onKeyUp={sethandlerecipeid}
                onBlur={handleBlur}
              
                InputLabelProps={{
                  classes: {
                    root: classes.heading,
                    focused: classes.cssFocused,
                  },
                }}
              />
            </FormControl>
          </Grid>
          <Grid item md={4} xs={12}>
            <FormControl
              required
              className={classes.formControl}
              style={{ width: '100%', paddingRight: '0.4em' }}
            >
              <Typography variant='subtitle1' className={classes.label}>
                Enter Protein 
              </Typography>
            
              <TextField
                placeholder='Enter Protein '
                type='text'
                name='protein'
                variant='outlined'
                size='small'
                value={values.protein}
                onChange={handleChange}
                onKeyUp={sethandlerecipeid}
                onBlur={handleBlur}
                
                InputLabelProps={{
                  classes: {
                    root: classes.heading,
                    focused: classes.cssFocused,
                  },
                }}
              />
            </FormControl>
          </Grid>
          <Grid item md={4} xs={12}>
            <FormControl
              required
              className={classes.formControl}
              style={{ width: '100%', paddingRight: '0.4em' }}
            >
              <Typography variant='subtitle1' className={classes.label}>
                Enter Total Fat
              </Typography>
            
              <TextField
                placeholder='Enter Total Fat'
                type='text'
                name='totalFat'
                variant='outlined'
                size='small'
                value={values.totalFat}
                onChange={handleChange}
                onKeyUp={sethandlerecipeid}
                onBlur={handleBlur}
                
                InputLabelProps={{
                  classes: {
                    root: classes.heading,
                    focused: classes.cssFocused,
                  },
                }}
              />
            </FormControl>
          </Grid>
          <Grid item md={4} xs={12}>
            <FormControl
              required
              className={classes.formControl}
              style={{ width: '100%', paddingRight: '0.4em' }}
            >
              <Typography variant='subtitle1' className={classes.label}>
                Enter Total Sugar
              </Typography>
            
              <TextField
                placeholder='Enter Total Sugar'
                type='text'
                name='totalSugar'
                variant='outlined'
                size='small'
                value={values.totalSugar}
                onChange={handleChange}
                onKeyUp={sethandlerecipeid}
                onBlur={handleBlur}
               
                InputLabelProps={{
                  classes: {
                    root: classes.heading,
                    focused: classes.cssFocused,
                  },
                }}
              />
            </FormControl>
          </Grid>
          <Grid item md={4} xs={12}>
            <FormControl
              required
              className={classes.formControl}
              style={{ width: '100%', paddingRight: '0.4em' }}
            >
              <Typography variant='subtitle1' className={classes.label}>
                Enter Sodium
              </Typography>
            
              <TextField
                placeholder='Enter Sodium'
                type='text'
                name='sodium'
                variant='outlined'
                size='small'
                value={values.sodium}
                onChange={handleChange}
                onKeyUp={sethandlerecipeid}
                onBlur={handleBlur}
               
                InputLabelProps={{
                  classes: {
                    root: classes.heading,
                    focused: classes.cssFocused,
                  },
                }}
              />
            </FormControl>
          </Grid>
          
         
         
        </Grid>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
             <Grid item md={3} xs={12}>
            <Button
              className={classes.secondaryButton}
              variant='contained'
              color='primary'
              type='submit'
               //disabled={recipeNu}
              // fullWidth
             // onClick={history.push(addRecipeSteps(recipeId))}
            >
              {isAddStudent ? 'Add' : 'Add'} Nutrition Facts
            </Button>
          </Grid>
          <Grid item md={3} xs={12}>
              {recipeData && recipeData.isCompleted===false?
                <Button
                className={classes.secondaryButton}
                variant='contained'
                color='primary'
                type='button'
                disabled={recipeIngridientLoading}
                // fullWidth
                onClick={() => {
                  getSaveIndraft(recipeData.id);
                }}
              >
                Save in draft 
              </Button>:""
              }
          </Grid>
          <Grid item md={3} xs={12}>
                <Button
                className={classes.secondaryButton}
                variant='contained'
                color='primary'
                type='button'
                // fullWidth
                onClick={ async () => {
                  if(recipeId)
                  {
                    await dispatch(getStepListForStudent(recipeId));
                  }
                  history.push(addRecipeSteps(recipeId));
                }}
               
              >
                Next
              </Button>
              
          </Grid>
        </Grid>
        {debug ? <DisplayFormikState {...props} /> : ''}
      </form>
     
    </div>
  );
};

import store, { AppThunk } from '../app/store';
import { viewSteps,ADD_RECIPE, editNutrintion, addRecipeSteps } from './../constants/routes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { setErrorMsg, setSuccessMsg } from './alertSlice';
import * as REQUESTS from '../api/recipeStepRequest';
//import { recipe } from '../constants/models/recipe';
import { RecipeStep } from '../constants/models/RecipeStep';
import { History } from 'history';
import { useParams } from 'react-router';


export interface recipeStepState {
  loading: boolean;
  error: string | null;
  recipeStep: RecipeStep | null;
  recipeSteps: RecipeStep[];
  stepUrl: string;
  isrecipeStepModalOpen: boolean;
}

export const initialState: recipeStepState = {
  loading: false,
  error: null,
  recipeStep: null,
  recipeSteps: [],
  stepUrl: '',
  isrecipeStepModalOpen: false,
};

const recipeStepSlice = createSlice({
  name: 'recipeStep',
  initialState,
  reducers: {
    recipeStepStart(state) {
      state.loading = true;
      state.error = null;
    },
    setrecipeStep(state, action: PayloadAction<RecipeStep | null>) {
      state.recipeStep = action.payload;
    },
    setrecipeSteps(state, action: PayloadAction<{ data: RecipeStep[] }>) {
      const { data } = action.payload;
      state.recipeSteps = data.map((recipeStep) => recipeStep);
      console.warn("state-->"+state.recipeSteps);
    },
    setIsrecipeStepModalOpen(state, action: PayloadAction<boolean>) {
      state.isrecipeStepModalOpen = action.payload;
    },
    setrecipeStepUrl(state, action: PayloadAction<string>) {
      state.stepUrl = action.payload;
    },
    recipeStepFailure(state, action: PayloadAction<string | null>) {
      state.loading = false;
      state.error = action.payload ? action.payload : 'some error';
    },
    recipeStepComplete(state) {
      state.loading = false;
    },
  },
});

export const {
  recipeStepStart,
  setrecipeStep,
  setrecipeSteps,
  setIsrecipeStepModalOpen,
  setrecipeStepUrl,
  recipeStepFailure,
  recipeStepComplete,
} = recipeStepSlice.actions;

export default recipeStepSlice.reducer;

export const getrecipeStepById =
  (recipeStepId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(recipeStepStart());
      const recipeStep = await REQUESTS.getrecipeStepById(recipeStepId);
      dispatch(setrecipeStep(recipeStep));
      dispatch(recipeStepComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(recipeStepFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };

  export const getrecipeStepByIdRefresh =
  (recipeStepId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(recipeStepStart());
      const recipeStep = await REQUESTS.getrecipeStepById(recipeStepId);
      dispatch(setrecipeStep(recipeStep));
      dispatch(recipeStepComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(recipeStepFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };

export const getrecipeStepsForProgram = (): AppThunk => async (dispatch) => {
  try {
    dispatch(recipeStepStart());
    const { program } = store.getState().program;
    const { parent } = store.getState().parent;
    let program_de_id="";
      if(parent?.id)
      {
        program_de_id=parent.id;
      }
      else if(program?.id)
      {
        program_de_id=program.id;
      }
      console.log("program id ---> "+program_de_id);
      if (program_de_id) {
      let programId = program_de_id;//program.id;
      const recipeSteps = await REQUESTS.getrecipeStepsForProgram(programId);
      dispatch(setrecipeSteps({ data: recipeSteps }));
      
    }
    dispatch(recipeStepComplete());
  } catch (err: any) {
    if (err.response) {
      const { error } = err.response.data;
      dispatch(recipeStepFailure(error));
      dispatch(setErrorMsg(error));
    }
  }
};
export const getStepListForStudent =
  (recipeId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(recipeStepStart());
     
      const recipeSteps = await REQUESTS.getParentStepList(
        recipeId
      );
      console.warn(recipeSteps);
      dispatch(setrecipeSteps({ data: recipeSteps }));

      dispatch(recipeStepComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(recipeStepFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };
export const createrecipeStep =
  ( stepDescription: string,
    stepTitle: string,
    stepUrl: string,
    recipeId:string,
    history: History
   ): AppThunk =>
  async (dispatch) => {
    // const { recipeId } = useParams<{ recipeId: string | undefined }>();
    try {
      dispatch(recipeStepStart());
      let program_de_id="";
      const { program } = store.getState().program;
      const { parent } = store.getState().parent;
      let {brosname } = store.getState().auth;
      let brosdevicename = 'Daily Active Users';
      const user_id = localStorage.getItem('user_id');
      console.log("chk user-->"+user_id)
      if(user_id)
      {
        program_de_id=user_id;
      }
        if (program_de_id) {
        let programId = program_de_id;//program.id;
        
        const recipeStep = await REQUESTS.createrecipeStep(
          stepDescription,
          stepTitle,
          stepUrl,
          brosname,
          brosdevicename,
          programId,
          recipeId
        );
        //dispatch(setrecipeStep(recipeStep));
        
        dispatch(setSuccessMsg(`Step added successfully..!`));
      //}
      dispatch(getStepListForStudent(recipeId));
      dispatch(setrecipeStepUrl(''));
    }
      dispatch(recipeStepComplete());


    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(recipeStepFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };

export const updaterecipeStep =
  (
    id: string | undefined,
    stepTitle: string,
    stepUrl: string,
    stepDescription: string,
    recipeId: string,
    history: History
    ): AppThunk =>
  async (dispatch) => {
    try {
      
      console.warn("check id------>"+id);
      dispatch(recipeStepStart());
      await REQUESTS.updaterecipeStep(
        id,  
        stepTitle,
        stepUrl,
        stepDescription,
        recipeId
        );
        dispatch(recipeStepComplete());
        history.push(addRecipeSteps(recipeId));

    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(recipeStepFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };

export const deleterecipeStep =
  (id: string,recipeId: string,history: History): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(recipeStepStart());
      await REQUESTS.deleterecipeStep(id,recipeId);
      console.warn("get recipe id----->"+recipeId);
      dispatch(getStepListForStudent(recipeId));
      dispatch(recipeStepComplete());

      //history.push(viewSteps(recipeId));
     
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(recipeStepFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };

export const addrecipeStepToS3 =
  (formData: FormData): AppThunk =>
  async (dispatch) => {
    try {
      console.warn(formData);

      dispatch(recipeStepStart());
      const { program } = store.getState().program;
      const { parent } = store.getState().parent;
      let program_de_id="";
        if(parent?.id)
        {
          program_de_id=parent.id;
        }
        else if(program?.id)
        {
          program_de_id=program.id;
        }
        console.log("program id ---> "+program_de_id);
        if (program_de_id) 
        {
          formData.append('programId', program_de_id);
          let data = await REQUESTS.addrecipeStepToS3(formData);
            
          dispatch(setrecipeStepUrl(data));
        } 
      dispatch(recipeStepComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(recipeStepFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };

  export const updateStepPositionFromWeb = (stepPostision: RecipeStep[],history: History
    ): AppThunk => async (dispatch) => {
    try {
      dispatch(recipeStepStart());
      const { program } = store.getState().program;
      const { parent } = store.getState().parent;
      let program_de_id="";
        if(parent?.id)
        {
          program_de_id=parent.id;
        }
        else if(program?.id)
        {
          program_de_id=program.id;
        }
        console.log("program id ---> "+program_de_id);
        if (program_de_id) {
        let programId = program_de_id;//program.id;
        
        const recipeSteps = await REQUESTS.updateStepPositionFromWeb(stepPostision,programId);
        //dispatch(setrecipeSteps({ data: recipeSteps }));
        if(recipeSteps.length>0)
        {
          dispatch(setSuccessMsg(`Recipe updated  successfully..!`));


        }
      }
      dispatch(recipeStepComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(recipeStepFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };
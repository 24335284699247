import React from 'react';
import {
  ADD_DOCUMENTS,
  ADD_IMAGES,
  ADD_QUESTIONS,
  ADD_STUDENT,
  ADD_TASK,
  ADD_VIDEOS,
  ADD_RECIPE,
  MESSAGING,
  STUDENT_PROFILES,
  viewStudentTask,
  PARENT_STUDENT_PROFILES,
  ADD_RECIPE_TAG, 
  EDIT_PARENT,
  COUNSELOR_PROFILES,
  PARENT_COUNSELOR_PROFILES,
  PARENTUSERGUIDEVIDEO_DASHBOARD
} from '../../../constants/routes';
import FaceIcon from '@mui/icons-material/Face';
import AdminIcon from '@mui/icons-material/AdminPanelSettingsTwoTone';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import AddPhoto from '@mui/icons-material/AddPhotoAlternate';
import AddVideo from '@mui/icons-material/VideoLibrary';
import IcecreamIcon from '@mui/icons-material/Icecream';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import AddTaskIcon from '@mui/icons-material/AddTask';
import { Chat } from '@material-ui/icons';
import AccountBoxIcon from '@mui/icons-material/AccountBox';


const authlocal = localStorage.getItem('userRole');

export const parentRoutes = [
  {
    

    id: 'Admin',
    children: [
      {
        // id: 'Counselor Profiles',
        id: 'How To Use NFlyte',
        route: PARENTUSERGUIDEVIDEO_DASHBOARD,
        icon: <AddVideo />,
      },
      {
        // id: 'Counselor Profiles',
        id: 'Admin Profiles',
        route: PARENT_COUNSELOR_PROFILES,
        icon: <AccountBoxIcon />,
      },
      {
        // id: 'Student Profiles',
        id: 'User Profiles',
        route: PARENT_STUDENT_PROFILES,
        // route: COUNSELOR_STUDENT_PROFILES,
        icon: <FaceIcon />,
      },
      // {
      //   // id: 'Student Profiles',
      //   id: 'Admin Profiles',
      //   route: EDIT_PARENT,
      //   // route: COUNSELOR_STUDENT_PROFILES,
      //   icon: <AdminIcon />,
      // },
      // {
      //   id: 'Messaging',
      //   route: MESSAGING,
      //   icon: <Chat />,
      // },
      // {
      //   id: 'Add Task',
      //   route: ADD_TASK,
      //   icon: <AddTaskIcon />,
      // },
      // {
      //   id: 'Child Tasks',
      //   route: ADD_TASK,
      //   icon: <AddTaskIcon />,
      // },
      // {
      //   id: 'Cook Book',
      //   route: ADD_RECIPE,
      //   icon: <i className="fas fa-bacon"></i>,

      // },
      // {
      //   id: 'Cook Book Tags',
      //   route: ADD_RECIPE_TAG,
      //   icon: <i className="fa fa-tag"></i>,
      // },
    ],
  },
  {
    id: 'Library',
    children: [
      {
        id: 'Questions',
        route: ADD_QUESTIONS,
        icon: <QuestionAnswerIcon />,
      },
      {
        id: 'Images',
        route: ADD_IMAGES,
        icon: <AddPhoto />,
      },
      {
        id: 'Videos',
        route: ADD_VIDEOS,
        icon: <AddVideo />,
      },
      {
        id: 'Documents',
        route: ADD_DOCUMENTS,
        icon: <UploadFileIcon />,
      },
      
    ],
  },
];

import { updateDetailsForGraphQlUser } from './authSlice';
import { USER_ROLE } from './../constants/userRoles';
import {
  PROGRAM_MANAGER_INTAKE,
  PROGRAM_MANAGER_DASHBOARD,
  DASHBOARD_ROUTE,
  COUNSELOR_DASHBOARD,
  PROGRAM_MANAGER_LIST,
  VIEW_ORGANISATION_DETAILS,
} from './../constants/routes';
import store, { AppThunk } from './../app/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from './../constants/models/User';
import { History } from 'history';

import * as REQUESTS from '../api/programManagerRequests';
import * as COUNSELOR_REQUESTS from '../api/counselorRequests';
import { setProgram } from './programSlice';
import { setSuccessMsg, setErrorMsg } from './alertSlice';
import { setCounselor } from './counselorSlice';

export interface programManagerState {
  loading: boolean;
  error: string | null;
  hasProgram: boolean;
  programManager: User | null;
  programManagers: User[];
}

export const initialState: programManagerState = {
  loading: false,
  error: null,
  hasProgram: false,
  programManager: null,
  programManagers: [],
};

const programManagerSlice = createSlice({
  name: 'programManager',
  initialState,
  reducers: {
    programManagerStart(state) {
      state.loading = true;
      state.error = null;
    },
    setHasProgram(state, action: PayloadAction<boolean>) {
      state.hasProgram = action.payload;
    },
    setProgramManger(state, action: PayloadAction<User | null>) {
      state.programManager = action.payload;
    },
    programManagerFailure(state, action: PayloadAction<string | null>) {
      state.loading = false;
      state.error = action.payload ? action.payload : 'some error';
    },
    programManagerComplete(state) {
      state.loading = false;
    },
  },
});

export const {
  programManagerStart,
  setHasProgram,
  setProgramManger,
  programManagerComplete,
  programManagerFailure,
} = programManagerSlice.actions;

export default programManagerSlice.reducer;

export const getProgramManagerById =
  (programMangerId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(programManagerStart());
      const programManager = await REQUESTS.getProgramManagerById(
        programMangerId
      );
      dispatch(setProgramManger(programManager));
      dispatch(programManagerComplete());
    } catch (error: any) {
      if (error.response) {
        const { data, status } = error.response;
        dispatch(programManagerFailure(data));
      }
    }
  };
  export const getProgramManagerLogById =
  (programMangerId: string, brosname: string,brosdevicename: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(programManagerStart());
      const programManager = await REQUESTS.getProgramManagerLogById(
        programMangerId,
        brosname,
        brosdevicename
      );
     // dispatch(setProgramManger(programManager));
      dispatch(programManagerComplete());
    } catch (error: any) {
      if (error.response) {
        const { data, status } = error.response;
        dispatch(programManagerFailure(data));
      }
    }
  };

  export const getProgramManagerByIdRefresh =
  (programMangerId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(programManagerStart());
      const programManager = await REQUESTS.getProgramManagerById(
        programMangerId
      );
      dispatch(setProgramManger(programManager));
      localStorage.setItem('userData', JSON.stringify(programManager));
      //localStorage.setItem('userRole', JSON.stringify(programManager));
      dispatch(programManagerComplete());
    } catch (error: any) {
      if (error.response) {
        const { data, status } = error.response;
        dispatch(programManagerFailure(data));
      }
    }
  };

  export const createProgramManager =
  (id: string, name: string, email: string, schoolName: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(programManagerStart());
      let text = email;
            email = text.toLowerCase();
      const programManager = await REQUESTS.createProgramManager(
        id,
        name,
        email,
        schoolName
      );
      dispatch(programManagerComplete());
    } catch (error: any) {
      if (error.response) {
        const { data, status } = error.response;
        dispatch(programManagerFailure(data));
      }
    }
  };

  export const createOrganisation =
  (phoneno: string, name: string, email: string, programname: string, tellus: string, firstchk: boolean,countryCode:string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(programManagerStart());
      let text = email;
            email = text.toLowerCase();
      const programManager = await REQUESTS.createOrganisation(
        phoneno,
        name,
        email,
        programname,
        tellus,
        firstchk,
        countryCode
      );
      dispatch(programManagerComplete());
    } catch (error: any) {
      if (error.response) {
        const { data, status } = error.response;
        dispatch(programManagerFailure(data));
      }
    }
  };

export const updateProgramManager =
  (
    id: string | undefined,
    name: string,
    email: string,
    address: string,
    street: string,
    city: string,
    state: string,
    country: string,
    postalCode: number,
    phoneno: string,
    countryCode: string,
    // about: string,
    imageUrl: string | undefined,
    history: History
  ): AppThunk =>
  async (dispatch, getStore) => {
    try {
      dispatch(programManagerStart());
      let text = email;
      email = text.toLowerCase();
      const programManager = await REQUESTS.updateProgramManager(
        id!,
        name,
        email,
        address,
        street,
        city,
        state,
        country,
        postalCode,
        phoneno,
        countryCode,
        // about,
        imageUrl
      );
      const { role } = getStore().auth;
      if (role === USER_ROLE.PROGRAM_MANAGER) {
        // const { graphQlUser } = getStore().auth;
        // dispatch(
        //   updateDetailsForGraphQlUser(
        //     id!,
        //     email,
        //     name,
        //     imageUrl ?? graphQlUser.imageUrl,
        //     email,
        //     graphQlUser.about
        //   )
        // );
      }
      dispatch(setSuccessMsg(`Profile updated!`));
      dispatch(getProgramManagerById(id!));

      const req_from = localStorage.getItem('req_from');
      if(req_from==="super_admin")
      {
        history.push(PROGRAM_MANAGER_LIST);

        
      }
      else
      {
        history.push(PROGRAM_MANAGER_DASHBOARD);
      }

      dispatch(programManagerComplete());
      localStorage.setItem('req_from',"");

    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        // dispatch(studentFailure(error));
        dispatch(setErrorMsg(error));
        dispatch(programManagerFailure(error));
      }
    }
  };

  export const updateProgramManagerBySuperAdmin =
  (
    id: string | undefined,
    name: string,
    email: string,
    address: string,
    street: string,
    city: string,
    state: string,
    country: string,
    postalCode: number,
    phoneno: string,
    countryCode: string,
    // about: string,
    imageUrl: string | undefined,
    history: History
  ): AppThunk =>
  async (dispatch, getStore) => {
    try {
      dispatch(programManagerStart());
      let text = email;
      email = text.toLowerCase();
      const programManager = await REQUESTS.updateProgramManager(
        id!,
        name,
        email,
        address,
        street,
        city,
        state,
        country,
        postalCode,
        phoneno,
        countryCode,
        // about,
        imageUrl
      );
      const { role } = getStore().auth;
      if (role === USER_ROLE.PROGRAM_MANAGER) {
        // const { graphQlUser } = getStore().auth;
        // dispatch(
        //   updateDetailsForGraphQlUser(
        //     id!,
        //     email,
        //     name,
        //     imageUrl ?? graphQlUser.imageUrl,
        //     email,
        //     graphQlUser.about
        //   )
        // );
      }
      localStorage.setItem('userData', "");

      dispatch(setSuccessMsg(`Program Manager updated!`));
     // dispatch(getProgramManagerById(id!));

      history.push(PROGRAM_MANAGER_LIST);

      dispatch(programManagerComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        // dispatch(studentFailure(error));
        dispatch(setErrorMsg(error));
        dispatch(programManagerFailure(error));
      }
    }
  };

  export const createProgramManagerBySuperAdmin =
  (
    id: string,
    name: string,
    email: string,
    history: History
  ): AppThunk =>
  async (dispatch, getStore) => {
    try {
      dispatch(programManagerStart());
      let text = email;
      email = text.toLowerCase();
      let {brosname } = store.getState().auth;
      let brosdevicename = 'Daily Active Users';
      const user_id = localStorage.getItem('user_id');
      let program_id='';
      if(user_id)
      {
        program_id=user_id;
      }
      const programManager = await REQUESTS.createProgramManagerBySuperAdmin(
        id,
        name,
        email,
        program_id,
        brosname,
        brosdevicename,
      );
      console.warn("program manager Logs Data===>",programManager);
      
      const { role } = getStore().auth;
      if (role === USER_ROLE.PROGRAM_MANAGER) {
        // const { graphQlUser } = getStore().auth;
        // dispatch(
        //   updateDetailsForGraphQlUser(
        //     id!,
        //     email,
        //     name,
        //     imageUrl ?? graphQlUser.imageUrl,
        //     email,
        //     graphQlUser.about
        //   )
        // );
      }
      localStorage.setItem('userData', "");

      dispatch(setSuccessMsg(`Program Manager Added Successfully !`));
     // dispatch(getProgramManagerById(id!));

      history.push(VIEW_ORGANISATION_DETAILS(id));

      dispatch(programManagerComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        // dispatch(studentFailure(error));
        dispatch(setErrorMsg(error));
        dispatch(programManagerFailure(error));
      }
    }
  };
export const getProgramForProgramManager =
  (programManagerId: string, history?: History): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(programManagerStart());
      const programs = await REQUESTS.getProgramForProgramManager(
        programManagerId
      );
      if (programs.length > 0) {
        dispatch(setProgram(programs[0]));
        dispatch(setHasProgram(true));
        if (history) {
          //history.push(PROGRAM_MANAGER_DASHBOARD);
        }
      } else {
        dispatch(setHasProgram(false));
        if (history) {
          history.push(PROGRAM_MANAGER_INTAKE);
        }
      }
      dispatch(programManagerComplete());
    } catch (error: any) {
      if (error.response) {
        const { data, status } = error.response;
        dispatch(programManagerFailure(data));
      }
    }
  };

  export const getProgramForProgramManagerRefresh =
  (programManagerId: string, history?: History): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(programManagerStart());
      const programs = await REQUESTS.getProgramForProgramManager(
        programManagerId
      );
      if (programs.length > 0) {
       // dispatch(setProgramManger(programs[0]));
        dispatch(setHasProgram(true));
        if (history) {
          //history.push(PROGRAM_MANAGER_DASHBOARD);
        }
      } else {
        dispatch(setHasProgram(false));
        // if (history) {
        //   history.push(PROGRAM_MANAGER_INTAKE);
        // }
      }
      dispatch(programManagerComplete());
    } catch (error: any) {
      if (error.response) {
        const { data, status } = error.response;
        dispatch(programManagerFailure(data));
      }
    }
  };

export const getProgramForCounselor =
  (counselorId: string, history?: History): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(programManagerStart());
      const counselor = await COUNSELOR_REQUESTS.getCounselorById(counselorId);
      dispatch(setCounselor(counselor));

      dispatch(setProgram(counselor.programCounselling));
      if (history) {
        // history.push(COUNSELOR_DASHBOARD);
      }

      dispatch(programManagerComplete());
    } catch (error: any) {
      if (error.response) {
        const { data, status } = error.response;
        dispatch(programManagerFailure(data));
      }
    }
  };

  export const getProgramForCounselorRefresh =
  (counselorId: string, history?: History): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(programManagerStart());
      const counselor = await COUNSELOR_REQUESTS.getCounselorById(counselorId);
      dispatch(setCounselor(counselor));

      dispatch(setProgram(counselor.programCounselling));
      if (history) {
       // history.push(COUNSELOR_DASHBOARD);
      }

      dispatch(programManagerComplete());
    } catch (error: any) {
      if (error.response) {
        const { data, status } = error.response;
        dispatch(programManagerFailure(data));
      }
    }
  };

import { withFormik } from 'formik';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { History } from 'history';
import { AppThunk } from '../../../app/store';
import {
  ABOUT_REQUIRED,
  ADDRESS_REQUIRED,
  CITY_REQUIRED,
  COUNTRY_REQUIRED,
  EMAIL_INVALID,
  EMAIL_NOT_LONG_ENOUGH,
  EMAIL_REQUIRED,
  NAME_REQUIRED,
  POSTAL_CODE_REQUIRED,
  REFERENCE_CODE_REQUIRED,
  SCHOOL_NAME_REQUIRED,
  STREET_REQUIRED,
} from '../../../constants/formMessages';
import { EditStudentForm } from './EditStudentForm';
import { updateStudent } from '../../../slices/studentSlice';
import { User } from '../../../constants/models/User';
import { RootState } from '../../../app/rootReducer';

interface IDispatchProps {
  updateStudent: (
    id: string | undefined,
    name: string,
    email: string,
    address: string,
    street: string,
    city: string,
    state: string,
    country: string,
    postalCode: number,
    about: string,
    phoneno: string,
    countryCode: string,
    imageUrl: string | undefined,
     diagnoses: string[],
     counselors: string[],
    history: History
  ) => AppThunk;
}

export interface EnhancedEditStudentFormValues {
  name: string;
  email: string;
  address: string;
  street: string;
  city: string;
  state: string;
  country: string;
  postalCode: number;
  about: string;
  phoneno: string;
  countryCode: string;
   diagnoses: string[];
   counselors: string[];
}

export interface EnhancedEditStudentFormProps extends RouteComponentProps {
  name?: string;
  email?: string;
  address?: string;
  street?: string;
  city?: string;
  state?: string;
  country?: string;
  postalCode?: number;
  about?: string;
  phoneno?: string;
  countryCode?: string;
  diagnoses?: any[];
  counselors?: any[];
  student?: User;
  updateStudent: (
    id: string | undefined,
    name: string,
    email: string,
    address: string,
    street: string,
    city: string,
    state: string,
    country: string,
    postalCode: number,
    about: string,
    phoneno: string,
    countryCode: string,
    imageUrl: string | undefined,
     diagnoses: string[],
     counselors: string[],
    history: History
  ) => AppThunk;
}

const EnhancedAddOrEditStudentForm = withFormik<
  EnhancedEditStudentFormProps,
  EnhancedEditStudentFormValues
>({
  mapPropsToValues: ({ student }) => ({
    name: student ? student.name : '',
    email: student ? student.email : '',
    address: student ? student.address : '',
    street: student ? student.street : '',
    city: student ? student.city : '',
    state: student ? student.state : '',
    country: student ? student.country : '',
    postalCode: student ? student.postalCode : 0,
    about: student ? student.about : '',
    phoneno: student ? student.phoneno : '',
    countryCode: student ? student.countryCode : '',
    diagnoses: student ? student.diagnosesarr : [],
    counselors: student ? student.counselors : [],

    // diagnoses: [],
    // counselors: [],
  }),
  validationSchema: Yup.object().shape({
    name: Yup.string().required(NAME_REQUIRED),
    email: Yup.string()
      .min(3, EMAIL_NOT_LONG_ENOUGH)
      .max(255)
      .email(EMAIL_INVALID)
      .required(EMAIL_REQUIRED),
    address: Yup.string().notRequired(),
    street: Yup.string().notRequired(),
    city: Yup.string().notRequired(),
    country: Yup.string().notRequired(),
    postalCode: Yup.number().notRequired(),
    about: Yup.string().notRequired(),
    phoneno: Yup.string().notRequired(),
    countryCode: Yup.string().notRequired(),
    // diagnoses: Yup.array().of(Yup.string()).notRequired(),
    // counselors: Yup.array().of(Yup.string()).notRequired(),
  }),
  handleSubmit: (values, { setSubmitting, props }) => {
    const { history, updateStudent, student } = props;
    const {
      name,
      email,
      address,
      street,
      city,
      state,
      country,
      postalCode,
      about,
      phoneno,
      countryCode,
       diagnoses,
       counselors,
    } = values;
    updateStudent(
      student?.id,
      name,
      email,
      address,
      street,
      city,
      state,
      country,
      postalCode,
      about,
      phoneno,
      countryCode,
      student?.imageUrl,
      diagnoses,
      counselors,
      history
    );
    setSubmitting(false);
  },
  displayName: 'BasicForm',
})(EditStudentForm);

const EnhancedAddOrEditStudentFormWithRouter = withRouter(
  EnhancedAddOrEditStudentForm
);

const mapStateToProps = (state: RootState) => ({
  student: state.student.student,
});

export default connect(mapStateToProps, {
  updateStudent,
  // @ts-ignore
})(EnhancedAddOrEditStudentFormWithRouter);

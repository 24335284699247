import React from 'react';
import {
  Button,
  FormControl,
  InputLabel,
  InputAdornment,
  makeStyles,
  TextField,
  Typography,
  Grid,
} from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { FormikProps } from 'formik';
import formStyles from '../../../assets/jss/components/formStyles';
import { RootState } from '../../../app/rootReducer';
import { EnhancedAddOrEditCounselorFormValues } from './EnhancedAddOrEditCounselorForm';
import {
  ADD_COUNSELOR,
  FORGOT_PASSWORD,
  REGISTER,
} from '../../../constants/routes';
import { DisplayFormikState } from '../../../components/DisplayFormikState';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';



interface IAddOrEditCounselorFormProps {}

const useStyles = makeStyles(formStyles);

export const AddOrEditCounselorForm: React.FC<
  IAddOrEditCounselorFormProps &
    FormikProps<EnhancedAddOrEditCounselorFormValues>
> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();


  const isAddCounselor = location.pathname === ADD_COUNSELOR ? true : false;

  const debug = false;
  const { values, errors, touched, handleSubmit, handleBlur, handleChange } =
    props;
  const { counselorLoading } = useSelector((state: RootState) => {
    return {
      counselorLoading: state.counselor.loading,
    };
  }, shallowEqual);

  return (
    <div>
       <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
            <Grid item md={10} xs={12}>
                <Button
                    color='primary'
                    startIcon={
                      <ArrowBackIosIcon
                        fontSize='small'
                        style={{ paddingRight: '0px' }}
                      />
                    }
                    onClick={(e) => {
                      history.go(-1);
                    }}
                    size='small'
                  >
                    Back
                  </Button>
            </Grid>
            
        </Grid><br/>
      <div style={{ margin: '0px 0px 32px' }}>
        <Typography variant='body1' className={classes.loginText}>
          Admins
        </Typography>
        <Typography variant='h4' className={classes.welcomeBackText}>
          {isAddCounselor ? 'Add Admin' : 'Edit Admin profile'}
        </Typography>
        <Typography variant='body1' className={classes.loginText}>
          {isAddCounselor ? 'Add admin to program' : ''}
        </Typography>
      </div>
      <form onSubmit={handleSubmit}>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <Grid item md={6} xs={12}>
            <FormControl
              required
              className={classes.formControl}
              style={{ width: '100%', paddingRight: '0.4em' }}
            >
              <Typography variant='subtitle1' className={classes.label}>
                Enter Admin Name <span style={{color:'red'}}>*</span>
              </Typography>
              <TextField
                placeholder='Name'
                type='text'
                name='name'
                variant='outlined'
                size='small'
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={(errors.name && touched.name) as boolean}
                helperText={errors.name && touched.name && errors.name}
                InputLabelProps={{
                  classes: {
                    root: classes.heading,
                    focused: classes.cssFocused,
                  },
                }}
              />
            </FormControl>
          </Grid>
          <Grid item md={6} xs={12}>
            <FormControl required className={classes.formControl}>
              <Typography variant='subtitle1' className={classes.label}>
                Enter Admin Email <span style={{color:'red'}}>*</span>
              </Typography>
              <TextField
                id='emailAddress'
                placeholder='Email'
                type='email'
                name='email'
                variant='outlined'
                size='small'
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={(errors.email && touched.email) as boolean}
                helperText={errors.email && touched.email && errors.email}
                InputLabelProps={{
                  classes: {
                    root: classes.heading,
                    focused: classes.cssFocused,
                  },
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
        {/* <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <Grid item md={12} xs={12}>
            <FormControl required className={classes.formControl}>
              <Typography variant='subtitle1' className={classes.label}>
                Enter Reference Code
              </Typography>
              <TextField
                placeholder='Reference Code'
                type='text'
                name='referenceCode'
                variant='outlined'
                size='small'
                value={values.referenceCode}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  (errors.referenceCode && touched.referenceCode) as boolean
                }
                helperText={
                  errors.referenceCode &&
                  touched.referenceCode &&
                  errors.referenceCode
                }
                InputLabelProps={{
                  classes: {
                    root: classes.heading,
                    focused: classes.cssFocused,
                  },
                }}
              />
            </FormControl>
          </Grid>
        </Grid> */}

        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <Grid item md={9} xs={12}>
            <FormControl
              required
              className={classes.formControl}
              style={{ width: '100%', paddingRight: '0.4rem' }}
            >
              <Typography variant='subtitle1' className={classes.label}>
                Address
              </Typography>
              <TextField
                id='address'
                placeholder='Address'
                type='text'
                name='address'
                variant='outlined'
                size='small'
                value={values.address}
                onChange={handleChange}
                onBlur={handleBlur}
                error={(errors.address && touched.address) as boolean}
                helperText={errors.address && touched.address && errors.address}
                InputLabelProps={{
                  classes: {
                    root: classes.heading,
                    focused: classes.cssFocused,
                  },
                }}
              />
            </FormControl>
          </Grid>
          <Grid item md={3} xs={12}>
            <FormControl
              required
              className={classes.formControl}
              style={{ width: '100%' }}
            >
              <Typography variant='subtitle1' className={classes.label}>
                City
              </Typography>
              <TextField
                placeholder='City'
                type='text'
                name='city'
                variant='outlined'
                size='small'
                value={values.city}
                onChange={handleChange}
                onBlur={handleBlur}
                error={(errors.city && touched.city) as boolean}
                helperText={errors.city && touched.city && errors.city}
                InputLabelProps={{
                  classes: {
                    root: classes.heading,
                    focused: classes.cssFocused,
                  },
                }}
              />
            </FormControl>
          </Grid>
          <Grid
            container
            direction='row'
            justifyContent='space-between'
            alignItems='center'
          >
            <Grid item md={4} xs={12}>
              <FormControl
                required
                className={classes.formControl}
                style={{ width: '100%', paddingRight: '0.4em' }}
              >
                <Typography variant='subtitle1' className={classes.label}>
                  State/Province/Region
                </Typography>
                <TextField
                  id='state'
                  placeholder='State'
                  type='text'
                  name='state'
                  variant='outlined'
                  size='small'
                  value={values.state}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={(errors.state && touched.state) as boolean}
                  helperText={errors.state && touched.state && errors.state}
                  InputLabelProps={{
                    classes: {
                      root: classes.heading,
                      focused: classes.cssFocused,
                    },
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item md={4} xs={12}>
              <FormControl
                required
                className={classes.formControl}
                style={{ width: '100%', paddingRight: '0.4em' }}
              >
                <Typography variant='subtitle1' className={classes.label}>
                  Country
                </Typography>
                <TextField
                  placeholder='Country'
                  type='text'
                  name='country'
                  variant='outlined'
                  size='small'
                  value={values.country}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={(errors.country && touched.country) as boolean}
                  helperText={
                    errors.country && touched.country && errors.country
                  }
                  InputLabelProps={{
                    classes: {
                      root: classes.heading,
                      focused: classes.cssFocused,
                    },
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item md={4} xs={12}>
              <FormControl
                required
                className={classes.formControl}
                style={{ width: '100%', paddingRight: '0.4em' }}
              >
                <Typography variant='subtitle1' className={classes.label}>
                  Postal Code
                </Typography>
                <TextField
                  placeholder='Postal Code'
                  type='text'
                  name='postalCode'
                  variant='outlined'
                  size='small'
                  value={values.postalCode}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={(errors.postalCode && touched.postalCode) as boolean}
                  helperText={
                    errors.postalCode && touched.postalCode && errors.postalCode
                  }
                  InputLabelProps={{
                    classes: {
                      root: classes.heading,
                      focused: classes.cssFocused,
                    },
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <Grid item md={6} xs={12}>
            <FormControl
              required
              className={classes.formControl}
              style={{ width: '100%', paddingRight: '0.4em' }}
            >
              <Typography variant='subtitle1' className={classes.label}>
                About
              </Typography>
              <TextField
                placeholder='About'
                type='text'
                name='about'
                variant='outlined'
                size='small'
                value={values.about}
                onChange={handleChange}
                onBlur={handleBlur}
                error={(errors.about && touched.about) as boolean}
                helperText={errors.about && touched.about && errors.about}
                InputLabelProps={{
                  classes: {
                    root: classes.heading,
                    focused: classes.cssFocused,
                  },
                }}
              />
            </FormControl>
          </Grid>
          <Grid item md={6} xs={12}>
                <FormControl 
                  required
                  className={classes.formControl}
                  style={{ width: '100%', paddingRight: '0.4em' }}
                >
                <Typography variant='subtitle1' className={classes.label}>
                  Phone Number
                </Typography>
              
               <div className='row' style={{ width: '100%' }}>
                <div className='col-sm-3' style={{paddingRight:'0px'}}>
                  <TextField
                        placeholder='+1'
                        type='text'
                        name='countryCode'
                        variant='outlined'
                        size='small'
                        value={values.countryCode}
                        style={{width:'100%'}}      
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={(errors.countryCode && touched.countryCode) as boolean}
                      helperText={
                        errors.countryCode && touched.countryCode && errors.countryCode
                      }
                      InputLabelProps={{
                        classes: {
                          root: classes.heading,
                          focused: classes.cssFocused,
                        },
                      }}    
                           
                      />
                </div>
                <div className='col-sm-9' style={{paddingLeft:'5px', paddingRight:'0px'}}>
                              
                              <TextField
                                  placeholder='Enter Phone No'
                                  type='number'
                                  name='phoneno'
                                  variant='outlined'
                                  size='small'
                                  value={values.phoneno}
                                  style={{width:'100%'}}      

                                  onInput = {(e:any) =>{
                                    e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,10)
                                }}              
                                onChange={handleChange}                                  
                                onBlur={handleBlur}
                                error={(errors.phoneno && touched.phoneno) as boolean}
                                helperText={
                                  errors.phoneno && touched.phoneno && errors.phoneno
                                } 
                                InputLabelProps={{
                                  classes: {
                                    root: classes.heading,
                                    focused: classes.cssFocused,
                                  },
                                }}  
                                />{' '}
                       </div>
                </div>  
                 </FormControl>
              </Grid> 
        </Grid>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <Grid item md={9} xs={12}>
            <Typography variant='subtitle2'>
              {false
                ? `The login credentials will be sent to the Counselors email address
              ${values.email} to manage their account`
                : ''}
            </Typography>
          </Grid>
          <Grid item md={3} xs={12}>
            <Button
              className={classes.secondaryButton}
              variant='contained'
              color='primary'
              type='submit'
              disabled={counselorLoading}
              // fullWidth
            >
              {isAddCounselor ? 'Add' : 'Edit'} Admin
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};



import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Collapse from '@mui/material/Collapse';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import { TransitionGroup } from 'react-transition-group';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../app/rootReducer';
import {
  deleteTaskType,
  getTaskTypesForProgram,
  getTaskTypesForProgramRefresh,
} from '../../../slices/taskTypeSlice';

interface IDisplayTaskTypeProps {}

export const DisplayTaskType: React.FC<IDisplayTaskTypeProps> = ({}) => {
  const dispatch = useDispatch();
  const { taskTypes } = useSelector((state: RootState) => {
    return {
      taskTypes: state.taskType.taskTypes,
    };
  }, shallowEqual);

  const authlocal = localStorage.getItem('userRole');
  const user_id = localStorage.getItem('user_id');


  useEffect(() => {
    if(user_id)
    {
      dispatch(getTaskTypesForProgramRefresh(user_id));
    }
    else
    {
      dispatch(getTaskTypesForProgram());

    }
  }, [taskTypes.length]);


  let renderedTaskTypes;
  if (taskTypes.length > 0) {

    // Custom sorting function
  const customSort = (a: any, b: any) => {
    if (a.name.toLowerCase() === 'other') {
      return 1; // Move 'other' to the end
    } else if (b.name.toLowerCase() === 'other') {
      return -1; // Move 'other' to the end
    } else {
      return a.name.localeCompare(b.name);
    }
  };
  // Sort the array
  const sortedArray = [...taskTypes].sort(customSort);
    renderedTaskTypes = sortedArray.map((taskType, index) => {
      const { name, id } = taskType;
      return (
        <Collapse key={id}>
          <ListItem
            style={{ width: '100%' }}
            secondaryAction={
              <IconButton
                edge="end"
                aria-label="delete"
                title="Delete"
                onClick={() => {
                  dispatch(deleteTaskType(id));
                }}
              >
                <DeleteIcon />
              </IconButton>
            }
          >
            <ListItemText primary={name} />
          </ListItem>
        </Collapse>
      );
    });
  }

  return (
    <div>
      <Box sx={{ mt: 1 }}>
        <List>
          <TransitionGroup>{renderedTaskTypes}</TransitionGroup>
        </List>
      </Box>
    </div>
  );
};

import React, { useEffect } from 'react';
import {
  createDispatchHook,
  shallowEqual,
  useDispatch,
  useSelector,
} from 'react-redux';
import { RootState } from '../../../app/rootReducer';
import {
  getDocumentsForProgram,
  deleteDocument,
  setDocumentUrl,
  createDocument,
  setIsDocumentModalOpen,
  getDocumentsForProgramRefresh,
} from '../../../slices/documentSlice';
import AddIcon from '@mui/icons-material/Add';
import {
  ImageList,
  makeStyles,
  ImageListItem,
  ImageListItemBar,
  IconButton,
  Paper,
  Table,
  TableContainer,
  TableBody,
  TableHead,
  TableRow,
  Typography,
  Button,
} from '@material-ui/core';
import { StyledTableRow } from '../../../components/StyledTableRow';
import { StyledTableCell } from '../../../components/StyledTableCell';
import EnhancedAddDocumentForm from './EnhancedAddDocumentForm';
import { setIsDeleteModalOpen } from '../../../slices/taskSlice';
import DeleteIcon from '@mui/icons-material/Delete';
import DocViewer, { DocViewerRenderers } from 'react-doc-viewer';

//const useStyles = makeStyles();

interface IAllDocumentsViewProps {}

export const AllDocumentsView: React.FC<IAllDocumentsViewProps> = ({}) => {
  const dispatch = useDispatch();
  //const classes = useStyles();

  const { documentLoading, documents, document, isDocumentModalOpen } =
    useSelector((state: RootState) => {
      return {
        document: state.document.document,
        documents: state.document.documents,
        documentLoading: state.document.loading,
        isDocumentModalOpen: state.document.isDocumentModalOpen,
      };
    }, shallowEqual);

    const authlocal = localStorage.getItem('userRole');
    const user_id = localStorage.getItem('user_id');

  useEffect(() => {
    if(authlocal && user_id)
    {
      dispatch(getDocumentsForProgramRefresh(user_id));

    }
    else
    {
      dispatch(getDocumentsForProgram());
    }
    return () => {};
  }, [documents.length]);

  let renderedDocuments;
  if (documentLoading) {
    renderedDocuments = <div>Loading...</div>;
  }
  if (documents.length > 0) {
    renderedDocuments = documents.map((document, index) => {
      const { id, name, documentUrl,user } = document;
      let userimg=user?.imageUrl==="no-url"?"https://nflyte-1.s3.amazonaws.com/ef12f40b-f758-4e9c-ab7c-acebc7e240c6.jpg":user?.imageUrl;
      return (
        <StyledTableRow key={index}>
          <StyledTableCell component="th" scope="row">
                {userimg !== '' ? (
                              <a href={userimg} target='_blank'>
                                <img
                                  src={userimg}
                                  width="50" height="50"
                                  className="rounded"
                                />
                              </a>
                            ) : (
                              <span>-</span>
                            )}
          </StyledTableCell>
          <StyledTableCell component="th" scope="row">
           {user?.name!== '' ?user?.name:"-"}
          </StyledTableCell>
          <StyledTableCell scope="row">{name}</StyledTableCell>
          <StyledTableCell scope="row">
            <Typography variant="subtitle2">
              <a
                href={documentUrl}
                target="_blank"
                style={{
                  textDecorationLine: 'underline',
                  color: '#0092BC',
                }}
              >
                {documentUrl}
              </a>
            </Typography>
          </StyledTableCell>
          <StyledTableCell>
          {user_id===user?.id?

            <IconButton
              edge="end"
              aria-label="delete"
              title="Delete"
              onClick={() => {
                dispatch(deleteDocument(id));
              }}
            >
              <DeleteIcon />
            </IconButton>:""
          }
          </StyledTableCell>
        </StyledTableRow>
      );
    });
  } else if (documents.length === 0) {
  }

  return (
    <div>
      <Typography variant="h6">Library Documents</Typography>
      <br />
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          dispatch(setDocumentUrl(''));

          dispatch(setIsDocumentModalOpen(true));
        }}
        startIcon={<AddIcon />}
      >
        Add Document
      </Button>
      <br />
      <br />
      {/* <DocViewer
        pluginRenderers={DocViewerRenderers}
        documents={documents.map((document, index) => {
          return {
            uri: document.documentUrl,
          };
        })}
      /> */}
      {documents.length > 0 && (
        <TableContainer component={Paper}>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Image</StyledTableCell>
                <StyledTableCell>Added By</StyledTableCell>
                <StyledTableCell>Document Name</StyledTableCell>
                <StyledTableCell>Document Url</StyledTableCell>
                <StyledTableCell>Delete</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>{renderedDocuments}</TableBody>
          </Table>
        </TableContainer>
      )}
      {isDocumentModalOpen ? <EnhancedAddDocumentForm /> : ''}
    </div>
  );
};

import React, { useEffect } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from '../../app/rootReducer';
import {
  ADD_RECIPE,
  ADD_TASK,
  ADD_TASK_WITH_USER,
  COUNSELOR_PROFILES,
  MESSAGING,
  PROGRAM_MANAGER_INTAKE,
  STUDENT_PROFILES,
  VIEW_CALENDAR,
} from '../../constants/routes';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import dashboardCardStyles from '../../assets/jss/components/dashboardCardStyles';
import { getProgramForSuperAdmin } from '../../slices/superAdminSlice';
import { setLongLoading } from '../../slices/authSlice';
import { setSuccessMsg } from '../../slices/alertSlice';
import { Typography } from '@material-ui/core';
import { Auth } from 'aws-amplify';
import { AllQuestionsView } from '../Library/Question/AllQuestionsView';
import GRAPHIC from '../../../assets/images/login_graphic.svg';
import NOTIFICATION from '../../../assets/images/Notification.svg';
import CookbookIcon from '@mui/icons-material/CollectionsBookmark';

import NotificationsIcon from '@mui/icons-material/Notifications';
import ChatIcon from '@mui/icons-material/Chat';
import DescriptionIcon from '@mui/icons-material/Description';
import PersonIcon from '@mui/icons-material/Person';
import FaceIcon from '@mui/icons-material/Face';
import EventIcon from '@mui/icons-material/Event';
import GRAPHIC_1 from '../../assets/images/Menu/1.svg';
import GRAPHIC_2 from '../../assets/images/Menu/2.svg'; 
import GRAPHIC_3 from '../../assets/images/Menu/3.svg';
import GRAPHIC_4 from '../../assets/images/Menu/4.svg';
import GRAPHIC_5 from '../../assets/images/Menu/5.svg';
import GRAPHIC_6 from '../../assets/images/Menu/cookbook.svg';
import GRAPHIC_7 from '../../assets/images/Menu/frame1.svg';
//import GRAPHIC_7 from '../../../assets/images/Menu/77.png';
import { height } from '@mui/system';

interface ISuperAdminDashboardProps {}

const useStyles = makeStyles(dashboardCardStyles);

const SuperAdminDashboard: React.FC<
  ISuperAdminDashboardProps
> = ({}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { superAdmin } = useSelector((state: RootState) => {
    return {
      superAdmin: state.superAdmin.superAdmin,
    };
  }, shallowEqual);

  useEffect(() => {
    (async () => {
      if (superAdmin) {
        dispatch(getProgramForSuperAdmin(superAdmin.id, history));
      }
    })();
    return () => {};
  }, []);
  

  return (
    <div>
      <Grid container direction='row'>
        <Grid
          item
          xs={12}
          md={6}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor:'pointer'
          }}
          onClick={(e) => {
            history.push(COUNSELOR_PROFILES);
          }}
        >
          <div style={{ width: '300px' }}>
            <div className={classes.davBox}>
              <Typography variant={'h4'} className={classes.title}>
                {/* Counselor Profiles */}
                Admin Profiles
              </Typography>
              <span className={classes.davBoxIcon}>
                <PersonIcon
                  width='100%'
                  fontSize='large'
                  className={classes.icon}
                /> 
              </span>
            </div>
            <div className={classes.img}>
              <img src={GRAPHIC_7} />
            </div>
          </div>
        </Grid>

        {/* <Grid
          item
          xs={12}
          md={6}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor:'pointer'
          }}
          onClick={(e) => {
            history.push(MESSAGING);
          }}
        >
          <div style={{ width: '300px' }}>
            <div className={classes.davBox}>
              <Typography variant={'h4'} className={classes.title}>
                Messaging
              </Typography>
              <span className={classes.davBoxIcon}>
                <ChatIcon
                  width='100%'
                  fontSize='large'
                  className={classes.icon}
                />
              </span>
            </div>
            <div className={classes.img}>
              <img src={GRAPHIC_2} />
            </div>
          </div>
        </Grid> */}
         <Grid
          item
          xs={12}
          md={6}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor:'pointer'
          }}
          onClick={(e) => {
            history.push(ADD_RECIPE);
          }}
        >
          <div style={{ width: '300px' }}>
            <div className={classes.davBox}>
              <Typography variant={'h4'} className={classes.title}>
                Cookbook
              </Typography>
              <span className={classes.davBoxIcon}>
                <CookbookIcon
                  width='100%'
                  fontSize='large'
                  className={classes.icon}
                />
              </span>
            </div>
            <div className={classes.img}>
            <img src={GRAPHIC_6} style={{
                    cursor:'pointer'
              }}/>
            </div>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor:'pointer'
          }}
          onClick={(e) => {
            history.push(ADD_TASK_WITH_USER);
          }}
        >
          <div style={{ width: '300px' }}>
            <div className={classes.davBox}>
              <Typography variant={'h4'} className={classes.title}>
                Add Task
              </Typography>
              <span className={classes.davBoxIcon}>
                <EventIcon
                  width='100%'
                  fontSize='large'
                  className={classes.icon}
                />
              </span>
            </div>
            <div className={classes.img}>
              <img src={GRAPHIC_3} />
            </div>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor:'pointer'
          }}
          onClick={(e) => {
            history.push(STUDENT_PROFILES);
          }}
        >
          <div style={{ width: '300px' }}>
            <div className={classes.davBox}>
              <Typography variant={'h4'} className={classes.title}>
                {/* Student Profiles */}
                User Profiles
              </Typography>
              <span className={classes.davBoxIcon}>
                <FaceIcon
                  width='100%'
                  fontSize='large'
                  className={classes.icon}
                />
              </span>
            </div>
            <div className={classes.img}>
              <img src={GRAPHIC_5} />
            </div>
          </div>
        </Grid>
        {/* <Grid item xs={12} md={4}>
          <div style={{ width: '300px' }}>
            <div className={classes.davBox}>
              <Typography variant={'h4'} className={classes.title}>
                Report
              </Typography>
              <span className={classes.davBoxIcon}>
                <DescriptionIcon
                  width='100%'
                  fontSize='large'
                  className={classes.icon}
                />
              </span>
            </div>
            <div className={classes.img}>
              <img src={GRAPHIC_5} />
            </div>
          </div>
        </Grid>

        <Grid item xs={12} md={4} onClick={(e) => {}}>
          <div style={{ width: '300px' }}>
            <div className={classes.davBox}>
              <Typography variant={'h4'} className={classes.title}>
                Notifications
              </Typography>
              <span className={classes.davBoxIcon}>
                <NotificationsIcon
                  width='100%'
                  fontSize='large'
                  className={classes.icon}
                />
              </span>
            </div>
            <div className={classes.img}>
              <img src={GRAPHIC_1} />
            </div>
          </div>
        </Grid> */}
      </Grid>
    </div>
  );
};

export default SuperAdminDashboard;

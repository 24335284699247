import React, { useEffect, useState } from 'react';
import {
  Button,
  FormControl,
  InputLabel,
  InputAdornment,
  makeStyles,
  TextField,
  Typography,
  MenuItem,
  Select,
  Grid,
  IconButton,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import formStyles from '../../../assets/jss/components/formStyles';
import { FormikProps } from 'formik';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../app/rootReducer';
import { LOGIN } from '../../../constants/routes';
import { EnhancedForgotPasswordFormValues } from './EnhancedForgotPasswordForm';
import { setBrowName } from '../../../slices/authSlice';
import Show from '@material-ui/icons/Visibility';
import Hide  from '@material-ui/icons/VisibilityOff';

interface IForgotPasswordFormProps {}

const useStyles = makeStyles(formStyles);
//const dispatch = useDispatch();
export const ForgotPasswordForm: React.FC<
  IForgotPasswordFormProps & FormikProps<EnhancedForgotPasswordFormValues>
> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [isDisabled, setDisabled] = useState(false);
  const [codeerr, setCodeerr] = useState("");

  const [visible, setVisible] = useState(false);
  const [visible1, setVisible1] = useState(false);

  const { values, errors, touched, handleSubmit, handleBlur, handleChange } =
    props;
  const { authLoading, email,tempCode } = useSelector((state: RootState) => {
    return {
      authLoading: state.auth.loading,
      email: state.auth.email,
      tempCode: state.auth.tempCode,

    };
  }, shallowEqual);
  
  
  function checkCode()
  {
    console.log("tpc----->"+tempCode)
  
  
      if(tempCode)
      {
        let valc=values.code;
        let valc2=tempCode.toString();
        console.log("temp--->"+values.code)
        if(valc2 === valc)
        {
         
            setDisabled(false);
            setCodeerr("");
        
        }
        else
        {
          
         
          setDisabled(true);
          setCodeerr("Invalid code provided, please request code again")
  
        }
      }
        
  }
  useEffect(() => {
    addBrowser();
        return () => {};
      }, []);
    
     const [browserName, setBrowserName] = useState('');
     function addBrowser()
     {
       const userAgent = window.navigator.userAgent;
          if (userAgent.indexOf('Chrome') > -1) {
           setBrowserName('Google Chrome');
           console.log("test brows-->'Google Chrome'");
           dispatch(setBrowName('Google Chrome'));
  
         } else if (userAgent.indexOf('Firefox') > -1) {
           setBrowserName('Mozilla Firefox');
           console.log("test brows-->'Mozilla Firefox'");
           dispatch(setBrowName('Mozilla Firefox'));
     
         } else if (userAgent.indexOf('Safari') > -1) {
           setBrowserName('Apple Safari');
           console.log("test brows-->'Apple Safari'");
           dispatch(setBrowName('Apple Safari'));
    
         } else if (userAgent.indexOf('Edge') > -1) {
           setBrowserName('Microsoft Edge');
           console.log("test brows-->'Edge'");
           dispatch(setBrowName('Edge')); 
     
         } else  if (userAgent.indexOf('Opera') > -1 || userAgent.indexOf('OPR') > -1) {
           setBrowserName('Opera');
           console.log("test brows-->'Opera'");
           dispatch(setBrowName('Opera'));
     
         } else if (userAgent.indexOf('MSIE') > -1 || userAgent.indexOf('Trident/') > -1) {
           setBrowserName('Internet Explorer');
           console.log("test brows-->'Internet Explorer'");
           dispatch(setBrowName('Internet Explorer'));
     
         } else {
           setBrowserName('Unknown browser');
           dispatch(setBrowName('Unknown browser'));
     
         }
   
         console.log("test-->"+browserName);
         //handleSubmit();
     }
  return (
    <div>
      <div style={{ margin: '0px 0px 22px' }}>
        <Typography variant='body1' className={classes.loginText}>
          UPDATE PASSWORD
        </Typography>
        <Typography variant='h4' className={classes.welcomeBackText}>
          Set New Password
        </Typography>
        <Typography variant='body1' className={classes.loginText}>
          We've sent a code to <span style={{ color: 'green' }}>{email}</span>.
          Please enter the code to change your current password.
        </Typography>
      </div>
      <form className={classes.root} onSubmit={handleSubmit}>
        <FormControl required className={classes.formControl}>
          <Typography variant='subtitle1' className={classes.label}>
            Enter verification code <span style={{color:'red'}}>*</span>
          </Typography>
          <TextField
            type='text'
            placeholder='123456'
            name='code'
            size='small'
            variant='outlined'
            value={values.code}
            onChange={handleChange}
            onBlur={handleBlur}
            onKeyUp={checkCode}

            error={(errors.code && touched.code) as boolean}
            helperText={errors.code && touched.code && errors.code}
            InputLabelProps={{
              classes: {
                root: classes.heading,
                focused: classes.cssFocused,
              },
            }}
          />
        </FormControl>
        <span style={{color:'red',fontWeight:'700'}}>{codeerr}</span>
        <FormControl required className={classes.formControl}>
          <Typography variant='subtitle1' className={classes.label}>
            Enter your password <span style={{color:'red'}}>*</span>
          </Typography>
          <TextField
            id='password'
            placeholder='Enter Password'
            type={visible1 ? 'text':'password'}
            name='password'
            variant='outlined'
            size='small'
            value={values.password}
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete={'off'}
            error={(errors.password && touched.password) as boolean}
            helperText={errors.password && touched.password && errors.password}
            InputLabelProps={{
              classes: {
                root: classes.heading,
                focused: classes.cssFocused,
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={()=>setVisible1(!visible1)}
                  >
                    {visible1 ? <Show /> : <Hide />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <Typography variant='subtitle1' className={classes.label}>
            Re-enter your password <span style={{color:'red'}}>*</span>
          </Typography>
          <TextField
            placeholder='Confirm Password'
            type={visible ? 'text':'password'}
            variant='outlined'
            size='small'
            name='passwordConfirmation'
            value={values.passwordConfirmation}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              (errors.passwordConfirmation &&
                touched.passwordConfirmation) as boolean
            }
            autoComplete={'off'}
            helperText={
              errors.passwordConfirmation &&
              touched.passwordConfirmation &&
              errors.passwordConfirmation
            }
            InputLabelProps={{
              classes: {
                root: classes.heading,
                focused: classes.cssFocused,
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={()=>setVisible(!visible)}
                  >
                    {visible ? <Show /> : <Hide />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
        <Button
          className={classes.secondaryButton}
          variant='contained'
          color='primary'
          type='submit'
          disabled={isDisabled}
          fullWidth
        >
          Update password
        </Button>
      </form>
    </div>
  );
};

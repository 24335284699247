import React, { useEffect, useState } from 'react';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  makeStyles,
  TextField,
  Typography,
  Dialog,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { FormikProps } from 'formik';
import { EnhancedAddDocumentFormValues } from './EnhancedAddDocumentForm';
import formStyles from '../../../assets/jss/components/formStyles';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../app/rootReducer';
import {
  deleteDocument,
  setDocumentUrl,
  setIsDocumentModalOpen,
} from '../../../slices/documentSlice';
import theme from '../../../app/theme';
import { DropzoneArea } from 'material-ui-dropzone';
import { addDocumentToS3 } from '../../../slices/documentSlice';

interface IAddDocumentFormProps {}

const useStyles = makeStyles(formStyles);

export const AddDocumentForm: React.FC<
  IAddDocumentFormProps & FormikProps<EnhancedAddDocumentFormValues>
> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const debug = false;
  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleBlur,
    handleChange,
    setFieldValue,
    setTouched,
    setSubmitting,
  } = props;
  const { documentLoading, isDocumentModalOpen, documentUrl } = useSelector(
    (state: RootState) => {
      return {
        documentLoading: state.document.loading,
        isDocumentModalOpen: state.document.isDocumentModalOpen,
        documentUrl: state.document.documentUrl,
      };
    },
    shallowEqual
  );
  localStorage.setItem('urlRoute', "documents");


  useEffect(() => {
    setFieldValue('documentUrl', documentUrl);
    return () => {};
  }, [documentUrl]);

  const handleClose = () => {
   // dispatch(deleteDocument(values.id));

    dispatch(setIsDocumentModalOpen(false));
  };

  const [disabled, setIsDisabled] = useState<boolean>(false);

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Dialog
          open={isDocumentModalOpen}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          arira-describedBy="alert-dialog-descritpion"
        >
          <DialogTitle
            id="alert-dialog-title"
            style={{
              color: theme.palette.primary.main,
              backgroundColor: 'white',
            }}
          >
            <DialogContent
              style={{
                backgroundColor: 'white',
              }}
            >
              <DialogContentText id="alert-dialog-description"></DialogContentText>
              <FormControl required className={classes.formControl}>
                <Typography variant="subtitle1" className={classes.label}>
                  Name{' '} <span style={{color:'red'}}>*</span>
                </Typography>
                <TextField
                  placeholder="Enter Name"
                  type="text"
                  name="name"
                  variant="outlined"
                  size="small"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={(errors.name && touched.name) as boolean}
                  helperText={errors.name && touched.name && errors.name}
                  InputLabelProps={{
                    classes: {
                      root: classes.heading,
                      focused: classes.cssFocused,
                    },
                  }}
                />
              </FormControl>
              <FormControl required className={classes.formControl}>
                <Typography variant="subtitle1" className={classes.label}>
                  Document URL{' '}
                </Typography>
                {!documentUrl.startsWith('https') ? (
                  <TextField
                    placeholder="Enter Url"
                    type="text"
                    name="documentUrl"
                    variant="outlined"
                    size="small"
                    value={values.documentUrl}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={disabled}
                    error={
                      (errors.documentUrl && touched.documentUrl) as boolean
                    }
                    helperText={
                      errors.documentUrl &&
                      touched.documentUrl &&
                      errors.documentUrl
                    }
                    InputLabelProps={{
                      classes: {
                        root: classes.heading,
                        focused: classes.cssFocused,
                      },
                    }}
                  />
                ) : (
                  <Typography variant="subtitle2">
                    <a href={documentUrl} target="_blank">
                      {documentUrl}
                    </a>
                  </Typography>
                )}
              </FormControl>
              <FormControl required className={classes.formControl}>
                <Typography variant="subtitle1" className={classes.label}>
                  Upload{' '} <span style={{color:'red'}}>*</span>
                </Typography>
                <DropzoneArea
                  acceptedFiles={['.pdf', '.doc', '.ppt', '.docx', '.pptx']}
                  filesLimit={1}
                  onChange={async (files) => {
                    if (files.length > 0) {
                      let file = files[0];
                      const formData = new FormData();
                      formData.append('file', file);
                      setIsDisabled(true);
                      await dispatch(addDocumentToS3(formData));
                      setFieldValue('name', file.name);
                    } else {
                      setIsDisabled(false);
                    }
                  }}
                  onDelete={(e) => {
                    dispatch(setDocumentUrl(''));
                    setIsDisabled(true);
                  }}
                />
              </FormControl>
            </DialogContent>
            <DialogActions
              style={{
                backgroundColor: 'white',
              }}
            >
              <Button variant="text" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={(e) => {
                  handleSubmit();
                  dispatch(setIsDocumentModalOpen(false));
                }}
                disabled={documentLoading || values.documentUrl === ''}
                // fullWidth
              >
                Add
              </Button>
            </DialogActions>
          </DialogTitle>
        </Dialog>
      </form>
    </div>
  );
};

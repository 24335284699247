import React, { useEffect, useState } from 'react';
import { Container } from '@material-ui/core';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { RootState } from '../../../app/rootReducer';
import { Grid, makeStyles } from '@material-ui/core';
import EnhancedEditAdditionalTaskDetailsForm from './EnhancedEditAdditionalTaskDetailsForm';
import EnhancedEditTaskDetailsForm from './EnhancedEditTaskDetailsForm';
import useMediaQuery from '@mui/material/useMediaQuery';
import GRAPHIC from '../../../assets/images/onboarding.svg';
import {
  resetTaskState,
  setName,
  setDays,
  setEnd,
  setStart,
  setTaskTypeId,
  setTaskLocation,
  setTaskId,
  setIsRecurring,
  setIsSpecial,
  setImageUrl,
  setSubTasks,
  setDaysRadio,
  setNotiTime,
  setRecipeId,
  getTaskById
} from '../../../slices/taskSlice';
import { EditTaskModal } from './EditTaskModal';
import theme from '../../../app/muiTheme';
import { getTaskTypesForProgramRefresh } from '../../../slices/taskTypeSlice';
import { getRecipesAll } from '../../../slices/recipeSlice';
//import { getTaskById } from '../../../api/taskRequests';

interface IEditTaskProps {}

const EditTask: React.FC<IEditTaskProps> = ({}) => {
  const dispatch = useDispatch();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const {
    taskLoading,
    name,
    taskTypeId,
    start,
    end,
    taskLocation,
    step,
    task,
  } = useSelector((state: RootState) => {
    return {
      taskLoading: state.task.loading,
      name: state.task.name,
      taskTypeId: state.task.taskTypeId,
      start: state.task.start,
      end: state.task.end,
      taskLocation: state.task.taskLocation,
      step: state.task.taskStep,
      task: state.task.task,
      recipeId: state.task.recipeId,

    };
  }, shallowEqual);


  const { taskId } = useParams<{ taskId: string | undefined }>();
  if (taskId) {
    localStorage.setItem('urlRoute', "edit_task");
    localStorage.setItem('urlId', taskId);
  }
  const user_id = localStorage.getItem('user_id');
  const urlId = localStorage.getItem('urlId');

  useEffect(() => {
    async function fetchMyAPI() {
    if(user_id && taskId)
    {
      dispatch(getTaskById(taskId));
      dispatch(getTaskTypesForProgramRefresh(user_id));
      dispatch(getRecipesAll());

      // const taskTypes = await REQUESTS.getTaskTypesForProgram(user_id);
      // dispatch(setTaskTypes({ data: taskTypes }));
    }
    else
    {
      if(taskId)
      {
        dispatch(getTaskById(taskId));
      }
    }
  }
    fetchMyAPI()
return () => {};
}, []);//recipes.length

  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    return () => {};
  }, [step]);
console.log("task data----->"+JSON.stringify(task))
  useEffect(() => {
    if (task) {
      localStorage.setItem('userData',JSON.stringify(task))

      setLoading(true);
      dispatch(setName(task.name));
      dispatch(setTaskTypeId(task.taskType.id));
      dispatch(setStart(task.start));
      dispatch(setEnd(task.end));
      dispatch(setTaskLocation(task.taskLocation));
      dispatch(setTaskId(task.id));
      dispatch(setRecipeId(task.recipeId));
      dispatch(setIsSpecial(task.isSpecial));
      dispatch(setImageUrl(task.imageUrl));
      if(task.subTasks)
      {
        dispatch(setSubTasks({ data: task.subTasks }));

      }
      dispatch(setNotiTime({ data: task.notiTime }));
      console.log('****');
      console.log(task.days);
      console.log('****');

      dispatch(setDays({ data: task.days.map((i) => Number(i)) }));
      dispatch(setIsRecurring(task.isRecurring));
      if (task.days.length === 7) {
        dispatch(setDaysRadio('1'));
      } else if (task.days.length === 1) {
        dispatch(setDaysRadio('2'));
      } else {
        dispatch(setDaysRadio('3'));
      }

      setLoading(false);

      // dispatch(setI)
      // dispatch(
      //   setInstructions({
      //     data: task.subTasks.map((instruction) => instruction.name),
      //   })
      // );
    }
  }, []);

  let renderedForm;

  switch (step) {
    case 0:
      renderedForm = (
        <EnhancedEditTaskDetailsForm name={name} taskTypeId={taskTypeId} />
      );
      break;
    case 1:
      renderedForm = (
        <EnhancedEditAdditionalTaskDetailsForm taskLocation={taskLocation} />
      );
      break;
  }

  return (
    <div>
      <Container>
        <Grid
          container
          direction='row'
          justifyContent='flex-start'
          alignItems='flex-start'
        >
          <Grid item xs={12} md={6}>
            {loading ? 'Loading...' : renderedForm}
          </Grid>
          <Grid item xs={12} md={6}>
            {matches && (
              <div style={{ paddingTop: '10vh', marginLeft: '2em' }}>
                <img src={GRAPHIC} />
              </div>
            )}
          </Grid>
        </Grid>
      </Container>
      <EditTaskModal />
    </div>
  );
};
export default EditTask;


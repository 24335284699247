import React, { useEffect, useState } from 'react';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
  IconButton,
  Grid,
  TextField,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from '../../app/rootReducer';
import { StyledTableCell } from '../../components/StyledTableCell';
import { StyledTableRow } from '../../components/StyledTableRow';
import SearchIcon from '@mui/icons-material/Search';

import AddIcon from '@mui/icons-material/Add';

import formStyles from '../../assets/jss/components/formStyles';
import EditIcon from '@mui/icons-material/Edit';
import { Autocomplete } from '@mui/material';
import { FiRefreshCw} from 'react-icons/fi';
import { MdOutlineDelete } from 'react-icons/md';
import { setSuccessMsg } from '../../slices/alertSlice';
import * as REQUESTS from '../../api/studentRequests';
import { getDateWiseOnboardingReports,  getOnboardingCompletionLogList, getOrganisationList, getSearchOrgByOnboardingCompletion } from '../../api/programManagerRequests';


interface IOnboardingCompletionProps {}

const useStyles = makeStyles(formStyles);

const OnboardingCompletion: React.FC<IOnboardingCompletionProps> = ({}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();

  const { student } = useSelector((state: RootState) => {
    return {
      student: state.student.student,
      //entryLog: state.entryLog.entryLog,
    };
  }, shallowEqual);
  const [rpage, setPage] = useState(1);
  const [totalRecipe, setTotalRecipe] = useState(1);
  const [recipesPage, setRecipesPage] = useState(1);
  const [sizes, setSizes] = useState(150);
  const [orgarr_nxt,setorgarrNxt]=useState([]);
  const [selectedOption, setSelectedOption] = useState('');

  //const sizes = 10;

    useEffect (()=>{
      async function fetchMyAPI() {
        if(user_id)
        {
          const orgDatanxt = await getOrganisationList();
          if(orgDatanxt)
          {
            setorgarrNxt(orgDatanxt)

          }
          if(selectedOption!=='')
          {
            filteredData();
          }
          else
          {
          const orgDatanxt = await getOnboardingCompletionLogList(rpage,sizes);
            if(orgDatanxt)
            {
              setRecipesPage(orgDatanxt.data.page);
              setSizes(orgDatanxt.data.size);
              setTotalRecipe(orgDatanxt.data.total);
              setEntryLog(orgDatanxt.data.data)
    
            }
          }
        }
      }
      fetchMyAPI()
        return () => {};
  },[ rpage ])

  
  localStorage.setItem('urlRoute', "parent_admins");
  const [orgarr,setorgarr]=useState(student);
  const user_id = localStorage.getItem('user_id');
  const [orgid,setOrgId]=useState("");
  const [entrylog,setEntryLog]=useState([]);
  const [userarr_nxt,setuserarrNxt]=useState([]);
  
  //console.warn("Entry log:"+entryLog);
  const [datefrom, setDatefrom] = useState("");
  const [dateto, setDateto] = useState("");

  const handleSelectFrom = (event:any) => {
    const selectedOption = event.target.value;
    setDatefrom(selectedOption);
  };
  const handleSelectDateTo = (event:any) => {
    const selectedOption = event.target.value;
    setDateto(selectedOption);
  };
  // Your data array
  const data: any[] = [entrylog];
  console.warn("Entry log Data====>",data[0]);

  const fromDate = new Date(datefrom);
  console.warn("From Date==>",fromDate)

  const toDate = new Date(dateto); 
  console.warn("To Date==>",toDate)
  async function filteredData(){
    
    const orgDatanxt = await getDateWiseOnboardingReports(fromDate,toDate,rpage,sizes);
    if(orgDatanxt)
    {
      setRecipesPage(orgDatanxt.data.page);
      setSizes(orgDatanxt.data.size);
      setTotalRecipe(orgDatanxt.data.total);
      setEntryLog(orgDatanxt.data.data)

    }
  //   const filterData = data.filter((entrylog,index) => {
  //   // const entryData = entrylog[index];
  //   //console.warn("Created Date Data====>",entrylog[index].createdAt);
  //   const createdat = new Date(entrylog[index].createdAt);
  //   console.warn("Created Date Data====>",createdat);
  //   console.warn("Created Date new Data===>",createdat >= fromDate);
  //   return (createdat >= fromDate &&
  //           createdat <= toDate)   
  // });  
  console.log("filter data===>",orgDatanxt);
}

const [orgSelect,setorgSelect]=useState("");

async function getSearchOrganisation(e:any,nm:string)
  {
    //alert(nm)
        setorgSelect(nm)
      let orgnm=nm;
      


        const orgDatanxt = await getSearchOrgByOnboardingCompletion(rpage,sizes,orgnm);
            if(orgDatanxt)
            {
              setRecipesPage(orgDatanxt.data.page);
              setSizes(orgDatanxt.data.size);
              setTotalRecipe(orgDatanxt.data.total);
              setEntryLog(orgDatanxt.data.data);
              
              
            }
  
      
  }
let renderedStudents: { label: string; value: string; imageUrl: string }[] =[];

  if(orgarr_nxt)
  {
  if (orgarr_nxt.length > 0) {
    renderedStudents = orgarr_nxt
      .map((org:any) => {
        return {
          label: org.name,
          value: org.id,
          imageUrl: "",
        };
      });
  } else {
    renderedStudents = [];
  }
}
console.log("----->"+renderedStudents)
  let renderedLogs;
 
  const totalNumberPages=(totalRecipe: number,sizes: number)=>{
    let pagesr =[];
    for(let x=1;x<=totalRecipe/sizes;x++)
    {
      pagesr.push(x);
    }
    return pagesr;
  }
  if (entrylog.length > 0) {
   let totalRecipe=entrylog.length;

    renderedLogs = entrylog.map((entrylog: any, index: any) => {
      const { for_user_id,
        entry_from_user_id, 
        entry_from_user_type, 
        entry_reason,
        entry_ip_address,
        createdAt,
        forusername,
        fromuser,
        browser_name,
        id } = entrylog;
       // console.warn("Users",entry_from_user_id.name);
        
      return (
        <StyledTableRow key={index}>

          <StyledTableCell component='th' scope='row'>
            {index + 1}
          </StyledTableCell>
          {/* <StyledTableCell>{referenceCode}</StyledTableCell> */}
          <StyledTableCell>{forusername?.name}</StyledTableCell>
          <StyledTableCell>{fromuser?.name}</StyledTableCell>
          <StyledTableCell>{entry_from_user_type}</StyledTableCell>
          <StyledTableCell>{entry_reason}</StyledTableCell>
          <StyledTableCell>{browser_name}</StyledTableCell>
          {/* <StyledTableCell>{entry_ip_address}</StyledTableCell> */}
          <StyledTableCell>{createdAt}</StyledTableCell>
          {/* <StyledTableCell>{updatedAt}</StyledTableCell> */}
         
        </StyledTableRow>
      );
    });
  } else if (entrylog.length === 0) { 
    renderedLogs = (
      <StyledTableRow>
        <StyledTableCell component='th' scope='row'>
          <Typography variant='subtitle2' className={classes.label}>
            No Records found.
          </Typography>
        </StyledTableCell>
      </StyledTableRow>
    );
  } else {
    renderedLogs = (
      <StyledTableRow>
        <StyledTableCell component='th' scope='row'></StyledTableCell>
      </StyledTableRow>
    );
  }
  
        return (
          <div>
            <Grid
              container
              direction='row'
              justifyContent='space-between'
              alignItems='center'
            >
              <Grid item md={12} xs={12}>
                <Typography variant='h6'>Onboarding Completion</Typography>
              </Grid>
              {/* <Grid item md={8} xs={8}>
                <Typography variant='h6'>Daily Active Users</Typography>
              </Grid> */}
              <Grid item md={4} xs={4}>
                {/* <label><b>Select Organization</b></label> */}
                <Autocomplete
                    freeSolo
                    autoComplete
                    id=''
                    disableClearable={false}
                    options={renderedStudents}
                    onChange={(e, option:any) => {
                      if (typeof option !== 'string') {
                        getSearchOrganisation(e,option.value)
                      }

                    }}
                    //value={orgSelect}
                    renderInput={(params) => (
                      <TextField {...params} label='' variant='outlined' size='small' placeholder='Select Organization' value={orgSelect}/>
                    )}
                    getOptionLabel={(option) =>
                      typeof option !== 'string' && option.label ? option.label : ''
                    }
                    style={{width:'250px',height:'40px'}}
                  />
              </Grid>
              <Grid item md={3} xs={6} >              
                <TextField
                id='date'
                placeholder='From Date'
                defaultValue="YYYY-MM-DD00:00:00:000Z"
                type='date'
                className='textField'
                onChange={handleSelectFrom}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              </Grid>
              <Grid item md={3} xs={6} >
                <TextField
                id='date'
                placeholder='To Date'
                defaultValue="YYYY-MM-DD00:00:00:000Z"
                type='date'
                className='textField'
                onChange={handleSelectDateTo}
                InputLabelProps={{
                  shrink: true,
                }}
              />
           </Grid>
           <Grid item md={2} xs={12}>
              &nbsp;<Button
                variant='contained'
                color='primary'
                onClick={() => filteredData()}
               // style={{"marginTop":"16px"}}
                startIcon={<SearchIcon />}
              >
                {/* Add Student */}
                SEARCH 
              </Button>
            </Grid>
            </Grid>
            <br />
           
            <TableContainer component={Paper}>
              <Table aria-label='customized table'>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>S.No.</StyledTableCell>
                    <StyledTableCell>User Name</StyledTableCell>
                    <StyledTableCell>Entry From User</StyledTableCell>
                    <StyledTableCell>Entry From User Type</StyledTableCell>
                    <StyledTableCell>Entry Reason</StyledTableCell>
                    <StyledTableCell>Entry Browser</StyledTableCell>
                    {/* <StyledTableCell>Ip Address</StyledTableCell> */}
                    <StyledTableCell>Created At</StyledTableCell>
                    {/* <StyledTableCell>Updated At</StyledTableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>{renderedLogs}</TableBody>
              </Table>
            </TableContainer>
            <br/>
      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='center'
      >
        <Grid item md={8} xs={8}>

        </Grid>
        <Grid item md={4} xs={4}>
          <nav aria-label="Page navigation example">
            <ul className="pagination justify-content-end">
              {recipesPage!==1 && <li className="page-item" onClick={()=>setPage(rpage - 1)}>
                <a className="page-link" href="javascript:void(null)" >Previous</a>
              </li>}
             {totalNumberPages(totalRecipe,sizes).map(pageNo => 
                <li className={`page-item ${pageNo===recipesPage ? 'active':''}`} key={pageNo} onClick={()=>setPage(pageNo)}>
                  <a className="page-link" href="javascript:void(null)">
                    {pageNo}
                  </a>
                </li>
              )} 
             
                {
                  totalRecipe > sizes?
                  recipesPage!==totalRecipe/sizes && <li className="page-item" onClick={()=>setPage(rpage + 1)}>
                  <a className="page-link" href="javascript:void(null)">Next</a>
                </li>:""
                }
              
            </ul>
          </nav>
        </Grid>
      </Grid>
          </div>
        );
};

export default OnboardingCompletion;


import React, { useEffect, useState } from 'react';
import {
  Button,
  FormControl,
  InputLabel,
  InputAdornment,
  makeStyles,
  TextField,
  Typography,
  Grid,
  IconButton,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { FormikProps } from 'formik';
import formStyles from '../../../assets/jss/components/formStyles';
import { EnhancedLoginFormValues } from './EnhancedLoginForm';
import { RootState } from '../../../app/rootReducer';
import { FORGOT_PASSWORD, REGISTER } from '../../../constants/routes';

import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { AiOutlineEye } from 'react-icons/ai';
import Show from '@material-ui/icons/Visibility';
import Hide  from '@material-ui/icons/VisibilityOff';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { setBrowName } from '../../../slices/authSlice';
interface IStudentRegisterFormProps {}

const useStyles = makeStyles(formStyles);

export const LoginForm: React.FC<
  IStudentRegisterFormProps & FormikProps<EnhancedLoginFormValues>
> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const debug = false;
  const { values, errors, touched, handleSubmit, handleBlur, handleChange } =
    props;

  const handleLoginSubmit = (e: any) => {
    e.preventDefault();
    handleSubmit();
  };

  const [visible, setVisible] = useState(false);

  const { authLoading } = useSelector((state: RootState) => {
    return {
      authLoading: state.auth.loading,
    };
  }, shallowEqual);

  useEffect(() => {
    addBrowser();
        return () => {};
      }, []);

  const [browserName, setBrowserName] = useState('');
  function addBrowser()
  {
    const userAgent = window.navigator.userAgent;
       if (userAgent.indexOf('Chrome') > -1) {
        setBrowserName('Google Chrome');
        console.log("test brows-->'Google Chrome'");
        dispatch(setBrowName('Google Chrome'));

      } else if (userAgent.indexOf('Firefox') > -1) {
        setBrowserName('Mozilla Firefox');
        console.log("test brows-->'Mozilla Firefox'");
        dispatch(setBrowName('Mozilla Firefox'));
  
      } else if (userAgent.indexOf('Safari') > -1) {
        setBrowserName('Apple Safari');
        console.log("test brows-->'Apple Safari'");
        dispatch(setBrowName('Apple Safari'));
 
      } else if (userAgent.indexOf('Edge') > -1) {
        setBrowserName('Microsoft Edge');
        console.log("test brows-->'Edge'");
        dispatch(setBrowName('Edge')); 
  
      } else  if (userAgent.indexOf('Opera') > -1 || userAgent.indexOf('OPR') > -1) {
        setBrowserName('Opera');
        console.log("test brows-->'Opera'");
        dispatch(setBrowName('Opera'));
  
      } else if (userAgent.indexOf('MSIE') > -1 || userAgent.indexOf('Trident/') > -1) {
        setBrowserName('Internet Explorer');
        console.log("test brows-->'Internet Explorer'");
        dispatch(setBrowName('Internet Explorer'));
  
      } else {
        setBrowserName('Unknown browser');
        dispatch(setBrowName('Unknown browser'));
  
      }

      console.log("test-->"+browserName);
      //handleSubmit();
  }
  return (
    <div>
      <div style={{ margin: '0px 0px 32px' }}>
        <Typography variant='body1' className={classes.loginText}>
          LOGIN
        </Typography>
        <Typography variant='h4' className={classes.welcomeBackText}>
          Welcome Back
        </Typography>
        <Typography variant='body1' className={classes.loginText}>
          Login to manage your account
        </Typography>
      </div>

      <form onSubmit={handleLoginSubmit}>
        <FormControl required className={classes.formControl}>
          <Typography variant='subtitle1' className={classes.label}>
            Email
          </Typography>
          <TextField
            id='emailAddress'
            placeholder='Enter your email'
            type='email'
            name='email'
            variant='outlined'
            size='small'
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            error={(errors.email && touched.email) as boolean}
            helperText={errors.email && touched.email && errors.email}
            InputLabelProps={{
              classes: {
                root: classes.heading,
                focused: classes.cssFocused,
              },
            }}
          />
        </FormControl>

        <br />
        <FormControl required className={classes.formControl}>
          <Grid
            container
            direction='row'
            justifyContent='space-between'
            alignItems='center'
          >
            <Grid item>
              <Typography variant='subtitle1' className={classes.label}>
                Password
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant='subtitle2' className={classes.label}>
                <Link to={FORGOT_PASSWORD} className={classes.link}>
                  Forgot your password?
                </Link>
              </Typography>
            </Grid>
          </Grid>
          
          <TextField
            id='password'
            placeholder='Enter your password'
            type={visible ? 'text' : 'password'}
            name='password'
            variant='outlined'
            size='small'
            value={values.password}
            onChange={handleChange}
            onBlur={handleBlur}
            error={(errors.password && touched.password) as boolean}
            helperText={errors.password && touched.password && errors.password}
            InputLabelProps={{
              classes: {
                root: classes.heading,
                focused: classes.cssFocused,
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={()=>setVisible(!visible)}
                  >
                    {visible ?  <Show /> : <Hide />}
                  </IconButton>
                </InputAdornment>
              ),
            }}

          />
           {/* <span onClick={()=>setVisible(!visible)} style={{ position: 'absolute', bottom: '8px',  right: '12px', color: 'gray' }} >
            { visible ?  <Show /> : <Hide /> }  
         </ span> */}
        </FormControl>
        <br />
        <FormControl className={classes.formControl}>
          <Grid
            container
            direction='row'
            justifyContent='space-between'
            alignItems='center'
          >
            <Grid item>
              <Typography variant='subtitle2'>
                Don't have an account yet?{' '}
                <Link to={REGISTER} className={classes.link}>
                  Sign up here.
                </Link>
              </Typography>
            </Grid>
            <Grid item>
              <Button
                className={classes.secondaryButton}
                variant='contained'
                color='primary'
                type='submit'
                disabled={authLoading}
                // fullWidth
              >
                Login
              </Button>
            </Grid>
          </Grid>
        </FormControl>
      </form>
    </div>
  );
};

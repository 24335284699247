import React, { useState } from 'react';
import List from '@mui/material/List';
import Collapse from '@mui/material/Collapse';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';

import Box from '@mui/material/Box';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { TransitionGroup } from 'react-transition-group';
import {
  addInstruction,
  removeInstruction,
  toggleIsSpecial,
  addSubTask,
  removeNotiTime,
  addSubTaskImageToS3,
  setIndex,
} from '../../../slices/taskSlice';
import { RootState } from '../../../app/rootReducer';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { DropzoneDialog } from 'material-ui-dropzone';
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

interface IDisplayNotiTimeProps {}

export const DisplayNotiTimes: React.FC<
IDisplayNotiTimeProps
> = ({}) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  //const [imageModalOpen, setImageModalOpen] = useState(false);

  const { notiTime} = useSelector((state: RootState) => {
    return {
      notiTime: state.task.notiTime,
    };
  }, shallowEqual);

  let renderedSubTasks;
  if (notiTime.length > 0) {
    renderedSubTasks = notiTime.map((NotiTimeItem, index) => {
      return (
        <Collapse key={index}>
          <ListItem
            style={{ width: '100%' }}
            secondaryAction={
              <>
               
                &nbsp; &nbsp;
                <IconButton
                  edge='end'
                  aria-label='delete'
                  title='Delete'
                  onClick={() => {
                    //dispatch(removeInstruction(instruction));
                    dispatch(removeNotiTime(NotiTimeItem));
                  }}
                  color='error'
                >
                  <DeleteIcon />
                </IconButton>
              </>
            }
          >
            <ListItemText primary={`${index + 1}. ${NotiTimeItem.notification_time+" minutes"}`} />
          </ListItem>
          
        </Collapse>
      );
    });
  }
  return (
    <div>
      <Box sx={{ mt: 1 }}>
        <List>
          <TransitionGroup>{renderedSubTasks}</TransitionGroup>
        </List>
      </Box>
    </div>
  );
};

import { Recipe } from '../constants/models/Recipe';
import { Tag } from './../constants/models/Tag';

import { RECIPE_ROUTE, PROGRAM_ROUTE, IMAGE_ROUTE } from '../constants/routes';
import Resizer from "react-image-file-resizer";

import API from './api';

export async function getFavoriteRecipesForUser(studentId: string) {
  try {
    const res = await API.post<{ data: Recipe[]; success: boolean }>(
      `${RECIPE_ROUTE}/${studentId}/getRecipeToFavorite`,
      {
        studentId:studentId,
        
      }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function getRecipeById(recipeId: string,userId: string) {
  try {
    const res = await API.post<{ data: Recipe; success: boolean }>(
      `${RECIPE_ROUTE}/getRecipeDetails`,
      {
        recipeId:recipeId,
        userId:userId
      }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}
export async function getRecipesAll() {
  try {
    const res = await API.get<{ data: Recipe[]; success: boolean }>(
      `${RECIPE_ROUTE}/all-recipesforweb`
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}
export async function getRecipesBySearch(
  programId: string,
  recipeTitle: string,
  TypeOfRecipe: string,
  page: number,
  size: number,
)
  {
  try {
    console.log("program id-->"+programId);
    const res = await API.post<{ data: Recipe[]; success: boolean;total:number;page:number;size:number; }>(
      `${RECIPE_ROUTE}/all-recipesforwebAdminBySearch`,
      {
        recipeTitle,
        TypeOfRecipe,
        page,
        size
      }
    );
   // return res.data.data;
    return res;
  } catch (err) {
    throw err;
  }
}

export async function getRecipesForProgram(programId: string, recipeTitle: string,TypeOfRecipe: string,recipeTags:string, pages: number,sizes: number,studentId: string,) {
  try {
    console.log("program id-->"+programId);
    const res = await API.post<{ data: Recipe[]; success: boolean;total:number;page:number;size:number; }>(
      `${RECIPE_ROUTE}/all-recipesforwebAdmin`,
      {
        recipeTitle,
        TypeOfRecipe,
        recipeTags,
        page:pages,
        size:sizes,
        studentId:studentId
      }
    );
    //return res.data.data;
    return res;
  } catch (err) {
    throw err;
  }
}

export async function getRecipesForProgramWithoutUser(programId: string, recipeTitle: string,TypeOfRecipe: string, pages: number,sizes: number) {
  try {
    console.log("program id-->"+programId);
    const res = await API.post<{ data: Recipe[]; success: boolean;total:number;page:number;size:number; }>(
      `${RECIPE_ROUTE}/all-recipesforwebAdminWithoutUser`,
      {
        recipeTitle,
        TypeOfRecipe,
        page:pages,
        size:sizes,
      } 
    );
    //return res.data.data;
    return res;
  } catch (err) {
    throw err;
  }
}
export async function getRecipesForTask(recipeTitle: string,TypeOfRecipe: string,recipeTagType: string) {
  try {
    const res = await API.post<{ data: Recipe[]; success: boolean; }>(
      `${RECIPE_ROUTE}/all-recipesfortask`,
      {
        recipeTitle,
        TypeOfRecipe,
        recipeTagType
        
      }
    );
    //return res.data.data;
    return res;
  } catch (err) {
    throw err;
  }
}
export async function getRecipesForWebUser(programId: string, recipeTitle: string,TypeOfRecipe: string, pages: number,sizes: number,) {
  try {
    console.log("program id-->"+programId);
    const res = await API.post<{ data: Recipe[]; success: boolean;total:number;page:number;size:number; }>(
      `${RECIPE_ROUTE}/${programId}/all-recipesforwebUser`,
      {
        recipeTitle,
        TypeOfRecipe,
        page:pages,
        size:sizes
      }
    );
    //return res.data.data;
    return res;
  } catch (err) {
    throw err;
  }
}
export async function createRecipe(
    recipeTitle: string,
    recipeUrl: string,
    recipeDescription: string,
    TypeOfRecipe: string,
    tags: string[],
    preparationTime: string,
    brosname:string,
    brosdevicename:string,
    programId: string
) {
  try {
    const res = await API.post<{ data: Recipe; success: boolean }>(
      `${PROGRAM_ROUTE}/${programId}${RECIPE_ROUTE}/create-recipeforweb`,
      {
        recipeTitle,
        recipeUrl,
        recipeDescription,
        TypeOfRecipe,
        recipeTags:tags,
        preparationTime,
        brosname,
        brosdevicename,
        programId 
      }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function updateRecipe(
  id: string,
  recipeUrl: string,
  recipeTitle: string,
  recipeDescription: string,
  TypeOfRecipe: string,
  preparationTime: string,
  brosname: string,
  brosdevicename:string,
  userId: string,
  tags: string[],

) {
  try {
    const res = await API.post<{ data: Recipe; success: boolean }>(
      `${RECIPE_ROUTE}/${id}/updateRecipeForWeb`,
      {
        recipeUrl,
        recipeTitle,
        recipeDescription,
        TypeOfRecipe,
        preparationTime,
        brosname,
        brosdevicename,
        userId,
        tags
      }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}
export async function ViewRecipeLogs(studentId: string,program_id:string,brosname:string,brosdevicename:string) {
  try {
    const res = await API.post<{ data: Recipe; success: boolean }>(
      `${RECIPE_ROUTE}/viewRecipeLogs`,
      {
        studentId:studentId,
        programId:program_id,
        brosname,
        brosdevicename
      }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}
export async function deleteRecipe(id: string,programId: string,brosname:string,brosdevicename:string) {
  try {
    const res = await API.post<{ data: {}; success: boolean }>(
      `${RECIPE_ROUTE}/${id}/deleteForWeb`,
      {
        brosname,
        brosdevicename,
        programId
      }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}
export async function addRecipeImagesToS3(formData: FormData) {
  try {
    console.warn("form data ----->"+JSON.stringify(formData));
    //console.warn("form data program id----->"+JSON.stringify(formData.programId));

    const res = await API.post<{ data: string; success: boolean }>(
      `${RECIPE_ROUTE}/add-recipeimage`,
      //
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}
export async function addRecipeToS3(formData: FormData) {
  try {
    console.warn("form data ----->"+JSON.stringify(formData));
    //console.warn("form data program id----->"+JSON.stringify(formData.programId));

    const res = await API.post<{ data: string; success: boolean }>(
      `${IMAGE_ROUTE}/add-image_next`,
      //
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function addLibraryImageToS3(formData: FormData,files:any[]) {
  try {

    let file = files[0];

       Resizer.imageFileResizer(
      file,
      680,
      460,
      "JPEG",
      100,
      0,
      async(uri:any) => {
        //console.log(uri);
       // console.warn("Form data view--->" + JSON.stringify(uri));
       let fdata= getImageUrl(uri);
       //setImg(fdata);
       const formData = new FormData();
       formData.append('file', fdata);  
            const res = await API.post<{ data: string; success: boolean }>(
              `${IMAGE_ROUTE}/add-image_next`,
              //
              formData,
              {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              }
            );
            console.log("img-->"+res.data.data);

            return res.data.data;
          },
          "base64",
          200,
          200
      );
  } catch (err) {
    throw err;
  }
}
export async function getRecipeTags(programId: string) {
  try {
    const res = await API.post<{ data: Tag[]; success: boolean }>(
      `${RECIPE_ROUTE}/getRecipeTypeAll`
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}
export async function deleteRecipeImage(id: string,recipeId: string) {
  try {
    const res = await API.post<{ data: {}; success: boolean }>(
      `${RECIPE_ROUTE}/deleteRecipeImage`,
      {
        id,
        recipeId,
       
      }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

function getImageUrl(imguri:any)
  {
    let arr=imguri.split(",");
    let mime=arr[0].match(/:(.*?);/)[1];
    let data=arr[1];

    let dataStr=atob(data);
    let n=dataStr.length;
    let dataArr=new Uint8Array(n);
    while(n--)
    {
      dataArr[n]=dataStr.charCodeAt(n);
    }
    let file = new File([dataArr],'file.jpg',{type:mime});
    //console.log(file);
    return file;
  }

  export async function getChangePositionAPi(
    id: string,
    pos:Number
  ) {
    try {
      const res = await API.post<{ data: []; success: boolean }>(
        `${RECIPE_ROUTE}/updateStepPositionFromWeb`,
        {
          id:id,
          step_position:pos,
        }
      );
      return res.data.data;
    } catch (err) {
      throw err;
    }
  }
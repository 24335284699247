import { setTempCode, updateDetailsForGraphQlUser } from './authSlice';
import { USER_ROLE } from './../constants/userRoles';
import store, { AppThunk } from './../app/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from './../constants/models/User';
import * as REQUESTS from '../api/parentRequests';
import { PARENT_ADD_CHILD, PARENT_DASHBOARD, SUPERADMIN_PARENT_PROFILES } from '../constants/routes';
import { History } from 'history';
import { setSuccessMsg, setErrorMsg } from './alertSlice';
import { Ingredient } from './../constants/models/Ingredient';
import { setStudents } from './studentSlice';


export interface parentState {
  loading: boolean;
  error: string | null;
  hasAddedChild: boolean;
  parent: User | null;
  parents: User[] | null;
  ingidiansList: Ingredient | null;
}

export const initialState: parentState = {
  loading: false,
  error: null,
  hasAddedChild: false,
  parent: null,
  parents:null,
  ingidiansList:null

};

const parentSlice = createSlice({
  name: 'parent',
  initialState,
  reducers: {
    parentStart(state) {
      state.loading = true;
      state.error = null;
    },
    setHasAddedChild(state, action: PayloadAction<boolean>) {
      state.hasAddedChild = action.payload;
    },
    setParent(state, action: PayloadAction<User | null>) {
      state.parent = action.payload;
    },
    setIngridiansList(state, action: PayloadAction<Ingredient | null>) {
      state.ingidiansList = action.payload;
    },
    setParentLists(state, action: PayloadAction<{ data: User[] }>) {
      const { data } = action.payload;
      state.parents = data.map((parent) => parent);
      console.warn("state-->"+state.parents);
    },
    parentFailure(state, action: PayloadAction<string | null>) {
      state.loading = false;
      state.error = action.payload ? action.payload : 'some error';
    },
    parentComplete(state) {
      state.loading = false;
    },
  },
});

export const {
  parentStart,
  setHasAddedChild,
  setParent,
  parentComplete,
  parentFailure,
  setIngridiansList,
  setParentLists
} = parentSlice.actions;

export default parentSlice.reducer;
export const getParentsList =
  (): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(parentStart());
      const parent = await REQUESTS.getParentsList();
      dispatch(setParentLists({ data: parent }));
      dispatch(parentComplete());
    } catch (error: any) {
      if (error.response) {
        const { data, status } = error.response;
        dispatch(parentFailure(data));
      }
    }
  };
export const getParentById =
  (parentId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(parentStart());
      const parent = await REQUESTS.getParentById(parentId);
      dispatch(setParent(parent));
      dispatch(parentComplete());
    } catch (error: any) {
      if (error.response) {
        const { data, status } = error.response;
        dispatch(parentFailure(data));
      }
    }
  };

  export const getParentLogById =
  (parentId: string, brosname:string, brosdevicename:string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(parentStart());
      const parent = await REQUESTS.getParentLogById(parentId,brosname,brosdevicename);
      //dispatch(setParent(parent));
      dispatch(parentComplete());
    } catch (error: any) {
      if (error.response) {
        const { data, status } = error.response;
        dispatch(parentFailure(data));
      }
    }
  };

  export const getOnboardingLogsEntry =
  (parentId: string): AppThunk =>
  async (dispatch) => {
    try {

      dispatch(parentStart());
      let {brosname } = store.getState().auth;
      let brosdevicename = 'Onboarding Completion';
      const parent = await REQUESTS.getOnboardingLogsEntry(parentId,brosname,brosdevicename);
      console.warn("onboarding logs entry==>",parent);
      //dispatch(setParent(parent));
      dispatch(parentComplete());
    } catch (error: any) {
      if (error.response) {
        const { data, status } = error.response;
        dispatch(parentFailure(data));
      }
    }
  };
  export const getParentByIdRefresh =
  (parentId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(parentStart());
      const parent = await REQUESTS.getParentById(parentId);
      dispatch(setParent(parent));
      localStorage.setItem('userData', JSON.stringify(parent));

      dispatch(parentComplete());
    } catch (error: any) {
      if (error.response) {
        const { data, status } = error.response;
        dispatch(parentFailure(data));
      }
    }
  };
export const createParent =
  (id: string, name: string, email: string, tempPassword: string, brosname:string, brosdevicename: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(parentStart());
      let text = email;
            email = text.toLowerCase();
      let parent = await REQUESTS.createParent(id, name, email, tempPassword, brosname,brosdevicename);
      dispatch(parentComplete());
    } catch (error: any) {
      if (error.response) {
        const { data, status } = error.response;
        dispatch(parentFailure(data));
      }
    }
  };

export const updateParent =
  (
    id: string | undefined,
    name: string,
    email: string,
    address: string,
    street: string,
    city: string,
    state: string,
    country: string,
    postalCode: number,
    phoneno:string,
    countryCode: string,
    // about: string,
    imageUrl: string | undefined,
    history: History
  ): AppThunk =>
  async (dispatch, getStore) => {
    try {
      dispatch(parentStart());
      let text = email;
      email = text.toLowerCase();
      const parent = await REQUESTS.updateParent(
        id!,
        name,
        email,
        address,
        street,
        city,
        state,
        country,
        postalCode,
        phoneno,
        countryCode,
        // about,
        imageUrl
      );
      dispatch(setSuccessMsg(`Profile updated!`));
      dispatch(getParentById(id!));
      const { role } = getStore().auth;
      if (role === USER_ROLE.PARENT) {
        const { graphQlUser } = getStore().auth;
        dispatch(
          updateDetailsForGraphQlUser(
            id!,
            email,
            name,
            imageUrl ?? graphQlUser.imageUrl,
            email,
            graphQlUser.about
          )
        );
      }

      history.push(PARENT_DASHBOARD);

      dispatch(parentComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        // dispatch(studentFailure(error));
        dispatch(setErrorMsg(error));
        dispatch(parentFailure(error));
      }
    }
  };

export const getChildForParent =
  (parentId: string, history: History): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(parentStart());
      const students = await REQUESTS.getChildForParent(parentId);
      if (students.length > 0) {
        dispatch(setHasAddedChild(true));
        dispatch(setStudents({ data: students }));
        // history.push(PARENT_DASHBOARD);
      } else {
        dispatch(setHasAddedChild(false));
       // history.push(PARENT_DASHBOARD);
      }

      dispatch(parentComplete());
    } catch (error: any) {
      if (error.response) {
        const { data, status } = error.response;
        dispatch(parentFailure(data));
      }
    }
  };

export const getChildForParentRefresh =
  (parentId: string, history: History): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(parentStart());
      const students = await REQUESTS.getChildForParent(parentId);
      if (students.length > 0) {
        dispatch(setHasAddedChild(true));
        dispatch(setStudents({ data: students }));
       // history.push(PARENT_DASHBOARD);
      } else {
        dispatch(setHasAddedChild(false));
        //history.push(PARENT_ADD_CHILD);
      }

      dispatch(parentComplete());
    } catch (error: any) {
      if (error.response) {
        const { data, status } = error.response;
        dispatch(parentFailure(data));
      }
    }
  };

export const getIngidiansListForStudent =
  (recipeId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(parentStart());
      // const { program } = store.getState().program;

      // if (program && program.id) {
        const { program } = store.getState().program;
        const { parent } = store.getState().parent;
        let program_de_id="";
        if(parent?.id)
        {
          program_de_id=parent.id;
        }
        else if(program?.id)
        {
          program_de_id=program.id;
        }
      
        if (program_de_id) {
        let programId = program_de_id;//program.id;
        const data = await REQUESTS.getParentIngridiansList(recipeId);
        if (data.length > 0) {
          console.log(data);
          dispatch(setIngridiansList(data[0]));
        } else {
          const newData = await REQUESTS.getParentIngridiansList(
            recipeId
          );
          console.log(newData);
          dispatch(setIngridiansList(newData));
        }

        // dispatch(setStudents({ data: students }));
      }
      dispatch(parentComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(parentFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };

  export const sendMailToParent =
  (name: string, email: string, tempPassword: string, subject: string,otpcode:number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(parentStart());
      dispatch(setTempCode(otpcode));

      let parent = await REQUESTS.sendMailToParent(name, email, tempPassword, subject, otpcode);
      console.log("parent code---->"+parent)
     
      dispatch(setSuccessMsg("Verification Code Sent Succesfully"));
     
      
      dispatch(parentComplete());
    } catch (error: any) {
      if (error.response) {
        const { data, status } = error.response;
        dispatch(parentFailure(data));
      }
    }
  };

  export const updateNewPassword =
  (email: string, tempPassword: string,check_nxt:string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(parentStart());
      let text = email;
      email = text.toLowerCase();
      let parent = await REQUESTS.updateNewPassword(email, tempPassword,check_nxt);
      console.warn("update password data parent====>",parent);
      
      dispatch(setSuccessMsg('Password updated successfully'));
      dispatch(parentComplete());

    } catch (error: any) {
      if (error.response) {
        const { data, status } = error.response;
        dispatch(parentFailure(data));
      }
    }
  };
  // export const updatepasswordLogEntry =
  // (brosname:string,brosdevicename:string): AppThunk =>
  // async (dispatch) => {
  //   try {
  //     dispatch(parentStart());
  //     let parent = await REQUESTS.updatepasswordLogEntry(brosname,brosdevicename);
  //     dispatch(parentComplete());

  //   } catch (error: any) {
  //     if (error.response) {
  //       const { data, status } = error.response;
  //       dispatch(parentFailure(data));
  //     }
  //   }
  // };
  export const updateParentBySuperAdmin =
  (
    id: string | undefined,
    name: string,
    email: string,
    address: string,
    street: string,
    city: string,
    state: string,
    country: string,
    postalCode: number,
    // about: string,
    imageUrl: string | undefined,
    history: History
  ): AppThunk =>
  async (dispatch, getStore) => {
    try {
      dispatch(parentStart());
      let text = email;
      email = text.toLowerCase();
      const programManager = await REQUESTS.updateParentBySuperAdmin(
        id!,
        name,
        email,
        address,
        street,
        city,
        state,
        country,
        postalCode,
        // about,
        imageUrl
      );
      const { role } = getStore().auth;
      if (role === USER_ROLE.PROGRAM_MANAGER) {
        // const { graphQlUser } = getStore().auth;
        // dispatch(
        //   updateDetailsForGraphQlUser(
        //     id!,
        //     email,
        //     name,
        //     imageUrl ?? graphQlUser.imageUrl,
        //     email,
        //     graphQlUser.about
        //   )
        // );
      }
      localStorage.setItem('userData', "");

      dispatch(setSuccessMsg(`Program Manager updated!`));
     // dispatch(getProgramManagerById(id!));

      history.push(SUPERADMIN_PARENT_PROFILES);

      dispatch(parentComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        // dispatch(studentFailure(error));
        dispatch(setErrorMsg(error));
        dispatch(parentComplete(error));
      }
    }
  };
import React, { useEffect, useState } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
  IconButton,
  Grid,
  FormControl,
  TextField
} from '@material-ui/core';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Link } from 'react-router-dom';
import formStyles from '../../../assets/jss/components/formStyles';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../app/rootReducer';
import { useHistory } from 'react-router-dom';
import Amplify, { Auth, API, graphqlOperation } from 'aws-amplify';

import {
    getIngidiansListForStudent,
    deleterecipeIngredient,
    getrecipeIngredientById,
} from '../../../slices/recipeingredientSlice';
import { StyledTableRow } from '../../../components/StyledTableRow';
import { StyledTableCell } from '../../../components/StyledTableCell';
import Button from '@mui/material/Button';
import Carousel from 'react-material-ui-carousel';
//import { Paper, Button } from '@mui/material';


import { updateNewPassword } from '../../../slices/parentSlice';
import { logout_next } from '../../../slices/authSlice';
import { setBrowName } from '../../../slices/authSlice';


interface IChangePasswordProps {}

const useStyles = makeStyles(formStyles);

export const ChangePassword: React.FC<IChangePasswordProps> = ({}) => {

  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const [codeerr, setCodeerr] = useState("");
  const [confirmcodeerr, setConfirmcodeerr] = useState("");
  const [newPasserr, setnewPasserr] = useState("");
  const [isDisabled, setDisabled] = useState(false);

  const [currentPassword, setCurrentPassword] = useState('');
  const [originPassword, setOriginPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [chkset, setChkSet] = useState(false);

  const userEmailn = localStorage.getItem('userEmail');
// if(userEmailn)
// {
//   setUserEmail(userEmailn)
// }
  const handleChange = async (event:any) => {
    setCurrentPassword(event.target.value);

  };
  const checkCurrentPass = async (event:any) => {

    if(currentPassword==="")
    { 
      
      setCodeerr('Enter current password please !');
    }
    else
    {
      if(userEmailn)
      {

        try{
          let user1 = await Auth.signIn(userEmailn, currentPassword);
          setChkSet(true)
              if (user1) {
                  setDisabled(false)
                  setCodeerr('');
                  setConfirmcodeerr('');
                  setnewPasserr('')

              }else{
      
              }
          }catch(e){
              console.log(e)
              setDisabled(true)
              setCodeerr('Current Password is Incorrect!');

          }

       
      }
    }
          

  };
  const checkConfirmPassword =  () => {
   
    if(confirmPassword==="")
    {
      setConfirmcodeerr("Enter confirm password please !");
      setDisabled(true);
    } else if(originPassword!==confirmPassword)
    {
      setConfirmcodeerr("Confirm password didn't match !");
      setDisabled(true);
    }
    else if(codeerr!=="")
    {
     
      setDisabled(true);
    }
    else{
      setConfirmcodeerr("");
      setDisabled(false);
    }
  };

  const checkNewPassword =  () => {
   
    if(originPassword==="")
    {
      setnewPasserr("Enter new password please !");
      setDisabled(true);
    } 
    else if(originPassword.length<6)
    {
      setnewPasserr("Password is too short - should be 6 characters minimum.");
      setDisabled(true);
    } 
    else if(confirmPassword==="")
    {
      setConfirmcodeerr("Enter confirm password please !");
      setDisabled(true);
      setnewPasserr('')
    } 
    else if(originPassword!==confirmPassword)
    {
      setConfirmcodeerr("Confirm password didn't match !");
      setDisabled(true);
      setnewPasserr('')

    }
    else if(codeerr!=="")
    {
      setnewPasserr('')

      setDisabled(true);
    }
    else{
      setConfirmcodeerr("");
      setDisabled(false);
      setnewPasserr('')

    }
  };

  const functionUpdatePassword = async () => {

    if(userEmailn)
    {
        if(currentPassword==="" && originPassword==="" && confirmPassword==="")
        { 
          
          setCodeerr('Enter current password please !');
          setnewPasserr("Enter new password please !");
          setConfirmcodeerr("Enter confirm password please !");
        }
        else if(originPassword==="" && confirmPassword==="")
        {
          setCodeerr('');
          setnewPasserr("Enter new password please !");
          setConfirmcodeerr("Enter confirm password please !");
        }
        else if(confirmPassword==="")
        {
          setCodeerr('');
          setnewPasserr("");
          setConfirmcodeerr("Enter confirm password please !");
        }
        else if(chkset===false)
        {
          try{
            let user1 = await Auth.signIn(userEmailn, currentPassword);
            setChkSet(true)
                if (user1) {
                    setDisabled(false)
                    setCodeerr('');
                    setConfirmcodeerr('');
                    setnewPasserr('')

                }else{
        
                }
            }catch(e){
                console.log(e)
                setDisabled(true)
                setCodeerr('Current Password is Incorrect!');

            }
        }
        else
        {
        
              if(confirmPassword==="")
              {
                setConfirmcodeerr("Enter confirm password please !");
                setDisabled(true);
                setnewPasserr('')

              } 
              else if(originPassword!==confirmPassword)
              {
                setConfirmcodeerr("Confirm password didn't match !");
                setDisabled(true);
                setnewPasserr('')

              }
              else if(codeerr!=="")
              {      setnewPasserr('')

                setDisabled(true);
              }
              else{
                setConfirmcodeerr("");
                setDisabled(false);
                await dispatch(updateNewPassword(userEmailn,confirmPassword,"forgot"))
                setConfirmPassword("")
                setOriginPassword("")
                setCurrentPassword("")
                dispatch(logout_next(history))

              }
                
        }
    }
        
  };
  const { role} = useSelector(
    (state: RootState) => {
      return {
        role: state.auth.role,

      };
    },
    shallowEqual
  );
  const sliderStyle={
    width: "100%",
    height: "100%"
  }

  const authlocal_user_id = localStorage.getItem('user_id');


  console.warn("gquser---->"+JSON.stringify(authlocal_user_id));
  const user_id = localStorage.getItem('user_id');
  const urlId = localStorage.getItem('urlId');

  

  let loginUserId="";
  if(authlocal_user_id)
  {
    loginUserId=authlocal_user_id;
  }

  function checkCode()
  {
    
        
  }
  // useEffect(() => {
  //   addBrowser();
  //       return () => {};
  //     }, []);

  // const [browserName, setBrowserName] = useState('');
  // function addBrowser()
  // {
  //   const userAgent = window.navigator.userAgent;
  //      if (userAgent.indexOf('Chrome') > -1) {
  //       setBrowserName('Google Chrome');
  //       console.log("test brows-->'Google Chrome'");
  //       dispatch(setBrowName('Google Chrome'));

  //     } else if (userAgent.indexOf('Firefox') > -1) {
  //       setBrowserName('Mozilla Firefox');
  //       console.log("test brows-->'Mozilla Firefox'");
  //       dispatch(setBrowName('Mozilla Firefox'));
  
  //     } else if (userAgent.indexOf('Safari') > -1) {
  //       setBrowserName('Apple Safari');
  //       console.log("test brows-->'Apple Safari'");
  //       dispatch(setBrowName('Apple Safari'));
 
  //     } else if (userAgent.indexOf('Edge') > -1) {
  //       setBrowserName('Microsoft Edge');
  //       console.log("test brows-->'Edge'");
  //       dispatch(setBrowName('Edge')); 
  
  //     } else  if (userAgent.indexOf('Opera') > -1 || userAgent.indexOf('OPR') > -1) {
  //       setBrowserName('Opera');
  //       console.log("test brows-->'Opera'");
  //       dispatch(setBrowName('Opera'));
  
  //     } else if (userAgent.indexOf('MSIE') > -1 || userAgent.indexOf('Trident/') > -1) {
  //       setBrowserName('Internet Explorer');
  //       console.log("test brows-->'Internet Explorer'");
  //       dispatch(setBrowName('Internet Explorer'));
  
  //     } else {
  //       setBrowserName('Unknown browser');
  //       dispatch(setBrowName('Unknown browser'));
  
  //     }

  //     console.log("test-->"+browserName);
  //     handleSubmit();
  // }
  let renderedStudents;
  if (user_id) {
    

    //console.warn("images-->"+JSON.stringify(recipe.recipeImages));

    let renderedIngred;
    renderedStudents =(<>
        <div className='row'>
          
          <div className='col-sm-12 '>
          <div style={{ margin: '0px 0px 22px' }}>
        {/* <Typography variant='body1' className={classes.loginText}>
          UPDATE PASSWORD
        </Typography>
        <Typography variant='h4' className={classes.welcomeBackText}>
          Set New Password
        </Typography> */}
       
      </div>
      <form className={classes.root} >
        <FormControl required className={classes.formControl}>
          <Typography variant='subtitle1' className={classes.label}>
            Enter current password <span style={{color:'red'}}>*</span>
          </Typography>
          <input
            maxLength={8}
            type='text'
            placeholder='Enter Current Password'
            name='code'
            value={currentPassword} 
            onChange={handleChange}         
            onBlur={checkCurrentPass} 
            className="form-control"   
            minLength={6}
            />
        </FormControl>
        <span style={{color:'red',fontWeight:'700'}}>{codeerr}</span>
        <FormControl required className={classes.formControl}>
          <Typography variant='subtitle1' className={classes.label}>
            Enter New Password <span style={{color:'red'}}>*</span>
          </Typography>
          <input
            id='password'
            placeholder='Enter New Password'
            type='password'
            name='password'
            value={originPassword} 
            onChange={(e)=> setOriginPassword(e.target.value)}   
            className="form-control"   
            onBlur={checkNewPassword}   

            maxLength={8}
            minLength={6}
          />
        </FormControl>
                <span style={{color:'red',fontWeight:'700'}}>{newPasserr}</span>

        <FormControl className={classes.formControl}>
          <Typography variant='subtitle1' className={classes.label}>
          Enter Confirm Password <span style={{color:'red'}}>*</span>
          </Typography>
          <input
            placeholder='Enter Confirm Password'
            type='password'
            name='passwordConfirmation'
            value={confirmPassword} 
            onChange={(e)=> setConfirmPassword(e.target.value)}         
            onBlur={checkConfirmPassword}   
            className="form-control"   
            maxLength={8}
            minLength={6}
          />
        </FormControl>
        <span style={{color:'red',fontWeight:'700'}}>{confirmcodeerr}</span>

        <Button
          className={classes.secondaryButton}
          variant='contained'
          color='primary'
          type='button'
          disabled={isDisabled}
          fullWidth
          onClick={ functionUpdatePassword 
           }
        >
          Update password
        </Button>
      </form>
            
          </div>
          
        </div>
       
          
       

       
            
    </>)
  } else  {
    renderedStudents = (
      <StyledTableRow>
        <StyledTableCell component='th' scope='row'>
          <Typography variant='subtitle2' className={classes.label}>
            No User Found.
           
          </Typography>
        </StyledTableCell>
      </StyledTableRow>
    ); 
  } 

  return (
    <div>
       <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
            <Grid item md={10} xs={12}>
                <Button
                    color='primary'
                    startIcon={
                      <ArrowBackIosIcon
                        fontSize='small'
                        style={{ paddingRight: '0px' }}
                      />
                    }
                    onClick={(e) => {
                      history.go(-1);
                    }}
                    size='small'
                  >
                    Back
                  </Button>
            </Grid>
            
        </Grid>

      {/* <Typography variant='h6'>
        Recipes
      </Typography> */}
      <br />
     
     
      <div className='card card-primary'>
        <div className='card-header text-white' style={{backgroundColor:'#0092BC'}}>
          <h4>Change Password</h4>
        </div>
        <div className='card-body'>
        {renderedStudents}
        </div>
      </div>
    </div>
  );
};
export default ChangePassword;
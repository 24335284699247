import React, { useEffect, useState } from 'react';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
  IconButton,
  Grid,
  TextField,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from '../../../app/rootReducer';
import { StyledTableCell } from '../../../components/StyledTableCell';
import { StyledTableRow } from '../../../components/StyledTableRow';
import {
  ADD_COUNSELOR,
  ADD_SUPPORT_ADMIN,
  EDIT_COUNSELOR_PROFILE,
  EDIT_PARENTS_COUNSELOR_PROFILE,
} from '../../../constants/routes';
import {
  getCounselorById,
  getCounselorsForOrganisationProgramRefresh,
  getCounselorsForProgram,
  getCounselorsForProgramRefresh,
  getCounselorsForProgramRefreshParent,
  setCounselor,
  setCounselors,
} from '../../../slices/counselorSlice';
import AddIcon from '@mui/icons-material/Add';

import formStyles from '../../../assets/jss/components/formStyles';
import EditIcon from '@mui/icons-material/Edit';
import { approvedOrganisation, changeProgramStatus, getDeleteUserAllData, getOrganisationList, getProgramManagerList, getSearchOrganisationBySuperAdmin, getSearchOrganisationBySuperAdminorList, getSupportAdminList } from '../../../api/programManagerRequests';
import { Autocomplete } from '@mui/material';
import { FiRefreshCw} from 'react-icons/fi';
import { MdOutlineDelete } from 'react-icons/md';
import { setSuccessMsg } from '../../../slices/alertSlice';
import NoImageIcon from '@mui/icons-material/HideImage';


interface ISuperAdminCounselorProfileProps {}

const useStyles = makeStyles(formStyles);

const SuperAdminCounselorProfile: React.FC<ISuperAdminCounselorProfileProps> = ({}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();

  const { counselors } = useSelector((state: RootState) => {
    return {
      counselors: state.counselor.counselors,
    };
  }, shallowEqual);

  localStorage.setItem('urlRoute', "parent_admins");
  const [orgarr,setorgarr]=useState(counselors);
  const [orgarr_nxt,setorgarrNxt]=useState([]);
  const authlocal = localStorage.getItem('userRole');
  const user_id = localStorage.getItem('user_id');
  const [orgid,setOrgId]=useState("");

  const [isLoading, setIsLoading] = useState(false);

  // useEffect(() => {
  //   if(user_id && authlocal=="3")
  //   {
  //     dispatch(getCounselorsForProgramRefreshParent(user_id));
  //   }
  //   else if(user_id && authlocal=="0")
  //   {
  //     //dispatch(getCounselorsForOrganisationProgramRefresh(user_id));
  //     dispatch(getCounselorsForProgramRefreshParent(user_id));
  //   }
  //   else if(user_id && authlocal=="4")
  //   {
  //     dispatch(getCounselorsForProgramRefresh(user_id));
  //   }
  //   else
  //   {
  //     dispatch(getCounselorsForProgram());

  //   }
  //   dispatch(setCounselor(null));
  //   return () => {};
  // }, []);//counselors.length
  if(counselors.length>0)
  {
   // orgarr.push(counselors)
  }
  async function getEditUser(id:any)
  {
   
    let delconfirm=window.confirm("Do you want to delete this user");
    if(delconfirm)
    {
        setIsLoading(true)

        // const orgAdmins = await getSearchOrganisationBySuperAdminorList(orgid);
         const orgAdmins = await getDeleteUserAllData(id);
         if(orgAdmins)
           {
     
             if(user_id && authlocal=="4")
             {
              const orgData = await getSupportAdminList();
              if(orgData)
              {
                setorgarr(orgData)
              }
                 dispatch(setSuccessMsg("User Deleted Successfully"));
     
                 setIsLoading(false)
             }
     
           }
    }
   

  
  }
  let renderedCounselors;
  if (orgarr.length > 0) {
    renderedCounselors = orgarr.map((counselor, index) => {
      const { name, email, referenceCode, imageUrl, id } = counselor;
      let userimg=imageUrl==="no-url"?"https://nflyte-1.s3.amazonaws.com/ef12f40b-f758-4e9c-ab7c-acebc7e240c6.jpg":imageUrl;
      return (
        <StyledTableRow key={index}>
          <StyledTableCell component='th' scope='row'>
            {index + 1}
          </StyledTableCell>
          <StyledTableCell component="th" scope="row">
          {userimg !== '' ? (
                              <a href={userimg} target='_blank'>
                                <img
                                  src={userimg}
                                  width="50" height="50"
                                  className="rounded"
                                />
                              </a>
                            ) : (
                              <span>-</span>
                            )}
          </StyledTableCell>
          {/* <StyledTableCell>{imageUrl !== 'no-url' ? (
                      <a href={imageUrl} target='_blank'>
                        <img
                          src={imageUrl}
                          width="70" height="70"
                        />
                      </a>
                    ) :
                    (
                      <a href={userimg} target='_blank'>
                      <img
                        src={userimg}
                        width="50" height="50"
                        className="rounded"
                      />
                    </a>
                     
                    ) 

                    // (
                    //   <NoImageIcon style={{ color: 'grey' }} />
                    // )
           }</StyledTableCell> */}
          <StyledTableCell>{name}</StyledTableCell>
          <StyledTableCell>{email}</StyledTableCell>
          <StyledTableCell>
            <IconButton
              onClick={async () => {
                await dispatch(getCounselorById(id));
                if(authlocal==="3")
                {
                  history.push(EDIT_PARENTS_COUNSELOR_PROFILE(id));
                }
                else
                {
                  history.push(EDIT_COUNSELOR_PROFILE(id));
                }
              }}
            >
              <EditIcon />
            </IconButton>
            {user_id && user_id==='85610051-0f41-417b-a5d5-b0466846c5a9'?
              <IconButton
                onClick={async () => {
                        
                  getEditUser(id)
                }}
              >
                <MdOutlineDelete />
              </IconButton>:""
            }
          </StyledTableCell>
        </StyledTableRow>
      );
    });
  } else if (orgarr.length === 0) {
    renderedCounselors = (
      <StyledTableRow>
        <StyledTableCell component='th' scope='row'>
          <Typography variant='subtitle2' className={classes.label}>
            No admin found.
            <Link to={ADD_COUNSELOR} className={classes.link}>
              Add Admin?
            </Link>
          </Typography>
        </StyledTableCell>
      </StyledTableRow>
    );
  } else {
    renderedCounselors = (
      <StyledTableRow>
        <StyledTableCell component='th' scope='row'></StyledTableCell>
      </StyledTableRow>
    );
  }
  
  
 
  useEffect(() => {
    async function fetchMyAPI() {
      setIsLoading(true)

    if(user_id)
    {
      const orgDatanxt = await getOrganisationList();
        if(orgDatanxt)
        {
          setorgarrNxt(orgDatanxt)

        }
      const orgData = await getSupportAdminList();
      if(orgData)
      {
        setorgarr(orgData)
      }
      setIsLoading(false)

    }
  }
  fetchMyAPI()
    return () => {};
  }, []);
  
  async function getApproveOrganisation(id:any)
  {
    //console.log("chk id-->"+id)

    const orgData_nxt = await approvedOrganisation(id);
      if(orgData_nxt)
      {
        const orgData = await getOrganisationList();
          if(orgData)
          {
            setorgarrNxt(orgData)
        
            
          }
      }
      
  }
  let renderedStudents: { label: string; value: string; imageUrl: string }[] =[];

  let autoorgdata: any[];
  if(orgarr_nxt)
  {
              if (orgarr_nxt.length > 0) {
                renderedStudents = orgarr_nxt
                  .map((org:any) => {
                    return {
                      label: org.name,
                      value: org.id,
                      imageUrl: "",
                    };
                  });
              } else {
                renderedStudents = [];
              }
  }
  console.log("----->"+renderedStudents)
  const [cstatus,setCstatus]=useState("");
  async function getInactiveProgramManager(e:any,cnt:any,id:any) {
    setCstatus(e.target.value)

    let c1=window.confirm("Do you want to change status !");
    if(c1)
    {
      //alert(e.target.value)
      let orstatus=e.target.value;
      const orgData = await changeProgramStatus(id,orstatus);
      if(orgData)
      {
        const orgData = await getProgramManagerList();
        if(orgData)
        {
          setorgarr(orgData)
        }
        //window.location.reload()
      }
    }
  }
  const [orgSelectnxt,setorgSelect]=useState("");
  async function getSearchOrganisation(e:any,nm:string)
  {
    //alert(nm)
        setorgSelect(nm)
        setIsLoading(true)
        setOrgId(nm)

      let orgnm=nm;
        const orgData = await getSearchOrganisationBySuperAdminorList(orgnm);
        if(orgData)
        {
          setorgarr(orgData)
          
          setIsLoading(false)

        }
  
      
  }
  function addSpportAdmin(orgid:any)
  {
    if(orgid==="")
    {
      alert("Please Select Organization First !")
    }
    else
    {
      history.push(ADD_SUPPORT_ADMIN(orgid));
    }
  }
  console.log("org data-->"+JSON.stringify(orgSelectnxt))
      // if(isLoading===false)
      // {
        return (
          <div>
            <Typography variant='h6'>Admins</Typography>
            <br />
            <Grid
                container
                direction='row'
                justifyContent='space-between'
                alignItems='center'
              >
                <Grid item md={6} xs={12}>
                <Button
                      variant='contained'
                      color='primary'
                      onClick={() => {
                        //history.push(ADD_SUPPORT_ADMIN(orgid));
                        addSpportAdmin(orgid)
                      }}
                      startIcon={<AddIcon />}
                      disabled={isLoading?true:false}
                    >
                      Add Admin
                    </Button>
                </Grid>
                
                <Grid item md={4} xs={8} style={{padding:'5px'}}>
                    <label><b>Select Organization</b></label>
                    <Autocomplete
                        freeSolo
                        autoComplete
                        id=''
                        disableClearable={false}
                        options={renderedStudents}
                        onChange={(e, option:any) => {
                          if (typeof option !== 'string') {
                            getSearchOrganisation(e,option.value)
                          }

                        }}
                        //value={orgSelect}
                        renderInput={(params) => (
                          <TextField {...params} label='' variant='outlined' size='small' placeholder='Select Organization' value={orgSelectnxt}/>
                        )}
                        getOptionLabel={(option) =>
                          typeof option !== 'string' && option.label ? option.label : ''
                        }
                      />
                    </Grid>
                    <Grid item md={2} xs={4}  style={{padding:'5px'}}>
                      <Button
                        variant='contained'
                        color='primary'
                        onClick={async() => { 
                          window.location.reload()
                        // setOrgDate("")
                          // const orgData = await getProgramManagerList();
                          // if(orgData)
                          // {
                          //   setorgarr(orgData)
                          //   setorgSelect("")
                          //   const orgDatan = await getOrganisationList();
                          //   if(orgDatan)
                          //   {
                          //     setorgarrNxt(orgDatan)
                          
                          //   }

                          // }
                        }}
                        style={{height:'50px',marginTop:'20px', textTransform:'capitalize'}}
                        startIcon={<FiRefreshCw />}
                        disabled={isLoading?true:false}

                      >
                        Refresh List 
                      </Button>
                    </Grid>
              </Grid>
            
            <br />
            <br />
            <TableContainer component={Paper}>
              <Table aria-label='customized table'>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>S.No.</StyledTableCell>
                    <StyledTableCell>Image</StyledTableCell>
                    <StyledTableCell>Name</StyledTableCell>
                    <StyledTableCell>Email</StyledTableCell>
                    <StyledTableCell>Actions</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{renderedCounselors}</TableBody>
              </Table>
            </TableContainer>
          </div>
        );
      // }
      // else
      // {
      //     return <div style={{position:'absolute',top:'50%',left:'60%',transform:'translate(-50%, -50%)'}}>
      //         <div className="spinner-border text-danger" role="status">
      //             <span className="sr-only">Loading...</span>
      //         </div> &nbsp;&nbsp;&nbsp;<span style={{fontSize:'24px',marginTop:'-10px'}}>Loading...</span>
      //     </div>;
      // }
};

export default SuperAdminCounselorProfile;

import axios from 'axios';

const PRODUCTION = true;

export const HOST = PRODUCTION
  ? 'https://nflytebackend.com' //pointing to azure app service now
  : 'http://localhost:5000';

export const BASE_URL = `${HOST}/api/v1`;

const API = axios.create({ baseURL: BASE_URL });

export default API;

import React, { useEffect, useState } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
  IconButton,
  Grid,
  TextField,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import formStyles from '../../../assets/jss/components/formStyles';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../app/rootReducer';
import { useHistory } from 'react-router-dom';
import {
  getStudentById,
  getStudentsForParent,
  getStudentsForProgramRefresh,
  getStudentsForProgramSuperadminRefresh,
  getUnConfirmUsers,
  setStudents,
} from '../../../slices/studentSlice';
import { StyledTableRow } from '../../../components/StyledTableRow';
import { StyledTableCell } from '../../../components/StyledTableCell';
import Button from '@mui/material/Button';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import EditIcon from '@mui/icons-material/Edit';
import {
  ADD_MOBILE_USER,
  ADD_STUDENT,
  ADD_SUPPORT_ADMIN,
  ADD_UNCONFIRMED_STUDENT,
  editStudent,
  viewStudentGroceryList,
  viewStudentTask,
} from '../../../constants/routes';
import {
  getTasksForStudent,
  getStudentTasksForDate,
  setSelectedDate,
  setRecipeId,
  setNotiTime,
} from '../../../slices/taskSlice';
import AddIcon from '@mui/icons-material/Add';
import LocalGroceryStoreOutlinedIcon from '@mui/icons-material/LocalGroceryStoreOutlined';
import { USER_ROLE } from '../../../constants/userRoles';
import { getChildForParentRefresh } from '../../../slices/parentSlice';
import { getOrganisationList, getSearchOrganisationBySuperAdminorList, getSearchSuperAdminListByUser } from '../../../api/programManagerRequests';
import { FiRefreshCcw } from 'react-icons/fi';
import { Autocomplete } from '@mui/material';
import { getSearchOrganisationBySuperAdminUserist } from '../../../api/studentRequests';
import { setLoading } from '../../../slices/chatRoomSlice';
import { setCounselors } from '../../../slices/counselorSlice';
import { getOrganisationById } from '../../../slices/organisationSlice';
import * as REQUESTS from '../../../api/studentRequests';
import { setSuccessMsg } from '../../../slices/alertSlice';


interface IUnConfirmUserListProps {}

const useStyles = makeStyles(formStyles);

const UnConfirmUserList: React.FC<IUnConfirmUserListProps> = ({}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const { students, selectedDate, role, counselor } = useSelector(
    (state: RootState) => {
      return {
        students: state.student.students,
        selectedDate: state.task.selectedDate,
        role: state.auth.role,
        counselor: state.counselor.counselor,
      };
    },
    shallowEqual
  );

  useEffect(() => {
    dispatch(setRecipeId(""));
    dispatch(setNotiTime({data:[]}));
  }, []);
console.warn("chk login");

const authlocal = localStorage.getItem('userRole');
const user_id = localStorage.getItem('user_id');

useEffect(() => {
  if(user_id && authlocal=="0")
  {
    dispatch(getUnConfirmUsers(user_id));
  }
  
  return () => {};
}, []);

  
localStorage.setItem('urlRoute', "parent_users");
 

  
  console.log("student details - > "+students);

  localStorage.setItem('urlRoute', "parent_admins");
  const [orgarr,setorgarr]=useState(students);
  const [orgarr_nxt,setorgarrNxt]=useState([]);

  
  const [cstatus,setCstatus]=useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [orgSelect,setorgSelect]=useState("");
  const [orgid,setOrgId]=useState("");


  
  console.log("org data-->"+JSON.stringify(orgarr))

  function addSpportAdmin(orgid:any)
  {
    if(orgid==="")
    {
      alert("Please Select Organization First !")
    }
    else
    {
      history.push(ADD_MOBILE_USER(orgid));
    }
  }

  async function getAddUser()
  {
        history.push(ADD_UNCONFIRMED_STUDENT);
   
  }

  async function getConfirmUnconfirmUsers(e:any)
  {
    
        const udata = await REQUESTS.getConfirmUnconfirmUsers();
        //dispatch(getSearchOrganisationBySuperAdminUserist(orgnm));
        dispatch(setSuccessMsg("All Users Confirmed Successfully !"));
        if(user_id && authlocal=="0")
        {
          dispatch(getUnConfirmUsers(user_id));
        }
      
  }
  async function getConfirmSingleUsers(e:any,id:string)
  {
    
        const udata = await REQUESTS.getConfirmSingleUsers(id);
        //dispatch(getSearchOrganisationBySuperAdminUserist(orgnm));
        dispatch(setSuccessMsg("User Confirmed Successfully !"));
        if(user_id && authlocal=="0")
        {
          dispatch(getUnConfirmUsers(user_id));
        }
       
      
  }
  let renderedStudents;


  if (students.length > 0) 
  {
    renderedStudents =
      role === USER_ROLE.COUNSELOR
        ? students
            .filter((student) =>
              student.studentGroups
                .map((group) => group.name)
                .includes(counselor!.name)
            )
            .map((student, index) => {
              const { name, email, id, diagnoses, studentGroups } = student;
              return (
                <StyledTableRow key={index} style={{width:'100%'}}>
                  {/* <StyledTableCell component='th' scope='row'>
                    {index + 1}
                  </StyledTableCell> */}
                  <StyledTableCell>{name}</StyledTableCell>
                  <StyledTableCell>{email}</StyledTableCell>
                  {/* <StyledTableCell>{email}</StyledTableCell> */}
                  {/* <StyledTableCell>
                    {diagnoses &&
                      diagnoses
                        .map((diagnosis) => {
                          return diagnosis.name;
                        })
                        .toString()}
                  </StyledTableCell> */}
                 
                  
                  <StyledTableCell>
                    <Button
                      style={{fontSize:"9px"}}

                      variant='outlined'
                      startIcon={<EditIcon />}
                      onClick={async (e) => {
                        await getConfirmSingleUsers(e,id)
                      }}
                    >
                     Send Mail
                    </Button>
                  </StyledTableCell>
                  
                </StyledTableRow>
              );
            })
        : students.map((student, index) => {
            const { name, email, id, diagnoses, studentGroups } = student;
            return (
              <StyledTableRow key={index} style={{width:'100%'}}>
                {/* <StyledTableCell component='th' scope='row'>
                  {index + 1}
                </StyledTableCell> */}
                <StyledTableCell style={{textOverflow:'ellipsis'}}>{name}</StyledTableCell>
                <StyledTableCell>{email}</StyledTableCell>

                {/* <StyledTableCell>{email}</StyledTableCell> */}
                {/* <StyledTableCell>
                  {diagnoses &&
                    diagnoses
                      .map((diagnosis) => {
                        return diagnosis.name;
                      })
                      .toString()}
                </StyledTableCell> */}
               
                
                <StyledTableCell>
                  <Button
                      style={{fontSize:"9px"}}
                      variant='outlined'
                    //startIcon={<EditIcon />}
                    onClick={async (e) => {
                      await getConfirmSingleUsers(e,id)
                    }}
                  >
                     Send Mail
                  </Button>
                </StyledTableCell>
                
              </StyledTableRow>
            );
          });
  } else if (students.length === 0) {
    renderedStudents = (
      <StyledTableRow style={{width:'100%'}}>
        <StyledTableCell component='th' scope='row'>
          <Typography variant='subtitle2' className={classes.label}>
            No user found.
            {/* <Link to={ADD_STUDENT} onClick={closeOnClick} className={classes.link}>
              Add User?
            </Link> */}
          </Typography>
        </StyledTableCell>
      </StyledTableRow>
    );
  } else {
    renderedStudents = (
      <StyledTableRow style={{width:'100%'}}>
        <StyledTableCell component='th' scope='row'></StyledTableCell>
      </StyledTableRow>
    );
  }

  if(isLoading===false)
    {

        return (
            
            <div style={{maxWidth:'100%'}}>
                            <Typography variant='h6'>
                                {/* Students */}
                                Users
                            </Typography>
                            <br />
                            <Grid
                                container
                                direction='row'
                                justifyContent='space-between'
                                alignItems='center'
                                >
                                <Grid item md={2} xs={12}>
                                        <Button
                                            title='Add User'
                                            className={classes.secondaryButton}
                                              style={{height:'40px',fontSize:'11px',backgroundColor:'#0092BC',color:'white'}}
                                              variant='outlined'
                                              startIcon={<AddIcon />}
                                              onClick={ (e) => {
                                                getAddUser()
                                              }}
                                              color='primary'

                                            >
                                             Add User
                                          </Button>
                                            
                                </Grid>
                                <Grid item md={4} xs={12}>

                                </Grid>
                                <Grid item md={4} xs={12}>
                                        <Button
                                            title='Add Unconfirm User'
                                            className={classes.secondaryButton}
                                              style={{height:'40px',fontSize:'11px',backgroundColor:'#0092BC',color:'white'}}
                                              variant='outlined'
                                              //startIcon={<AddIcon />}
                                              onClick={ (e) => {
                                                getConfirmUnconfirmUsers(e)
                                              }}
                                              color='primary'

                                            >
                                             Send Mail to All unconfirmed users
                                          </Button>
                                            
                                </Grid> 
                                {/* <Grid item md={2} xs={12}>
                                        <Button
                                            title='Add Unconfirm User'
                                            className={classes.secondaryButton}
                                              style={{height:'40px',fontSize:'11px',backgroundColor:'#0092BC',color:'white'}}
                                              variant='outlined'
                                              startIcon={<AddIcon />}
                                              onClick={ (e) => {
                                                getConfirmUnconfirmUsers(e)
                                              }}
                                              color='primary'

                                            >
                                             Confirm the UnConfir Users
                                          </Button>
                                            
                                </Grid> */}
                               
                                
                            </Grid>

                            <br />
                            <br />
                    <Grid
                        container
                        direction='row'
                        justifyContent='space-between'
                        alignItems='center'

                      >
                      <Grid item sm={12} xs={12} className='' style={{padding:'5px',overflow:'scroll',overflowX:'scroll',overflowY:'scroll',width:'800px',height:'400px'}}>

                            <TableContainer component={Paper} >
                                <Table aria-label='customized table'>
                                <TableHead>
                                    <TableRow style={{width:'100%'}}>
                                    {/* <StyledTableCell>S.No.</StyledTableCell> */}
                                    <StyledTableCell>Name</StyledTableCell>
                                    <StyledTableCell>Email</StyledTableCell>
                                   
                                    <StyledTableCell>Action</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>{renderedStudents}</TableBody>
                                </Table>
                            </TableContainer>
                      </Grid>
                </Grid>
            </div>
        );
    }
    else
    {
        return <div style={{position:'absolute',top:'50%',left:'60%',transform:'translate(-50%, -50%)'}}>
            <div className="spinner-border text-danger" role="status">
                <span className="sr-only">Loading...</span>
            </div> &nbsp;&nbsp;&nbsp;<span style={{fontSize:'24px',marginTop:'-10px'}}>Loading...</span>
        </div>;
    }
};
export default UnConfirmUserList;

// import { GroceryList } from './../constants/models/GroceryList';
import { User } from '../constants/models/User';

import {
  STUDENT_ROUTE,
  PROGRAM_ROUTE,
  COUNSELOR_ROUTE,
  PARENT_ROUTE,
  ALERT_ROUTE,
  PROGRAM_MANAGER_ROUTE
} from './../constants/routes'; 
import API from './api';

export async function getStudentBydId(id: string) {
  try {
    const res = await API.get<{ data: User; success: boolean }>(
      `${STUDENT_ROUTE}/${id}`
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function ViewTaskLogs(id: string,program_id:string,brosname:string,brosdevicename:string) {
  try {
    const res = await API.post<{ data: User; success: boolean }>(
      `${STUDENT_ROUTE}/viewTasklog`,
      {
        studentId:id,
        programId:program_id,
        brosname,
        brosdevicename
      }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function getStudentsForCounselor(programId: string) {
  try {
    const res = await API.post<{ data: User[]; success: boolean }>(
      `${PROGRAM_ROUTE}/${programId}${STUDENT_ROUTE}/getStudentsForCounselor`,
      {
        programId:programId
      }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function getStudentsForProgram(programId: string) {
  try {
    const res = await API.get<{ data: User[]; success: boolean }>(
      `${PROGRAM_ROUTE}/${programId}${STUDENT_ROUTE}`
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function getUnConfirmUsers(programId: string) {
  try {
    const res = await API.post<{ data: User[]; success: boolean }>(
      `${PROGRAM_ROUTE}/${programId}${STUDENT_ROUTE}/getUnConfirmUsers`,
      {
        programId:programId
      }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}


export async function getStudentsForParent(parentId: string) {
  try {
    const res = await API.post<{ data: User[]; success: boolean }>(
      `${PROGRAM_ROUTE}/${parentId}${STUDENT_ROUTE}/getStudentsForParentPanel`
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function getStudentsByOrganisation(parentId: string) {
  try {
    const res = await API.post<{ data: User[]; success: boolean }>(
      `${PROGRAM_ROUTE}/${parentId}${STUDENT_ROUTE}/getStudentsByOrganisation`
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}


export async function createStudentAsProgramManager(
  name: string,
  email: string,
  address: string,
  street: string,
  city: string,
  state: string,
  country: string,
  postalCode: number,
  about: string,
  phoneno: string,
  countryCode: string,
  programId: string,
  diagnoses: string[],
  counselors: string[]
) {
  try {
    const res = await API.post<{ data: User; success: boolean }>(
      `${STUDENT_ROUTE}/register`,
      {
        name,
        email,
        address,
        street,
        city,
        state,
        country,
        postalCode,
        about,
        phoneno,
        countryCode,
        programId,
        diagnosisIds: diagnoses,
        counselorIds: counselors,
      }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}
export async function createUnConfirmUser(
  name: string,
  email: string,
  address: string,
  street: string,
  city: string,
  state: string,
  country: string,
  postalCode: number,
  about: string,
  phoneno: string,
  countryCode: string,
  programId: string,
  diagnoses: string[],
  counselors: string[],
  brosname:string,
  brosdevicename: string
) {
  try {
    const res = await API.post<{ data: User; success: boolean }>(
      `${STUDENT_ROUTE}/register_pre`,
      {
        name,
        email,
        address,
        street,
        city,
        state,
        country,
        postalCode,
        about,
        phoneno,
        countryCode,
        programId,
        diagnosisIds: diagnoses,
        counselorIds: counselors,
        brosname,
        brosdevicename
      }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}
export async function updateStudent(
  id: string,
  name: string,
  email: string,
  address: string,
  street: string,
  city: string,
  state: string,
  country: string,
  postalCode: number,
  about: string,
  phoneno: string,
  countryCode: string,
  imageUrl: string | undefined,
  diagnoses: string[],
  counselors: string[],
  program_de_id:string,
  brosname: string,
  brosdevicename:string,
) {
  try {
    const res = await API.post<{ data: User; success: boolean }>(
      `${STUDENT_ROUTE}/${id}/updateStudent`,
      {
        name,
        email,
        address,
        street,
        city,
        state,
        country,
        postalCode,
        about,
        phoneno,
        countryCode,
        imageUrl: imageUrl ?? 'no-url',
        diagnosisIds: diagnoses,
        counselorIds: counselors,
        programId: program_de_id,
        brosname,
        brosdevicename
      }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export const getStudentGroceryList = async (id: string) => {
  try {
    const res = await API.get<{ data: any; success: boolean }>(
      `${STUDENT_ROUTE}/${id}/grocery-list`
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
};
export const getConfirmUnconfirmUsers = async () => {
  try {
    const res = await API.post<{ data: any; success: boolean }>(
      `${STUDENT_ROUTE}/getConfirmUsers`
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
};
export const getConfirmSingleUsers = async (id:string) => {
  try {
    const res = await API.post<{ data: any; success: boolean }>(
      `${STUDENT_ROUTE}/getConfirmSingleUser`,
      {
        id,
      }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
};
export const createStudentGroceryList = async (
  programId: string,
  studentId: string
) => {
  try {
    const res = await API.post<{ data: any; success: boolean }>(
      `${STUDENT_ROUTE}/${studentId}/grocery-list`,
      {
        programId,
        studentId,
      }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
};
export async function createMessageNotification(
  user: string,
  createdMessage: any,
  grpUser2:any[],
  fromuser:string
) {
  try {
    const res = await API.post<{ data: User; success: boolean }>(
      `${STUDENT_ROUTE}/sendMessageNotification`,
      {
        user,
        createdMessage,
        grpUser2,
        fromuser
      }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}


export async function getStudentsForEmail() {
  try {
    const res = await API.post<{ data: User[]; success: boolean }>(
      `${ALERT_ROUTE}/getStudentsForList`
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function getSearchOrganisationBySuperAdminUserist( 
  id: string,
  ) {
  try {
    const res = await API.post<{ data: User[]; success: boolean }>(
      `${PROGRAM_MANAGER_ROUTE}/getSearchOrganisationBySuperAdminUserList`,
      {
        id
      }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

//create user by super admin
export async function createStudentBySuperAdmin(
  name: string,
  email: string,
  address: string,
  street: string,
  city: string,
  state: string,
  country: string,
  postalCode: number,
  about: string,
  phoneno: string,
  countryCode: string,
  programId: string,
  diagnoses: string[],
  counselors: string[],
  orgid:string,
  brosname: string,
  brosdevicename: string
) {
  try {
    // let orgid;
    // const userData = localStorage.getItem('userData');
    // if(userData)
    // {

    //  const userDt = JSON.parse(userData);
    //  orgid=userDt.id;
    // }

    const res = await API.post<{ data: User; success: boolean }>(
      `${STUDENT_ROUTE}/createUserBySuperAdmin`,
      {
        name,
        email,
        address,
        street,
        city,
        state,
        country,
        postalCode,
        about,
        phoneno,
        countryCode,
        programId,
        diagnosisIds: diagnoses,
        counselorIds: counselors,
        organisationId:orgid,
        brosname,
        brosdevicename
      }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function getSuperadminLogoutLog(program_id:string, brosname:string,brosdevicename:string) {
  try {
    const res = await API.post<{ data: User; success: boolean }>(
      `${STUDENT_ROUTE}/superadminLogoutEntry`,
      {
        program_id:program_id,
        brosname: brosname,
        brosdevicename,
      }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function getSigninLogById(student_id: string,brosname:string,brosdevicename: string) {
  try {
    const res = await API.post<{ data: User; success: boolean }>(
      `${STUDENT_ROUTE}/getSigninLogById`,
      {
        student_id,
        brosname,   
        brosdevicename,
      }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}


import React, { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  FormControl,
  InputLabel,
  InputAdornment,
  TextField,
  MenuItem,
  Grid,
  Avatar,
  Select,
  OutlinedInput,
  IconButton,
  Typography,
  makeStyles,
  Backdrop
} from '@material-ui/core';
//import Backdrop from '@material-ui/core/Backdrop';
import { Link, useParams } from 'react-router-dom';
import formStyles from '../../assets/jss/components/formStyles';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../app/rootReducer';
import { useHistory } from 'react-router-dom';
import {
    getRecipeById,
    getRecipesForProgram,
    deleteRecipe,
    getRecipesBySearch,
    recipeComplete,
    getRecipesForProgramRefresh,
    ViewRecipeLogs
} from '../../slices/recipeSlice';
import { getrecipeNutritionById } from '../../slices/recipeNutritionSlice';
import {
  getStepListForStudent,
    deleterecipeStep,
    getrecipeStepById
} from '../../slices/recipeStepsSlice';
import { StyledTableRow } from '../../components/StyledTableRow';
import { StyledTableCell } from '../../components/StyledTableCell';
import Button from '@mui/material/Button';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DeleteIcon from '@mui/icons-material/Delete';

import EditIcon from '@mui/icons-material/Edit';
import {
  ADD_RECIPE,
  ADD_RECIPE_VIEW,
  editRecipe,
  viewRecipe,
  viewIngridians,
  viewSteps,
  viewNutrintion,
  viewRecipeDetails,
  favorite_recipe_by_user
} from '../../constants/routes'; 
// import {
//   getTasksForStudent,
//   getStudentTasksForDate,
//   setSelectedDate,
// } from '../../../slices/recipeSlice';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import { setTags } from '../../slices/tagSlice';
import * as REQUESTS from '../../api/recipeRequests';
import LocalGroceryStoreOutlinedIcon from '@mui/icons-material/LocalGroceryStoreOutlined';
import { PROGRAM_MANAGER, USER_ROLE } from '../../constants/userRoles';
import NoImageIcon from '@mui/icons-material/HideImage';
import { fontSize } from '@mui/system';
import headerStyles from '../../assets/jss/components/headerStyles';
import { CropSquareSharp } from '@material-ui/icons';
//import gtag from '../../assets/jss/components/google_tags';

//import { makeStyles } from '@material-ui/core/styles/makeStyles';

//import { deleteRecipe } from '../../api/recipeRequests';

interface IListRecipeAllProps {}

const useStyles = makeStyles(formStyles);//makeStyles(formStyles);

export const ListRecipeAll: React.FC<IListRecipeAllProps> = ({}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const useStylesNext = makeStyles(headerStyles);
  const classesNext = useStylesNext();
  const [rpage, setPage] = useState(1);
  const [rrecipeTitle, setrrecipeTitle]= useState("");
  const [rtypeOfRecipe, setrtypeOfRecipe] = useState("");
  const [recipeTags, setFieldValue] = useState("");

  const sizes = 10;
 // const [totalRecipe,setTotalRecipes]=useState(0);
  let totalRecipe=0;
  const { recipes, role, recipesTotal,recipesPage,rtags,recipeSize,graphQlUser } = useSelector(
    (state: RootState) => {
      return {
        recipes: state.recipe.recipes,
        role: state.auth.role,
        graphQlUser: state.auth.graphQlUser,
        recipesTotal:state.recipe.total,
        recipesPage:state.recipe.page,
        recipeSize:state.recipe.size,
        rtags: state.tag.tags,
      };
    },
    shallowEqual
  );
  const authlocal_user_id = localStorage.getItem('user_id');


  console.warn("gquser---->"+JSON.stringify(authlocal_user_id));
  const { studentId } = useParams<{ studentId: string | undefined }>();

let loginUserId="";
if(authlocal_user_id)
{
  loginUserId=authlocal_user_id;
}

let loginUserRole=role;
let studid="";
// if(studentId)
// {
//   studid=studentId;
// }
const authlocal = localStorage.getItem('userRole');
  const user_id = localStorage.getItem('user_id');

  useEffect(() => {
    //classesNext.
    dispatch(recipeComplete())
    if(user_id)
    {
      dispatch(getRecipesForProgramRefresh(rrecipeTitle,rtypeOfRecipe,recipeTags,rpage,sizes,studid,user_id));
    }
    else
    {
        dispatch(getRecipesForProgram(rrecipeTitle,rtypeOfRecipe,recipeTags,rpage,sizes,studid));
        localStorage.setItem('urlRoute', "recipe_list_with_user");
    }
    
    console.log();
    async function fetchMyAPI() {
      if(user_id)
      {
        const tags = await REQUESTS.getRecipeTags(user_id);
          dispatch(setTags({ data: tags }));
          // gtag("event", "List Recipe", {
          //   "event_category": "List Recipe",
          //   "event_label": "Super Admin",
          //   "event_action": "List Recipe"
          // });
      }
    }
    fetchMyAPI()
    return () => {};
  }, [ rpage,rtags.length ]);
 
// useEffect(() => {
//   dispatch(getRecipesForStudent());
//   return () => {};
// }, [students.length]);
  
//   useEffect(() => {
//     dispatch(setSelectedDate(new Date()));
//   }, []);

//console.warn("recipe cnt data----------------->"+JSON.stringify(recipes));
function getConfirmBox(id:string,rrecipeTitle:string,rtypeOfRecipe:string,rpage:number,sizes:number)
{
  let chk=window.confirm("Do you want to delete this recipe");  
  if(chk)
  {
    dispatch(deleteRecipe(id,rrecipeTitle,rtypeOfRecipe,rpage,sizes,history));
  }
}
let renderedRecipeTags= rtags.map((tag) => {
  return {
    value: tag.id,
    label: tag.type,
  };
});
  let renderedStudents;
  if (recipes.length > 0) {

    console.warn("page count-------->"+JSON.stringify(recipesTotal+"/"+recipesPage+"/"+recipeSize))
    totalRecipe=recipesTotal;
      
    renderedStudents =
      role === USER_ROLE.COUNSELOR || role === USER_ROLE.PARENT
        ? recipes.map((recipes:any, index:any) => {
              const { recipeTitle, recipeDescription, id, recipeUrl, tags, userId} = recipes;
              
              return (
                <StyledTableRow key={index}>
                  <StyledTableCell component='th' scope='row'>
                    {index + 1}
                  </StyledTableCell>
                  <StyledTableCell>
                          {recipeUrl !== '' ? (
                              <a href={recipeUrl} target='_blank'>
                                <img
                                  src={recipeUrl}
                                  width="50" height="50"
                                  className="rounded"
                                />
                              </a>
                            ) : (
                              <NoImageIcon style={{ color: 'grey' }} />
                            )}
                    </StyledTableCell>
                  <StyledTableCell>{recipeTitle}</StyledTableCell>
                  <StyledTableCell>{recipeDescription}</StyledTableCell>
                  
                  
                  <StyledTableCell>
                  {userId===loginUserId?
                    <Button
                    title='Edit Recipe'
                    style={{height:'40px',fontSize:'11px'}}
                      className={classesNext.link}
                      variant='outlined'
                      startIcon={<EditIcon />}
                      onClick={async () => {
                        await dispatch(getRecipeById(id));
                        localStorage.setItem('urlRoute', "edit_recipe");
                        localStorage.setItem('urlId', id);
                        history.push(editRecipe(id));
                      }}
                    >
                      Edit Recipe
                    </Button>:""
                  }
                      
                  </StyledTableCell>
                  {/* <StyledTableCell>
                  {userId===loginUserId.id?
                    <Button
                      title='View Ingredients'

                      style={{height:'40px',fontSize:'11px'}}
                      variant='outlined'
                      startIcon={<LocalGroceryStoreOutlinedIcon />}
                      onClick={(e) => {
                        // dispatch(getTasksForStudent(id));

                        // dispatch(getStudentTasksForDate(id, selectedDate));
                        dispatch(getRecipeById(id));
                        history.push(viewIngridians(id));
                      }}
                    >
                      View Ingredients
                    </Button>:""
                  }
                  </StyledTableCell>
                  <StyledTableCell>
                  {userId===loginUserId.id?
                    <Button
                    title='View Steps'
                    style={{height:'40px',fontSize:'11px'}}
                    variant='outlined'
                      startIcon={<i className="fa-solid fa-forward-step"></i>}
                      onClick={async () => {
                        await dispatch(getStepListForStudent(id));
                        history.push(viewSteps(id));
                      }}
                    >
                      View Steps
                    </Button>:""
                  }
                  </StyledTableCell> */}
                  {/* <StyledTableCell>
                  {userId===loginUserId.id?

                    <Button
                    title='View Nutritiion'
                    style={{height:'40px',fontSize:'11px'}}
                    variant='outlined'
                      startIcon={<i className="fa fa-cutlery"></i>}
                      onClick={async () => {
                        await dispatch(getrecipeNutritionById(id));
                        history.push(viewNutrintion(id));
                      }}
                    >
                      View Nutritiion
                    </Button>:""
                  }
                  </StyledTableCell> */}
                  <StyledTableCell>
                  <IconButton
                  title='View Recipe Details'
                    onClick={async () => {
                      await dispatch(getRecipeById(id));
                      dispatch(ViewRecipeLogs(id));
                      history.push(viewRecipeDetails(id));
                    }}
                  >
                    <RemoveRedEyeIcon style={{ color: 'red' }} />
                    </IconButton>
                    {userId===loginUserId?

                      <IconButton
                        title='Delete Recipe'

                        onClick={async (e) => {
                          await getConfirmBox(id,rrecipeTitle,rtypeOfRecipe,rpage,sizes);
                        }}
                      >
                    <DeleteIcon style={{ color: 'red' }} />
                    </IconButton>:""
                    }
                    {/* <Button
                    style={{height:'40px',fontSize:'11px'}}
                    variant='outlined'
                    startIcon={<DeleteIcon />}
                      onClick={async () => {
                        await dispatch(deleteRecipe(id,rrecipeTitle,rtypeOfRecipe,rpage,sizes));
                      }}
                    >
                      Delete Cook Book
                    </Button> */}
                  </StyledTableCell>
                </StyledTableRow>
              );
            })
        : recipes.map((recipe, index) => {
            const { id, recipeTitle, recipeDescription, recipeUrl, tags, userId} = recipe;

            return (
              <StyledTableRow key={index}>
                <StyledTableCell component='th' scope='row'>
                  {index + 1}
                </StyledTableCell>
                <StyledTableCell>
                          {recipeUrl !== '' ? (
                              <a href={recipeUrl} target='_blank'>
                                <img
                                  src={recipeUrl}
                                  width="50" height="50"
                                  className="rounded"
                                />
                              </a>
                            ) : (
                              <NoImageIcon style={{ color: 'grey' }} />
                            )}
                    </StyledTableCell>
                <StyledTableCell>{recipeTitle}</StyledTableCell>
                <StyledTableCell>{recipeDescription}</StyledTableCell>
               
                <StyledTableCell>
                  <Button
                  title='Edit Recipe'
                    style={{height:'40px',fontSize:'11px'}}
                    variant='outlined'
                    startIcon={<EditIcon />}
                    onClick={async () => {
                      await dispatch(getRecipeById(id));
                      localStorage.setItem('urlRoute', "edit_recipe");
                        localStorage.setItem('urlId', id);
                      history.push(editRecipe(id));
                    }}
                  >
                    Edit Recipe 
                  </Button>
                </StyledTableCell>
                {/* <StyledTableCell>

                  <Button
                  title='View Ingredients'
                    style={{height:'40px',fontSize:'11px'}}
                    variant='outlined'
                    startIcon={<LocalGroceryStoreOutlinedIcon />}
                    onClick={(e) => {
                      // dispatch(getTasksForStudent(id));

                      // dispatch(getStudentTasksForDate(id, selectedDate));
                      dispatch(getRecipeById(id));
                      history.push(viewIngridians(id));
                    }}
                  >
                    View Ingredients
                  </Button>
                </StyledTableCell>
                <StyledTableCell>
                    <Button
                    title='View Steps'
                    style={{height:'40px',fontSize:'11px'}}
                    variant='outlined'
                    startIcon={<i className="fa-solid fa-forward-step"></i>}
                      onClick={async() => {
                        await dispatch(getStepListForStudent(id));
                        history.push(viewSteps(id));
                      }}
                    >
                      View Steps
                    </Button>
                  </StyledTableCell> */}
                  {/* <StyledTableCell>

                    <Button
                    title='View Nutritions'
                    style={{height:'40px',fontSize:'11px'}}
                    variant='outlined'
                    startIcon={<i className="fa fa-cutlery"></i>}
                      onClick={async () => {
                        await dispatch(getrecipeNutritionById(id));
                        history.push(viewNutrintion(id));
                      }}
                    >
                      View Nutritions
                    </Button>
                  </StyledTableCell> */}
                  <StyledTableCell>
                  <IconButton
                  title='View Recipe Details'
                    onClick={async () => {
                      await dispatch(getRecipeById(id));
                      dispatch(ViewRecipeLogs(id));
                      history.push(viewRecipeDetails(id));
                    }}
                  >
                    <RemoveRedEyeIcon style={{ color: 'red' }} />
                    </IconButton>

                    <IconButton
                    title='Delete Recipe'
                      onClick={async (e) => {
                        await getConfirmBox(id,rrecipeTitle,rtypeOfRecipe,rpage,sizes);
                      }}
                    >
                      <DeleteIcon style={{ color: 'red' }} />
                      </IconButton>
                    {/* <Button
                    style={{height:'40px',fontSize:'11px'}}
                    variant='outlined'
                    startIcon={<DeleteIcon />}
                      onClick={async () => {
                        await dispatch(deleteRecipe(id,rrecipeTitle,rtypeOfRecipe,rpage,sizes));
                      }}
                    >
                      Delete Cook Book
                    </Button> */}
                  </StyledTableCell>
              </StyledTableRow>
            );
          });
  } else if (recipes.length === 0) {
    renderedStudents = (
      <StyledTableRow>
        <StyledTableCell component='th' scope='row' style={{width:"100%"}}>
          <Typography variant='subtitle2' className={classes.label}>
            No Cookbook found.
            <Link to={ADD_RECIPE_VIEW} className={classes.link}>
              Add Recipe?
            </Link>
          </Typography>
        </StyledTableCell>
      </StyledTableRow>
    );
  } else {
    renderedStudents = (
      <StyledTableRow>
        <StyledTableCell component='th' scope='row'></StyledTableCell>
      </StyledTableRow>
    );
  }
  let renderType=[];
  renderType=[
    { "label": "Other",
    "value": "Other"
  },
  { "label": "Breakfast",
  "value": "Breakfast"
},
  { "label": "Lunch",
  "value": "Lunch"
  },
  { "label": "Dinner",
    "value": "Dinner"
  }];
  const customStyles = {
    control: (base: any, state: any) => ({
      ...base,
      background: '#FAFAFA',
      // match with the menu
      borderRadius: '8px',
      padding: '1.5px 1.5px',

      borderColor: state.isFocused ? '#0092BC' : '#C0C0C0',

      // Overwrittes the different states of border
      '&:hover': {
        // Overwrittes the different states of border
        borderColor: state.isFocused ? '#0092BC' : '#C0C0C0',
      },
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
    }),
    menu: (base: any) => ({
      ...base,
      background: '#FAFAFA',
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0,
    }),
    menuList: (base: any) => ({
      ...base,
      background: '#FAFAFA',
      // kill the white space on first and last option
    }),
  };

  
  const totalNumberPages=(totalRecipe: number,sizes: number)=>{
    let pagesr =[];
    for(let x=1;x<=totalRecipe/sizes;x++)
    {
      pagesr.push(x);
    }
    return pagesr;
  }
  function apicall()
  {
    //console.warn("rrecipeTitle-->"+rrecipeTitle+"<br/>rtypeOfRecipe-->"+rtypeOfRecipe);
    let studid="";

      dispatch(getRecipesForProgram(rrecipeTitle,rtypeOfRecipe,recipeTags,1,sizes,studid));
   
   // console.warn("rrdata-->"+rdataa);

  }
  function cleartextall()
  {
    //console.warn("rrecipeTitle-->"+rrecipeTitle+"<br/>rtypeOfRecipe-->"+rtypeOfRecipe);
    setrrecipeTitle("");
    setrtypeOfRecipe("");
   renderType=[
    { "label": "Other",
    "value": "Other"
  },
  { "label": "Breakfast",
  "value": "Breakfast"
},
  { "label": "Lunch",
  "value": "Lunch"
  },
  { "label": "Dinner",
    "value": "Dinner"
  }];
    console.warn("chk value"+rtypeOfRecipe)
   // alert(rtypeOfRecipe);
    let recipeTitleBlack="";
    let rtypeOfRecipeBlank="";
    let recipeTagsBlank="";
    let studid="";
    
      dispatch(getRecipesForProgram(recipeTitleBlack,rtypeOfRecipeBlank,recipeTagsBlank,1,10,studid));
   
   // console.warn("rrdata-->"+rdataa);

  }
  const handleSelectChange = (event:any) => {
    const selectedOption = event.target.value;
    setrtypeOfRecipe(selectedOption);
  };
  return (
    <div>
      <Typography variant='h6'>
        {/* Students */}
        Cookbook
      </Typography>
      <br />
      <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
      <Grid item md={3} xs={2}>
      <Button
        variant='contained'
        color='primary'
        onClick={() => {
          history.push(ADD_RECIPE_VIEW);
        }}
        startIcon={<AddIcon />}
        style={{marginTop:'20px'}}

      >
        {/* Add Student */}
        Add Recipe
      </Button>
      &nbsp; &nbsp; &nbsp;
      <Button
        variant='contained'
        color='primary'
        onClick={() => {
          history.push(favorite_recipe_by_user(studentId));
        }}
        startIcon={<AddIcon />}
        style={{marginTop:'20px'}}

      >
        {/* Add Student */}
        My Favorite
      </Button>
      </Grid>
      <Grid item md={2} xs={2}>
            <FormControl
              required
              className={classes.formControl}
              style={{ width: '100%', paddingRight: '0.4em' }}
            >
              <Typography variant='subtitle1' className={classes.label}>
                Enter Recipe Name
              </Typography>
              <TextField
                placeholder='Title'
                type='text'
                name='recipeTitle'
                variant='outlined'
                size='small'
                value={rrecipeTitle}
                onChange={(e)=>setrrecipeTitle(e.target.value)} 
                InputLabelProps={{
                  classes: {
                    root: classes.heading,
                    focused: classes.cssFocused,
                  },
                }}
              />
            </FormControl>
          </Grid>
          <Grid item md={2} xs={2}>
            <FormControl required className={classes.formControl}>
              <Typography className={classes.label}>
              Recipe Type
              </Typography>
              {/* <AsyncSelect
                
                cacheOptions
                defaultOptions={renderType}
                styles={customStyles}
                defaultInputValue={rtypeOfRecipe}
                onChange={(option) => {
                  if (option) {
                    setrtypeOfRecipe(option.value);
                  }
                }}

              /> */}
             <select value={rtypeOfRecipe} onChange={handleSelectChange} className='form-control' >
                <option value="">Select an option</option>
                <option value="Other">Other</option>
                <option value="Breakfast">Breakfast</option>
                <option value="Lunch">Lunch</option>
                <option value="Dinner">Dinner</option>
              </select>
            </FormControl>
          </Grid>
          <Grid item md={2} xs={12}>  
            <FormControl
              required
              className={classes.formControl}
              style={{ width: '100%', paddingRight: '0.4rem' }}
            >
              <Typography variant='subtitle1' className={classes.label}>
                Select Tags
              </Typography>
              <AsyncSelect
                cacheOptions
                placeholder='Select Tags'
                defaultOptions={renderedRecipeTags}
                styles={customStyles}
                //onChange={handleTagSelectChange}
                onChange={(option) => {
                 // console.warn("Tags Options",option);
                  setFieldValue(option?.label)

                }}
                defaultValue={rtags.map((item) => {
                    return {
                      value: item.value,
                      label: item.label,
                    };
                  })}
              /> 
            </FormControl>
          </Grid>
            <Grid item md={3} xs={1}>
              <Button
                variant='contained'
                color='primary'
                onClick={() => apicall()}
                style={{"marginTop":"16px"}}
                startIcon={<SearchIcon />}
              >
                {/* Add Student */}
                SEARCH 
              </Button>
              &nbsp;&nbsp;
              <Button
                variant='contained'
                color='primary'
                onClick={cleartextall}
                style={{"marginTop":"16px"}}
              >
                {/* Add Student */}
                CLEAR 
              </Button>
            </Grid>
      </Grid>
      <br />
      <TableContainer component={Paper}>
        <Table aria-label='customized table'>
          <TableHead>
            <TableRow style={{width:"100%"}}>
              <StyledTableCell style={{width:"5%"}}>S.No.</StyledTableCell>
              <StyledTableCell style={{width:"10%"}}>Image</StyledTableCell>
              <StyledTableCell style={{width:"10%"}}>Title</StyledTableCell>
              <StyledTableCell style={{width:"25%"}}>Description</StyledTableCell>
              <StyledTableCell style={{width:"10%"}}>Edit Recipes</StyledTableCell>
              {/* <StyledTableCell style={{width:"10%"}}>View Ingredient</StyledTableCell>
              <StyledTableCell style={{width:"10%"}}>View Steps</StyledTableCell> */}
              {/* <StyledTableCell style={{width:"10%"}}>View Nutritions</StyledTableCell> */}
              <StyledTableCell style={{width:"10%"}}>Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>{renderedStudents}</TableBody>
        </Table>
      </TableContainer>
      <br/>
      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='center'
      >
        <Grid item md={8} xs={8}>

        </Grid>
        <Grid item md={4} xs={4}>
          <nav aria-label="Page navigation example">
            <ul className="pagination justify-content-end">
              {recipesPage!==1 && <li className="page-item" onClick={()=>setPage(recipesPage - 1)}>
                <a className="page-link" href="javascript:void(null)" >Previous</a>
              </li>}
             {totalNumberPages(totalRecipe,sizes).map(pageNo => 
                <li className={`page-item ${pageNo===recipesPage ? 'active':''}`} key={pageNo} onClick={()=>setPage(pageNo)}>
                  <a className="page-link" href="javascript:void(null)">
                    {pageNo}
                  </a>
                </li>
              )} 
             
                {
                  recipesTotal > sizes?
                recipesPage!==totalRecipe/sizes && <li className="page-item" onClick={()=>setPage(recipesPage + 1)}>
                  <a className="page-link" href="javascript:void(null)">Next</a>
                </li>:""
                }
              
            </ul>
          </nav>
        </Grid>
      </Grid>
    </div>
  );
};
export default ListRecipeAll
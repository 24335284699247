import React, { useEffect, useState } from 'react';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
  IconButton,
  Grid,
  TextField,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from '../../../app/rootReducer';
import { StyledTableCell } from '../../../components/StyledTableCell';
import { StyledTableRow } from '../../../components/StyledTableRow';
import {
  ADD_COUNSELOR,
  EDIT_COUNSELOR_PROFILE,
  EDIT_PARENTS_COUNSELOR_PROFILE,
} from '../../../constants/routes';
import {
  getCounselorById,
  getCounselorsForOrganisationProgramRefresh,
  getCounselorsForProgram,
  getCounselorsForProgramRefresh,
  getCounselorsForProgramRefreshOrganization,
  getCounselorsForProgramRefreshParent,
  setCounselor,
} from '../../../slices/counselorSlice';
import AddIcon from '@mui/icons-material/Add';

import formStyles from '../../../assets/jss/components/formStyles';
import EditIcon from '@mui/icons-material/Edit';
import { approvedOrganisation, changeProgramStatus, getOrganisationList, getProgramManagerList, getSearchOrganisationBySuperAdmin } from '../../../api/programManagerRequests';
import { Autocomplete } from '@mui/material';
import { FiRefreshCw} from 'react-icons/fi';
import NoImageIcon from '@mui/icons-material/HideImage';


interface ICounselorProfilesProps {}

const useStyles = makeStyles(formStyles);

const CounselorProfiles: React.FC<ICounselorProfilesProps> = ({}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();

  const { counselors } = useSelector((state: RootState) => {
    return {
      counselors: state.counselor.counselors,
    };
  }, shallowEqual);

  localStorage.setItem('urlRoute', "parent_admins");

  const authlocal = localStorage.getItem('userRole');
  const user_id = localStorage.getItem('user_id');
console.log("authlocal-->"+authlocal)
  useEffect(() => {
    if(user_id && authlocal=="3")
    {
      dispatch(getCounselorsForProgramRefreshParent(user_id));
    }
    else if(user_id && authlocal=="0")
    {
      //dispatch(getCounselorsForOrganisationProgramRefresh(user_id));
      //dispatch(getCounselorsForProgramRefreshParent(user_id));
      dispatch(getCounselorsForProgramRefreshOrganization(user_id));
    }
    else if(user_id && authlocal=="4")
    {
      dispatch(getCounselorsForProgramRefresh(user_id));
    }
    else
    {
      dispatch(getCounselorsForProgram());

    }
    dispatch(setCounselor(null));
    return () => {};
  }, [counselors.length]);

  let renderedCounselors;
  if (counselors.length > 0) {
    renderedCounselors = counselors.map((counselor, index) => {
      const { name, email,imageUrl, referenceCode, id } = counselor;
      let userimg=imageUrl==="no-url"?"https://nflyte-1.s3.amazonaws.com/ef12f40b-f758-4e9c-ab7c-acebc7e240c6.jpg":imageUrl;

      return (
        <StyledTableRow key={index}>
          <StyledTableCell component='th' scope='row'>
            {index + 1}
          </StyledTableCell>
          <StyledTableCell component="th" scope="row">
          {userimg !== '' ? (
                              <a href={userimg} target='_blank'>
                                <img
                                  src={userimg}
                                  width="50" height="50"
                                  className="rounded"
                                />
                              </a>
                            ) : (
                              <span>-</span>
                            )}
          </StyledTableCell>
          {/* <StyledTableCell>{referenceCode}</StyledTableCell> */}
          <StyledTableCell>{name}</StyledTableCell>
          <StyledTableCell>{email}</StyledTableCell>
          <StyledTableCell>
            <IconButton
              onClick={async () => {
                await dispatch(getCounselorById(id));
                if(authlocal==="3")
                {
                  history.push(EDIT_PARENTS_COUNSELOR_PROFILE(id));
                }
                else
                {
                  history.push(EDIT_COUNSELOR_PROFILE(id));
                }
              }}
            >
              <EditIcon />
            </IconButton>
          </StyledTableCell>
        </StyledTableRow>
      );
    });
  } else if (counselors.length === 0) {
    renderedCounselors = (
      <StyledTableRow>
        <StyledTableCell component='th' scope='row'>
          <Typography variant='subtitle2' className={classes.label}>
            No admin found.
            <Link to={ADD_COUNSELOR} className={classes.link}>
              Add Admin?
            </Link>
          </Typography>
        </StyledTableCell>
      </StyledTableRow>
    );
  } else {
    renderedCounselors = (
      <StyledTableRow>
        <StyledTableCell component='th' scope='row'></StyledTableCell>
      </StyledTableRow>
    );
  }
  
 
  return (
    <div>
      <Typography variant='h6'>Admins</Typography>
      <br />
      <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
           <Grid item md={6} xs={12}>
          <Button
                variant='contained'
                color='primary'
                onClick={() => {
                  history.push(ADD_COUNSELOR);
                }}
                startIcon={<AddIcon />}
              >
                Add Admin
              </Button>
          </Grid>
          
          
        </Grid>
      
      <br />
      <br />
      <TableContainer component={Paper}>
        <Table aria-label='customized table'>
          <TableHead>
            <TableRow>
              <StyledTableCell>S.No.</StyledTableCell>
              {/* <StyledTableCell>Refrence Code</StyledTableCell> */}
              <StyledTableCell>Image</StyledTableCell>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell>Email</StyledTableCell>
              <StyledTableCell>Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>{renderedCounselors}</TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default CounselorProfiles;

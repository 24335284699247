import React from 'react';
import { StyledTableCell } from '../../../components/StyledTableCell';
import { StyledTableRow } from '../../../components/StyledTableRow';
import { Task } from '../../../constants/models/Task';
import { DateTime } from 'luxon';
import { TASK_STATE } from '../../../constants/taskState';
import {
  Box,
  Button,
  Collapse,
  IconButton,
  TableCell,
  TableRow,
  Table,
  TableBody,
  Typography,
  TableHead,
} from '@material-ui/core';
import DeleteIcon from '@mui/icons-material/Delete';
import EyeIcon from '@mui/icons-material/RemoveRedEyeSharp';

import { useDispatch } from 'react-redux';
import {
  deleteTask,
  setIsDeleteModalOpen,
  setTask,
  setTaskId,
} from '../../../slices/taskSlice';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useHistory,useParams } from 'react-router';
import Chip from '@material-ui/core/Chip';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/ModeOutlined';
import CloseIcon from '@mui/icons-material/Close';
import DoneAll from '@material-ui/icons/DoneAll';
import Tooltip from '@material-ui/core/Tooltip';
import ErrorIcon from '@material-ui/icons/Error';
import { EDIT_TASK, viewStudentTask } from '../../../constants/routes';
import NoImageIcon from '@mui/icons-material/HideImage';
import moment from 'moment';
import { getRecipeById, setRecipe } from '../../../slices/recipeSlice';

interface ITaskRowProps {
  index: number;
  task: Task;
}

export const TaskRow: React.FC<ITaskRowProps> = ({ task, index }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const { studentId } = useParams<{ studentId: string | undefined }>();

  const { start, end, name, state, id, taskType, completionTime,completedImageUrl,recipeData,imageUrl, taskLocation } = task;
  //let task_completedImageUrl=task.completedImageUrl;

  let renderedState;
  let renderedChip;

  if (state === TASK_STATE.STARTED) {
    renderedChip = (
      <Tooltip title='This task has not been completed' arrow>
        <Chip
          size='medium'
          icon={<PendingActionsIcon style={{ color: 'grey' }} />}
          label='Pending'
          variant='outlined'
          //color="primary"
          style={{ borderColor: 'grey', color: 'grey' }}
        />
      </Tooltip>
    );
  } else if (state === TASK_STATE.COMPLETED) {
    //          label={`Completed (at ${completionTime})`}

    //label={`Completed (at ${moment(completionTime).format('h:mm A')})`}
    renderedChip = (
      <Tooltip title='Task completed!' arrow>
        <Chip
          size='medium'
          icon={<DoneAll style={{ color: 'green' }} />}
          label={`Completed (at ${moment(completionTime).format('h:mm A')})`}
          variant='outlined'
          //color="primary"
          style={{ borderColor: 'green', color: 'green' }}
        />
      </Tooltip>
    );
  } else if (state === TASK_STATE.OVERDUE) {
    renderedChip = (
      <Tooltip title="Task wasn't completed by the student!" arrow>
        <Chip
          size='medium'
          icon={<ErrorIcon style={{ color: 'orange' }} />}
          label='Overdue'
          variant='outlined'
          //color="primary"
          style={{ borderColor: 'orange', color: 'orange' }}
        />
      </Tooltip>
    );
  }
  return (
    <React.Fragment>
      <StyledTableRow>
        <StyledTableCell>
          <IconButton
            aria-label='expand row'
            size='small'
            onClick={() => setOpen(!open)}
            disabled={task===undefined}
            // disabled={task && task.subTasks && task.subTasks?.length === 0}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </StyledTableCell>
        <StyledTableCell>
        {imageUrl? 
            <img src={imageUrl} style={{ width: 35, height: 35 }} className='img img-circle'/>:
            <img src={'https://nflyte-1.s3.amazonaws.com/a068d46e-9170-4ad7-88b2-eb119180ec9a.png'} style={{ width: 40, height: 40 }}/>
          }
        </StyledTableCell>
        <StyledTableCell colSpan={2} style={{fontSize:'14px'}}>

         Title - {name}<br/>
         
         Time - {moment(start).format('h:mm A')}
          {' '}
          -{' '}
          {moment(end).format('h:mm A')}<br/>
          
          {/* {DateTime.fromJSDate(new Date(end)).toLocaleString(
            DateTime.TIME_SIMPLE
          )} */}

        </StyledTableCell>
       
        <StyledTableCell style={{fontSize:'14px'}}>

        
        {taskLocation}
        {/* {DateTime.fromJSDate(new Date(end)).toLocaleString(
          DateTime.TIME_SIMPLE
        )} */}

        </StyledTableCell>
        <StyledTableCell>
          <em>{taskType.name}</em>
        </StyledTableCell>
        <StyledTableCell>{renderedChip}</StyledTableCell>

        <StyledTableCell style={{ display: 'table-cell', flexDirection: 'row' }}>
        {/* <IconButton
            onClick={() => {
              //dispatch(deleteTask(id));
              // dispatch(setTaskId(id));
             // await dispatch(setTask(task));
              // dispatch(setIsDeleteModalOpen(true));
              history.push(viewStudentTask(studentId));
            }}
          >
            <EyeIcon color='primary' />
          </IconButton> */}
          <IconButton
            onClick={async () => {
              //dispatch(deleteTask(id));
              // dispatch(setTaskId(id));
              if(task.recipeId!=="")
              {
                dispatch(getRecipeById(task.recipeId));
              }
              else
              {
                dispatch(setRecipe(null));
              }
              await dispatch(setTask(task));
              localStorage.setItem('userData',"")

              // dispatch(setIsDeleteModalOpen(true));
              history.push(EDIT_TASK(id));
            }}
          >
            <EditIcon color='primary' />
          </IconButton>
          <IconButton
            onClick={async () => {
              //dispatch(deleteTask(id));
              dispatch(setTaskId(id));
              await dispatch(setTask(task));
              dispatch(setIsDeleteModalOpen(true));
            }}
          >
            <DeleteIcon style={{ color: 'red' }} />
          </IconButton>
        </StyledTableCell>
      </StyledTableRow>
      { recipeData?(
      <TableRow style={{ width: '100%' }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Box margin={1}><br/>
                <h6>Recipe Details</h6>
                <Table size='small' aria-label='purchases'>
                    <TableHead>
                      <TableRow>
                      <TableCell align='center'>
                          <strong>Image</strong>
                        </TableCell>
                        <TableCell align='center'>
                          <strong>Recipe Title</strong>
                        </TableCell>
                        {/* <TableCell align='center'>
                          <strong>Status</strong>
                        </TableCell> */}
                        
                        <TableCell align='center'>
                          <strong>Recipe Description</strong>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {recipeData?
                      
                            <TableRow>
                              <TableCell component='th' scope='row' align='center'>
                                {recipeData.recipeUrl !== 'no-url' ? (
                                    <a href={recipeData.recipeUrl} target='_blank'>
                                      <img
                                        src={recipeData.recipeUrl}
                                        height='50'
                                        width='50'
                                      />
                                    </a>
                                  ) : (
                                    <NoImageIcon style={{ color: 'grey' }} />
                                  )}
                              </TableCell>
                              {/* <TableCell align='right'>{renderedIcon}</TableCell> */}
                              <TableCell align='center'>
                                <em>
                                  
                                  {recipeData.recipeTitle}
                                </em>
                                
                              </TableCell>
                              <TableCell align='center'>
                                {recipeData.recipeDescription}
                                </TableCell>
                            </TableRow>:""
                        
                        }
                    </TableBody>
                  </Table>
                  <br />  
              
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>):("")
      }
      { task.subTasks!==undefined && task.subTasks?.length > 0?(
      <TableRow style={{ width: '100%' }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Box margin={1}>
              <Table size='small' aria-label='purchases'>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <strong>Instructions</strong>
                    </TableCell>
                    {/* <TableCell align='right'>
                      <strong>Status</strong>
                    </TableCell> */}
                    <TableCell align='right'>
                      <strong>Image</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {task.subTasks &&
                    task.subTasks.map((subTask, index) => {
                      const { id, name, isCompleted, completedImageUrl } =
                        subTask;
                      let renderedIcon;

                      if (state === TASK_STATE.STARTED && !isCompleted) {
                        renderedIcon = (
                          <PendingActionsIcon style={{ color: 'grey' }} />
                        );
                      } else if (
                        state === TASK_STATE.COMPLETED &&
                        isCompleted
                      ) {
                        renderedIcon = <DoneIcon style={{ color: 'grey' }} />;
                      } else {
                        renderedIcon = <ErrorIcon style={{ color: 'grey' }} />;
                      }

                      let renderedCompletedImageUrl;

                      return (
                        <TableRow key={id}>
                          <TableCell component='th' scope='row'>
                            <em>
                              {index + 1}.&nbsp;
                              {name}
                            </em>
                          </TableCell>
                          {/* <TableCell align='right'>{renderedIcon}</TableCell> */}
                          <TableCell align='right'>
                            {completedImageUrl !== 'no-url' ? (
                              <a href={completedImageUrl} target='_blank'>
                                <img
                                  src={completedImageUrl}
                                  height='20'
                                  width='20'
                                />
                              </a>
                            ) : (
                              <NoImageIcon style={{ color: 'grey' }} />
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
              <br />
              
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>):("")
      }

      { 
        
        task.completedImageUrl!=="no-url"?(
      
      <TableRow style={{ width: '100%' }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Box margin={1}>
              <Table size='small' aria-label='purchases'>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <strong>Task Completed Image</strong>
                    </TableCell>
                   
                    <TableCell align='left'>
                      <strong>Image</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                 
                      
                        <TableRow key={id}>
                          <TableCell component='th' scope='row'>
                            <em>
                              {/* {index + 1}.&nbsp;
                              {task.name} */}
                            </em>
                          </TableCell>
                          
                          <TableCell align='left'>
                            {task.completedImageUrl !== 'no-url' ? (
                              <a href={task.completedImageUrl} target='_blank'>
                                <img
                                  src={task.completedImageUrl}
                                  width="193" height="130"
                                />
                              </a>
                            ) : (
                              <NoImageIcon style={{ color: 'grey' }} />
                            )}
                          </TableCell>
                        </TableRow>
                      
                   
                </TableBody>
              </Table>
              <br />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>):("")
      }
    </React.Fragment>
  );
};

import React, { useEffect,useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import dashboardCardStyles from '../../../assets/jss/components/dashboardCardStyles';
import { Link, useHistory } from 'react-router-dom';
import { RootState } from '../../../app/rootReducer';
import { getChildForParent, getChildForParentRefresh, getOnboardingLogsEntry, getParentById, setParent } from '../../../slices/parentSlice';
import {  Grid,
    Container,
    CssBaseline,
    TextField,
    FormControl,
    IconButton,
    Button,
    Switch,
    InputAdornment, } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import GRAPHIC_2 from '../../../assets/images/Menu/messaging.svg';
import GRAPHIC_3 from '../../../assets/images/Menu/add_task.svg';
import GRAPHIC_5 from '../../../assets/images/Menu/success_dashboard.svg';
import GRAPHIC_6 from '../../../assets/images/Menu/cookbook.svg';
import GRAPHIC_9 from '../../../assets/images/Menu/cookbook_new.svg';
//import GRAPHIC_7 from '../../../assets/images/Menu/a1nog-b9wnj.3da05c91.svg';
import GRAPHIC_7 from '../../../assets/images/Menu/viewtask.svg';
import GRAPHIC_8 from '../../../assets/images/Menu/gorcerylist.svg';
import CookbookIcon from '@mui/icons-material/CollectionsBookmark';

import PersonIcon from '@mui/icons-material/Person';
import FaceIcon from '@mui/icons-material/Face';
import EventIcon from '@mui/icons-material/Event';
import ChatIcon from '@mui/icons-material/Chat';
import formStyles from '../../../assets/jss/components/formStyles';
import { AiOutlinePlusCircle } from 'react-icons/ai';



import {
  ADD_TASK,
  EDIT_PARENT,
  MESSAGING,
  PROGRAM_MANAGER_INTAKE,
  PARENT_STUDENT_PROFILES,
  VIEW_CALENDAR,
  viewStudentTask,
  viewStudentGroceryList,
  ADD_RECIPE,
  ADD_RECIPE_BY_USER,
  ADD_ALL_RECIPE_BY_USER,
  MY_RECIPES,
  ADD_STUDENT,
} from '../../../constants/routes';
import AsyncSelect from 'react-select/async';
import { ListItemIcon } from '@mui/material';
import { ViewTaskLogs, getStudentById, getStudentsForParent, getStudentsForProgram } from '../../../slices/studentSlice';
import { addStudentId, getStudentTasksForDate, setStudentIds } from '../../../slices/taskSlice';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { getUserUpdateUserguid } from '../../../api/parentRequests';


interface IParentDashboardProps {}

const useStyles = makeStyles(dashboardCardStyles);
const useStyles_2 = makeStyles(formStyles);

export const ParentDashboard: React.FC<IParentDashboardProps> = ({}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const classes_2 = useStyles_2();
  const cursorStyle={cursor:"pointer"}
  const [studentId,setStudentId]=useState("");
  const [selectedDate,setSelectedDate]=useState(new Date());

  const { parent, students} = useSelector((state: RootState) => {
    return {
      parent: state.parent.parent,
      students: state.student.students,

    };
  }, shallowEqual);

  const customStyles = {
    control: (base: any, state: any) => ({
      ...base,
      background: '#FAFAFA',
      // match with the menu
      borderRadius: '8px',
      padding: '1.5px 1.5px',

      borderColor: state.isFocused ? '#0092BC' : '#C0C0C0',

      // Overwrittes the different states of border
      '&:hover': {
        // Overwrittes the different states of border
        borderColor: state.isFocused ? '#0092BC' : '#C0C0C0',
      },
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
    }),
    menu: (base: any) => ({
      ...base,
      background: '#FAFAFA',
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0,
      zIndex: 9999,
    }),
    menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
    menuList: (base: any) => ({
      ...base,
      background: '#FAFAFA',
      // kill the white space on first and last option
    }),
  };
//   useEffect(() => {
//     dispatch(setSelectedDate(new Date()));
//   }, []);
console.log('check parent--->'+JSON.stringify(parent));
const user_id = localStorage.getItem('user_id');
  useEffect(() => {

    (async () => {
       if (parent && user_id) {
       // dispatch(getChildForParentRefresh(parent.id, history));
        await dispatch(getChildForParentRefresh(user_id, history));
        if(students)
        {
          for(let i=0;i<students.length;i++)
          {
            if(students.length===1)
            {
              setStudentId(students[i].id)
              dispatch(addStudentId(students[i].id));


            }
            else
            {
              setStudentId(students[0].id)
              dispatch(addStudentId(students[0].id));
            }
            
          }
        }
      }
      const authlocal = localStorage.getItem('userg');

       

    })();
    return () => {};
  }, []);
  
  
useEffect(() => {
  async function fetchMyAPI() {
    if(parent)
    {
      console.log("check parent data---->"+JSON.stringify(parent.is_user_guid_played))
      if(parent.is_user_guid_played==="no")
      {
        // const scriptfirst = document.createElement('script');
  
        // scriptfirst.src = "https://admin.nflyte.com/usergfirst.js";
        // scriptfirst.async = true;
      
        // document.body.appendChild(scriptfirst);

        // return () => {
        //   document.body.removeChild(scriptfirst);
        // }

        const script = document.createElement('script');
  
        script.src = "https://admin.nflyte.com/userg.js";
        script.async = true;
      
        document.body.appendChild(script);
        const orgDatanxt = await getUserUpdateUserguid(parent.id);
        dispatch(getOnboardingLogsEntry(parent.id));
        dispatch(setParent(orgDatanxt));
        return () => {
          //document.body.removeChild(scriptfirst);

          //document.body.removeChild(script);
        }
      }
     
    }
    else
    {
      
    }
  }
  fetchMyAPI()
    return () => {};
  
}, [])
const [width, setWidth] = useState<number>(window.innerWidth);

function handleWindowSizeChange() {
    setWidth(window.innerWidth);
}
useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    }
}, []);

const isMobile = width <= 768;
  function getuserg()
  {
    
  }
  const authlocal = localStorage.getItem('userRole');

  console.log('check parent users--->'+authlocal);
  // useEffect(() => {
    
  //     dispatch(getStudentsForParent());
  // }, []);
  let renderedTaskTypes = students.map((student) => {
    return {
      value: student.id,
      label: student.name,
    };
  });

  function functionViewTask(sid:any)
  {
    console.log("chkl 2---->")

        if(sid)
        {
             dispatch(ViewTaskLogs(sid));
             dispatch(getStudentTasksForDate(sid, selectedDate));
             dispatch(getStudentById(sid));
            history.push(viewStudentTask(sid));
    
        }
        else
        {
          alert("Select User First Please");
        }
   
   
        
  }
  function functionAddTask(sid:string)
  {
    if(sid && user_id)
    {
      //dispatch(getStudentsForParent());
         // dispatch(addStudentId(studentId));
         dispatch(getChildForParentRefresh(user_id, history));
         dispatch(addStudentId(sid));

         history.push(ADD_TASK(sid));
    }
    else
    {
      alert("Select User First Please");
    }
        
  }
  function functionViewGrocery(sid:string)
  {
    if(sid)
    {
      
       dispatch(getStudentById(sid));
      history.push(viewStudentGroceryList(sid));

    }
    else
    {
      alert("Select User First Please");
    }
        
  }
  function functionViewCookBook()
  {
    // if(studentId)
    // {
      
    //   history.push((ADD_RECIPE_BY_USER(studentId)));

    // }
    // else
    // {
    //   alert("Select User First Please");
    // }
    history.push(MY_RECIPES);
        
  }
  function functionViewCookBookAll(sid:string)
  {
    
    if(sid)
    {
      history.push((ADD_ALL_RECIPE_BY_USER(sid)));
    }
    else
    {
      alert("Select User First Please");
    }
        
  }
  const handleSelectChange = (event:any) => {
    const selectedOption = event.target.value;
    setStudentId(selectedOption);
    dispatch(addStudentId(selectedOption));

  };
  function checkStudentIdExist(req:string)
  {
    if(studentId)
    {
        if(students)
        {

          for(let i=0;i<students.length;i++)

          {
            if(students.length===1)
            {

              setStudentId(students[i].id)
              dispatch(addStudentId(students[i].id));
              if(req==="viewtask")
              {
                functionViewTask(students[i].id);
               // dispatch(ViewTaskLogs(students[i].id));
                
              }
              else if(req==="addtask")
              {
                functionAddTask(students[i].id);
              }
              else if(req==="viewgrocery")
              {
                functionViewGrocery(students[i].id);
              }
              else if(req==="viewcookbook")
              {
                functionViewCookBookAll(students[i].id);
              }
            }
            else
            {
              if(studentId)
              {
                console.log("chkl 2---->"+req)

                if(req==="viewtask")
                {
                  functionViewTask(studentId);
                }
                else if(req==="addtask")
                {
                  functionAddTask(studentId);
                }
                else if(req==="viewgrocery")
                {
                  functionViewGrocery(studentId);
                }
                else if(req==="viewcookbook")
                {
                  functionViewCookBookAll(studentId);
                }
              }
            }
          }
        
        }
    }
    else
    {
      alert("No User Found For Select User");
    }
  }
  return (
  <div>
    
     <Grid container direction='row'>
        {/* <Grid
          item
          xs={12}
          md={2}
          style={{
            display: 'flex',
            justifyContent: 'right',
            alignItems: 'right',
            textAlign:'right'
          }}
          
        >
            <FormControl required className={classes_2.formControl}>
            <Typography variant='subtitle1' className={classes_2.label}>
                Select User -  &nbsp;&nbsp;
            </Typography>
           <AsyncSelect
                cacheOptions
                defaultOptions={renderedTaskTypes}
                styles={customStyles}
                //value={studentId}
                onChange={(option) => {
                if (option) {
                  setStudentId(option.value);
                  //addStudentId(option.value);
                 /// dispatch(addStudentId(option.value));
                }
                }}

            />   
           
            </FormControl>
        </Grid> */}
         <Grid
          item
          xs={12}
          md={12}
          style={{
            display: 'flex',
            justifyContent: 'right',
            alignItems: 'right',
            padding:'15px'
          }}
          
        >
         
          {/* <Button
              className="btn btn-light"
              variant='contained'
  
              type='button'
              // fullWidth
              onClick={(e) => {
                history.push(ADD_STUDENT);
              }} style={{marginTop:'-10px'}}
            >
              Add New User
            </Button> */}
            <Link to={ADD_STUDENT}  onClick={(e) => {
                history.push(ADD_STUDENT);
              }} style={{marginTop:'-10px',borderRadius:'9px'}}>
                  Add New User <AiOutlinePlusCircle />
              </Link>
          
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding:'5px'
          }}
          
        >
            <FormControl required className={classes_2.formControl}>
           
            {students.length===1?
             <select autoFocus value={studentId} onChange={handleSelectChange} className='form-control' style={{borderRadius:'20px'}}>
                {students.map((student, index) => (
                  <option key={index} value={student.id}>
                    {student.name}
                  </option> 
                ))}
              </select>:
              <select autoFocus value={studentId} onChange={handleSelectChange} className='form-control' >
              
                <option value="">Select User</option>
                {students.map((student, index) => (
                  <option key={index} value={student.id}>
                    {student.name}
                  </option>
                ))}
              </select>
              }
            </FormControl>
        </Grid>
       
    </Grid><br/><br/>
    <Grid container direction='row'>

        <Grid
          item
          xs={12}
          md={6}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onClick={ async(e) => {
            let req="viewtask";
            await checkStudentIdExist(req);
            

           // history.push(EDIT_PARENT);
          }}
        >
          <div style={{ width: '300px' }} className="text-center">
            <div className={classes.davBox}>
              <Typography variant={'h4'} className={classes.title}>
                {/* Counselor Profiles */}
                View Task
              </Typography>
              <span className={classes.davBoxIcon}>
               <ChatIcon
                  width='100%'
                  fontSize='large'
                  className={classes.icon}
                />
              </span>
            </div>
            <div className={classes.img} >
              <img src={GRAPHIC_7} style={{
                    cursor:'pointer',
                    maxHeight:'100%',
                    maxWidth:'100%'
              }}/>
            </div>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onClick={async () => {
            let req="addtask";
            await checkStudentIdExist(req);
          
          }}
        >
          <div style={{ width: '300px' }}>
            <div className={classes.davBox}>
              <Typography variant={'h4'} className={classes.title}>
                Add Task
              </Typography>
              <span className={classes.davBoxIcon}>
                <EventIcon
                  width='100%'
                  fontSize='large'
                  className={classes.icon}
                />
              </span>
            </div>
            <div className={classes.img}>
              <img src={GRAPHIC_3} style={{
                    cursor:'pointer'
              }}/>
            </div>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onClick={async () => {
            let req="viewgrocery";
            await checkStudentIdExist(req);
          }}
        >
          <div style={{ width: '300px' }} className="text-center">
            <div className={classes.davBox}>
              <Typography variant={'h4'} className={classes.title}>
                {/* Counselor Profiles */}
                Grocery List
              </Typography>
              <span className={classes.davBoxIcon}>
               <CookbookIcon
                  width='100%'
                  fontSize='large'
                  className={classes.icon}
                />
              </span>
            </div>
            <div className={classes.img} >
              <img src={GRAPHIC_8} style={{
                    cursor:'pointer',
                    maxHeight:'100%',
                    maxWidth:'100%'
              }}/>
            </div>
          </div>
        </Grid>
        {/* <Grid
          item
          xs={12}
          md={6}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onClick={(e) => {
            history.push(MESSAGING);
          }}
        >
          <div style={{ width: '300px' }}>
            <div className={classes.davBox}>
              <Typography variant={'h4'} className={classes.title}>
                Messaging
              </Typography>
              <span className={classes.davBoxIcon}>
                <ChatIcon
                  width='100%'
                  fontSize='large'
                  className={classes.icon}
                />
              </span>
            </div>
            <div className={classes.img}>
              <img src={GRAPHIC_2} style={{
                    cursor:'pointer'
              }}/>
            </div>
          </div>
        </Grid> */}
        
        <Grid
          item
          xs={12}
          md={6}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onClick={(e) => {
            functionViewCookBook();
          }}
        >
          <div style={{ width: '300px' }}>
            <div className={classes.davBox}>
              <Typography variant={'h4'} className={classes.title}>
                {/* Student Profiles */}
                My Recipes
              </Typography>
              <span className={classes.davBoxIcon}>
                <FaceIcon
                  width='100%'
                  fontSize='large'
                  className={classes.icon}
                />
              </span>
            </div>
            <div className={classes.img}>
              <img src={GRAPHIC_6} style={{
                    cursor:'pointer'
              }}/>
            </div>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onClick={async(e) => {
            let req="viewcookbook";
            await checkStudentIdExist(req);
          }}
        >
          <div style={{ width: '300px' }}>
            <div className={classes.davBox}>
              <Typography variant={'h4'} className={classes.title}>
                {/* Student Profiles */}
                Cookbook
              </Typography>
              <span className={classes.davBoxIcon}>
                <FaceIcon
                  width='100%'
                  fontSize='large'
                  className={classes.icon}
                />
              </span>
            </div>
            <div className={classes.img}>
              <img src={GRAPHIC_9} style={{
                    cursor:'pointer'
              }}/>
            </div>
          </div>
        </Grid>
        {/* <Grid
          item
          xs={12}
          md={6}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            
          }}
          
        >
          <div style={{ width: '300px',pointerEvents:'none',opacity:"0.7" }} >
            <div className={classes.davBox}>
              <Typography variant={'h4'} className={classes.title}>
                Success Dashboard
              </Typography>
              <span className={classes.davBoxIcon}>
                <FaceIcon
                  width='100%'
                  fontSize='large'
                  className={classes.icon}
                />
              </span>
            </div>
            <div className={classes.img}>
              <img src={GRAPHIC_5} style={{
                    cursor:'pointer'
              }} />
            </div>
          </div>
        </Grid> */}
        {/* <Grid item xs={12} md={4}>
          <div style={{ width: '300px' }}>
            <div className={classes.davBox}>
              <Typography variant={'h4'} className={classes.title}>
                Report
              </Typography>
              <span className={classes.davBoxIcon}>
                <DescriptionIcon
                  width='100%'
                  fontSize='large'
                  className={classes.icon}
                />
              </span>
            </div>
            <div className={classes.img}>
              <img src={GRAPHIC_5} />
            </div>
          </div>
        </Grid>

        <Grid item xs={12} md={4} onClick={(e) => {}}>
          <div style={{ width: '300px' }}>
            <div className={classes.davBox}>
              <Typography variant={'h4'} className={classes.title}>
                Notifications
              </Typography>
              <span className={classes.davBoxIcon}>
                <NotificationsIcon
                  width='100%'
                  fontSize='large'
                  className={classes.icon}
                />
              </span>
            </div>
            <div className={classes.img}>
              <img src={GRAPHIC_1} />
            </div>
          </div>
        </Grid> */}
      </Grid>
  </div>
  );
};


export default ParentDashboard;

import {
  PROGRAM_MANAGER_DASHBOARD,
  COUNSELOR_DASHBOARD,
  PARENT_DASHBOARD,
  SUPER_ADMIN_DASHBOARD,
} from './../constants/routes';
import { createCounselor, getCounselorById, getCounselorLogById, setCounselor } from './counselorSlice';
import {
  PROGRAM_MANAGER,
  COUNSELOR,
  STUDENT,
  USER_ROLE,
} from './../constants/userRoles';
import Amplify, { Auth, API, graphqlOperation } from 'aws-amplify';
import store, { AppThunk } from '../app/store';
import { History } from 'history';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import isEmpty from '../utils/isEmpty';
import { CognitoUser } from '@aws-amplify/auth';
import setAuthToken from '../utils/setAuthToken';
import { setErrorMsg, setSuccessMsg } from './alertSlice';
import { CONFIRM_USER, LOGIN, REGISTER } from '../constants/routes';
import {
  createProgramManager,
  getProgramForCounselor,
  getProgramForProgramManager,
  getProgramManagerById,
  getProgramManagerLogById,
  setProgramManger,
} from './programManagerSlice';
import * as PROGRAM_MANAGER_REQUESTS from '../api/programManagerRequests';
import * as COUNSELOR_REQUESTS from '../api/counselorRequests';
import * as PARENT_REQUESTS from '../api/parentRequests';
// @ts-ignore
import { createUser, updateUser } from '../graphql/mutations';
import { getUser } from '../graphql/queries';
import { createParent, getChildForParent, getParentById, getParentLogById, sendMailToParent, setParent, updateNewPassword } from './parentSlice';
import { setStudent,setStudents,getStudentsForParent } from './studentSlice';
import { setProgram } from './programSlice';
import { setChatRoom, setChatRoomUser, setChatRoomUsers, setGroupUsers, setUser } from './chatRoomSlice';
import { createSuperAdmin, getProgramForSuperAdmin, getSuperAdminById, getSuperAdminLogById } from './superAdminSlice';
import { Check } from '@material-ui/icons';
import * as REQUESTS from '../api/studentRequests';
import * as SUPER_REQUESTS from '../api/superAdminRequests';


export interface authState {
  loading: boolean;
  longLoading: boolean;
  error: string | null;
  user: CognitoUser | null;
  isAuthenticated: boolean;
  email: string;
  name: string;
  subject:string;
  schoolName: string;
  userId: string;
  role: USER_ROLE;
  step: number;
  forgotPasswordStep: number;
  loginStep: number;
  tempPassword: string;
  tempCode: number;
  graphQlUser: any;
  brosname:string;
}

export const initialState: authState = {
  loading: false,
  longLoading: false,
  error: null,
  user: null,
  isAuthenticated: false,
  email: '',
  name: '',
  subject:'',
  schoolName: '',
  role: USER_ROLE.PROGRAM_MANAGER,
  userId: '',
  step: 0,
  forgotPasswordStep: 0,
  loginStep: 0,
  tempPassword: '',
  brosname:'',
  tempCode: 0,
  graphQlUser: localStorage.getItem('graphQlUser')
    ? // @ts-ignore
      JSON.parse(localStorage.getItem('graphQlUser'))
    : null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    authStart(state) {
      state.loading = true;
      state.error = null;
    },
    setLongLoading(state, action: PayloadAction<boolean>) {
      state.longLoading = action.payload;
    },
    setGraphQlUser(state, action: PayloadAction<any>) {
      state.graphQlUser = action.payload.data;
    },
    setIsAuthenticated(state, action: PayloadAction<boolean>) {
      state.isAuthenticated = action.payload;
    },
    setEmail(state, action: PayloadAction<string>) {
      state.email = action.payload;
    },
    setName(state, action: PayloadAction<string>) {
      state.name = action.payload;
    },
    setBrowName(state, action: PayloadAction<string>) {
      state.brosname = action.payload;
    },
    setSubject(state, action: PayloadAction<string>) {
      state.subject = action.payload;
    },
    setRole(state, action: PayloadAction<USER_ROLE>) {
      state.role = action.payload;
    },
    setUserId(state, action: PayloadAction<string>) {
      state.userId = action.payload;
    },
    setSchoolName(state, action: PayloadAction<string>) {
      state.schoolName = action.payload;
    },
    setStep(state, action: PayloadAction<number>) {
      state.step = action.payload;
    },
    setForgotPasswordStep(state, action: PayloadAction<number>) {
      state.forgotPasswordStep = action.payload;
    },
    setLoginStep(state, action: PayloadAction<number>) {
      state.loginStep = action.payload;
    },
    setTempPassword(state, action: PayloadAction<string>) {
      state.tempPassword = action.payload;
    },
    setTempCode(state, action: PayloadAction<number>) {
      state.tempCode = action.payload;
    },
    authFailure(state, action: PayloadAction<string | null>) {
      state.loading = false;
      state.error = action.payload ? action.payload : 'some error';
    },
    authComplete(state) {
      state.loading = false;
    },
  },
});

export const {
  authStart,
  setLongLoading,
  setIsAuthenticated,
  setEmail,
  setName,
  setSubject,
  setRole,
  setUserId,
  setSchoolName,
  setStep,
  setForgotPasswordStep,
  setLoginStep,
  setTempPassword,
  setTempCode,
  authFailure,
  authComplete,
  setGraphQlUser,
  setBrowName
} = authSlice.actions;

export default authSlice.reducer;

//register user
export const registerUserWithGraphQl =
  (userInfo: any, id: string, role: string): AppThunk =>
  async (dispatch) => {
    try {
      // dispatch(authStart());
      //  * Queries- when you recieve information like get request
      // * Mutations- when you add or update info like post/put/delete request
      // const name = store.getState().student.student.name;
      let user;
      if (role === '0') {
        user = await PROGRAM_MANAGER_REQUESTS.getProgramManagerById(id);
      } else if (role === '1') {
        user = await COUNSELOR_REQUESTS.getCounselorById(id);
      } else if (role === '3') {
        user = await PARENT_REQUESTS.getParentById(id);
      }
      // const student = await STUDENT_REQUESTS.getStudentBydId(studentId);
      const createdUser = await API.graphql(
        graphqlOperation(createUser, {
          input: {
            id: id,
            email: userInfo.attributes.email,
            name: user?.name,
            username: userInfo.attributes.email,
            imageUrl:
              user?.imageUrl && user?.imageUrl !== 'no-url'
                ? user?.imageUrl
                : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png',
          },
        })
      );
      // @ts-ignore
      dispatch(loginWithGraphQl(userInfo.attributes.email, id));
      // dispatch(authComplete());
    } catch (err: any) {
      console.log(err);
      dispatch(authFailure(err.message));
      // dispatch(authFailure(err));
    }
  };

export const register =
  (
    name: string,
    email: string,
    password: string,
    role: USER_ROLE,
    schoolName: string,
    history: History
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(authStart());
      let address = 'abc';
      let userRole = '0';

      if (role === USER_ROLE.PROGRAM_MANAGER) {
        userRole = '0';
      } else if (role === USER_ROLE.COUNSELOR) {
        userRole = '1';
      } else if (role === USER_ROLE.PARENT) {
        userRole = '3';
      }
      else if (role === USER_ROLE.SUPER_ADMIN) {
        userRole = '4';
      }
      let text = email;
      email = text.toLowerCase();

      console.log("chk email-->"+email);
      let {brosname } = store.getState().auth;
      let brosdevicename = 'Daily Active Users';
      const { user, userSub } = await Auth.signUp({
        username: email,
        password,
        attributes: {
          given_name: name,
          family_name: name,
          address,
          email,
          'custom:schoolName': schoolName,
          'custom:userRole': userRole,
        },
      });
      console.log("user result---->",user);
      console.log("userSub result---->",userSub);
      dispatch(setUserId(userSub));
      dispatch(getSigninLogById(userSub,brosname,brosdevicename));

      dispatch(setEmail(email));
      dispatch(setName(name));
      dispatch(setSchoolName(schoolName));
      dispatch(setRole(role));
      dispatch(setTempPassword(password));
      //send mail request
      console.warn(role);
      let otpcode=Math.floor(100000 + Math.random() * 900000);
      if (role === USER_ROLE.SUPER_ADMIN) {
        //console.warn("userid"+user+", name"+name+", email"+email+", "+schoolName);
        //dispatch(createSuperAdmin(user, name, email, schoolName,password));
        let subject="Verification Code (Super Admin)";
        dispatch(setSubject(subject));
        dispatch(sendMailToParent(name, email, password, subject,otpcode));

      } 
      else if (role === USER_ROLE.PROGRAM_MANAGER) {
       // console.warn("userid"+userId+", name"+name+", email"+email+", "+schoolName);
       // dispatch(createProgramManager(userId, name, email, schoolName));
       let subject="Verification Code (Program Manager)";
       dispatch(setSubject(subject));
       dispatch(sendMailToParent(name, email, password, subject,otpcode));

      } else if (role === USER_ROLE.COUNSELOR) {
        //dispatch(createCounselor(userId, name, email, schoolName));
        let subject="Verification Code (Couselor Admin)";
        dispatch(setSubject(subject));
        dispatch(sendMailToParent(name, email, password, subject,otpcode));

      } else if (role === USER_ROLE.PARENT) {
        let subject="Verification Code (Parent Admin)";
        dispatch(setSubject(subject));
        dispatch(sendMailToParent(name, email, password, subject,otpcode));
      }

      dispatch(setStep(1));
      dispatch(authComplete());
    } catch (err: any) {
      console.log(err);
      dispatch(authFailure(err.message));
      history.push(REGISTER);
      dispatch(setErrorMsg(err.message));
    }
  };
  export const registerOrganisation =
  (
    name: string,
    email: string,
    password: string,
    role: USER_ROLE,
    schoolName: string,
    history: History
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(authStart());
      let address = 'abc';
      let userRole = '0';

      if (role === USER_ROLE.PROGRAM_MANAGER) {
        userRole = '0';
      } else if (role === USER_ROLE.COUNSELOR) {
        userRole = '1';
      } else if (role === USER_ROLE.PARENT) {
        userRole = '3';
      }
      else if (role === USER_ROLE.SUPER_ADMIN) {
        userRole = '4';
      }
      
      const { user, userSub } = await Auth.signUp({
        username: email,
        password,
        attributes: {
          given_name: name,
          family_name: name,
          address,
          email,
          'custom:schoolName': schoolName,
          'custom:userRole': userRole,
        },
      });
      console.log("user result---->"+user);
      dispatch(setUserId(userSub));
      dispatch(setEmail(email));
      dispatch(setName(name));
      dispatch(setSchoolName(schoolName));
      dispatch(setRole(role));
      dispatch(setTempPassword(password));
      //send mail request
      console.warn(role);
      let otpcode=Math.floor(100000 + Math.random() * 900000);

      if (role === USER_ROLE.SUPER_ADMIN) {
        //console.warn("userid"+user+", name"+name+", email"+email+", "+schoolName);
        //dispatch(createSuperAdmin(user, name, email, schoolName,password));
        let subject="Verification Code (Super Admin)";
        dispatch(setSubject(subject));
        dispatch(sendMailToParent(name, email, password, subject,otpcode));

      } 
      else if (role === USER_ROLE.PROGRAM_MANAGER) {
       // console.warn("userid"+userId+", name"+name+", email"+email+", "+schoolName);
       // dispatch(createProgramManager(userId, name, email, schoolName));
       let subject="Verification Code (Program Manager)";
       dispatch(setSubject(subject));
       dispatch(sendMailToParent(name, email, password, subject,otpcode));

      } else if (role === USER_ROLE.COUNSELOR) {
        //dispatch(createCounselor(userId, name, email, schoolName));
        let subject="Verification Code (Couselor Admin)";
        dispatch(setSubject(subject));
        dispatch(sendMailToParent(name, email, password, subject,otpcode));

      } else if (role === USER_ROLE.PARENT) {
        let subject="Verification Code (Parent Admin)";
        dispatch(setSubject(subject));
        dispatch(sendMailToParent(name, email, password, subject,otpcode));
      }

      dispatch(setStep(1));
      dispatch(authComplete());
    } catch (err: any) {
      console.log(err);
      dispatch(authFailure(err.message));
      history.push(REGISTER);
      dispatch(setErrorMsg(err.message));
    }
  };
export const confirmUser =
  (code: string, history: History): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(authStart());
      let { userId, email, schoolName, role, name, tempPassword, brosname } = store.getState().auth;
      //const data = await Auth.confirmSignUp(email, code);
      let brosdevicename='Daily Active Users';
      console.warn(role);
      if (role === USER_ROLE.SUPER_ADMIN) {
        console.warn("userid"+userId+", name"+name+", email"+email+", "+schoolName);
        dispatch(createSuperAdmin(userId, name, email, schoolName,tempPassword));
      } 
      else if (role === USER_ROLE.PROGRAM_MANAGER) {
        console.warn("userid"+userId+", name"+name+", email"+email+", "+schoolName);
        dispatch(createProgramManager(userId, name, email, schoolName));
      } else if (role === USER_ROLE.COUNSELOR) {
        dispatch(createCounselor(userId, name, email, schoolName));
      } else if (role === USER_ROLE.PARENT) {
        console.warn("userid"+userId+", name"+name+", email"+email+", "+schoolName);

        dispatch(createParent(userId, name, email, tempPassword, brosname,brosdevicename));
      }
      dispatch(setSuccessMsg('Registered Successfully!'));
      dispatch(setStep(0));
      history.push(LOGIN);

      dispatch(authComplete());
    } catch (err: any) {
      dispatch(authFailure(err.message));
      dispatch(setErrorMsg(err.message));
    }
  };

// export const register =
//   (
//     name: string,
//     email: string,
//     password: string,
//     role: USER_ROLE,
//     schoolName: string,
//     history: History
//   ): AppThunk =>
//   async (dispatch) => {
//     try {
//       dispatch(authStart());
//       let address = 'abc';
//       let userRole = '0';

//       if (role === USER_ROLE.PROGRAM_MANAGER) {
//         userRole = '0';
//       } else if (role === USER_ROLE.COUNSELOR) {
//         userRole = '1';
//       } else if (role === USER_ROLE.PARENT) {
//         userRole = '3';
//       }
//       // let { userId, email, schoolName, role, name } = store.getState().auth;
//       // const data = await Auth.confirmSignUp(email, code);
//      // console.log(data);
//       if (role === USER_ROLE.PROGRAM_MANAGER) {
//         //dispatch(createProgramManager(name, email, schoolName, password));
//         const program_manager = await PROGRAM_MANAGER_REQUESTS.createProgramManager(
//           name,
//           email,
//           schoolName,
//           password,
          
//         );
//       } else if (role === USER_ROLE.COUNSELOR) {
//        // dispatch(createCounselor(name, email, schoolName, password));
//         const counseler_manager = await COUNSELOR_REQUESTS.createCounselor(
//           name,
//           email,
//           schoolName,
//           password,
          
//         );
//       } else if (role === USER_ROLE.PARENT) {
//        // dispatch(createParent(name, email,password));
//         const parent_manager = await PARENT_REQUESTS.createParent(
//           name,
//           email,
//           password,
          
//         );
//       }
//       dispatch(setSuccessMsg('Registered Successfully!'));
//       dispatch(setStep(0));
//       history.push(LOGIN);

//       dispatch(authComplete());
//     } catch (err: any) {
//       console.log(err);
//       dispatch(authFailure(err.message));
//       history.push(REGISTER);
//       dispatch(setErrorMsg(err.message));
//     }
//   };

// export const confirmUser =
//   (code: string, history: History): AppThunk =>
//   async (dispatch) => {
//     try {
//       dispatch(authStart());
//       let { userId, email, schoolName, role, name } = store.getState().auth;
//       const data = await Auth.confirmSignUp(email, code);
//       console.log(data);
//       if (role === USER_ROLE.PROGRAM_MANAGER) {
//         dispatch(createProgramManager(userId, name, email, schoolName));
//       } else if (role === USER_ROLE.COUNSELOR) {
//         dispatch(createCounselor(userId, name, email, schoolName));
//       } else if (role === USER_ROLE.PARENT) {
//         dispatch(createParent(userId, name, email));
//       }
//       dispatch(setSuccessMsg('Registered Successfully!'));
//       dispatch(setStep(0));
//       history.push(LOGIN);

//       dispatch(authComplete());
//     } catch (err: any) {
//       dispatch(authFailure(err.message));
//       dispatch(setErrorMsg(err.message));
//     }
//   };


export const resendConfirmationCode = (): AppThunk => async (dispatch) => {
  try {
    dispatch(authStart());
    let { email } = store.getState().auth;
    await Auth.resendSignUp(email);
    console.log('code resent successfully');
    dispatch(setSuccessMsg(`Code resent to ${email}`));
    dispatch(authComplete());
  } catch (err: any) {
    console.log('error resending code: ', err);
    dispatch(authFailure(err.message));
    dispatch(setErrorMsg(err.message));
  }
};

export const login =
  (email: string, password: string, history: History): AppThunk =>
  async (dispatch) => {
    try {
            dispatch(authStart());
            dispatch(setParent(null));
            dispatch(setStudent(null));
            dispatch(setStudents({ data: [] }));
            dispatch(setCounselor(null));
            dispatch(setProgramManger(null));
            dispatch(setProgram(null));
            dispatch(setChatRoom(null));
            dispatch(setUser(null));
            dispatch(setChatRoomUser(null));
            dispatch(setChatRoomUsers(null));
            dispatch(setGroupUsers(null)); 
  
            dispatch(setLongLoading(true));
          
            let text = email;
            email = text.toLowerCase();
            console.log("chkemail--->"+email)
            let user = await Auth.signIn(email, password);
            let {brosname } = store.getState().auth;
            let brosdevicename = 'Daily Active Users';
            if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
              dispatch(setLoginStep(1));
              dispatch(setEmail(email));
              dispatch(setTempPassword(password));
            } else {
              // other situations
              const data = await Auth.currentSession();
              let accessToken = data.getAccessToken();
              let jwt = accessToken.getJwtToken();
              await setAuthToken(jwt);
  
              let { attributes, username } = await Auth.currentUserInfo();
              console.log("attribute user role--->"+attributes['custom:userRole'])
              if (attributes['custom:userRole'] === '4') {
                dispatch(setRole(USER_ROLE.SUPER_ADMIN));
                localStorage.setItem('user_id', username);
                localStorage.setItem('userEmail', email);
                localStorage.setItem('userRole', '4');
                await dispatch(getSuperAdminById(username,  history));
                await dispatch(getSuperAdminLogById(username,brosname, brosdevicename, history));
                await dispatch(getProgramForSuperAdmin(username, history));
                await dispatch(loginWithGraphQl(email, username, '4'));
                await dispatch(setSuccessMsg('Logged in as Super Admin'));
                await dispatch(setIsAuthenticated(true));
                history.push(SUPER_ADMIN_DASHBOARD); 

              }
              else if (attributes['custom:userRole'] === '0') {
                const orgData = await PROGRAM_MANAGER_REQUESTS.getDeclineStatus(email,brosname);
                console.log("status-->"+JSON.stringify(orgData));
                if(orgData && orgData==="Active")
                {
                        dispatch(setRole(USER_ROLE.PROGRAM_MANAGER));
                        localStorage.setItem('user_id', username);
                        localStorage.setItem('userRole', '0');
                        localStorage.setItem('userEmail', email);
                        await dispatch(getProgramManagerById(username));
                        await dispatch(getProgramManagerLogById(username,brosname,brosdevicename));
                        await dispatch(getProgramForProgramManager(username, history));
                        await dispatch(loginWithGraphQl(email, username, '0'));
                        await dispatch(setSuccessMsg('Logged in as Program Manager'));
                        await dispatch(setIsAuthenticated(true));
                        history.push(PROGRAM_MANAGER_DASHBOARD);

                  }
                  else if(orgData && orgData==="Not Approve")
                  {
          
                    dispatch(setErrorMsg('Your account is not Apporved by admin!'));
          
          
                  }
                  else
                  {
          
                    dispatch(setErrorMsg('Program Manager '+orgData+' By Super Admin !'));
          
          
                  }

              } else if (attributes['custom:userRole'] === '1') {
                const orgData = await PROGRAM_MANAGER_REQUESTS.getChkActiveStatus(email);
                console.log("status-->"+JSON.stringify(orgData));

                //let strstatus=JSON.parse(orgData);
                if(orgData && orgData.status==="Active")
                {
                        dispatch(setRole(USER_ROLE.COUNSELOR));
                        await dispatch(getCounselorById(username));
                        await dispatch(getCounselorLogById(username,brosname,brosdevicename));
                        await dispatch(getProgramForCounselor(username, history));
                        await dispatch(loginWithGraphQl(email, username, '1'));
                        localStorage.setItem('user_id', username);
                        localStorage.setItem('userRole', '1');
                        localStorage.setItem('userEmail', email);
                        await  dispatch(setSuccessMsg('Logged in as Admin'));
                        await dispatch(setIsAuthenticated(true));
                        history.push(COUNSELOR_DASHBOARD);
                }
                else if(orgData && orgData.status==="Inactive")
                {
          
                    dispatch(setErrorMsg('Your account is Inactivated or Deleted by admin!'));
          
          
                }
                else
                {
        
                  dispatch(setErrorMsg('Incorrent username or password !'));
        
        
                }

              } else if (attributes['custom:userRole'] === '3') {
                //parent
                
                const orgData = await PROGRAM_MANAGER_REQUESTS.getChkActiveStatus(email);
                console.log("status-->"+JSON.stringify(orgData));
                //let strstatus=JSON.parse(orgData);
                if(orgData && orgData.status==="Active")
                {
                      await dispatch(setRole(USER_ROLE.PARENT));
                      await dispatch(getParentById(username));
                      await dispatch(getChildForParent(username, history));
                      await dispatch(getParentLogById(username,brosname,brosdevicename));

                      // let chkuser=1;
                      // if(chkuser===1)
                      // {
                      //   userGuiding.previewGuide(91683)
                      // }
                        localStorage.setItem('user_id', username);
                        localStorage.setItem('userRole', '3');
                        localStorage.setItem('userEmail', email);
                        await dispatch(loginWithGraphQl(email, username, '3'));
                        await dispatch(setSuccessMsg('Logged in as Parent'));
                      
                        await  dispatch(setIsAuthenticated(true)); 
                        history.push(PARENT_DASHBOARD);
                }
                else if(orgData && orgData.status==="Inactive")
                {   
          
                    dispatch(setErrorMsg('Your account is Inactivated or Deleted by admin!'));
          
          
                }
                else
                {
        
                  dispatch(setErrorMsg('Incorrent username or password !'));
        
        
                }
              } else {
                dispatch(
                  setErrorMsg(
                    "User can't login via the web portal. Use the NFlyte app instead."
                  )
                );
              }
            }
            localStorage.setItem('isAuth', "true");
            dispatch(authComplete());

    } catch (err: any) {
      if(err.message==="User is not confirmed.")
      {
        // let usernxt = await Auth.signIn(email, password);
        // console.log("chk err-->"+JSON.stringify(user))
        dispatch(setEmail(email));
        //dispatch(getUserData(email,password));
        let parent = await PARENT_REQUESTS.getUserData(email, password);
        let prjn:any;
        if(parent)
        {
          console.log("chk-->"+JSON.stringify(parent))
          prjn=parent
        }
        //if(userData)
        console.log("chk------->"+JSON.stringify(prjn.UserAttributes[6].Value))
        let name=prjn.UserAttributes[4].Value;
        let userSub=prjn.UserAttributes[0].Value;
        let schoolName="";
        let role=parseInt(prjn.UserAttributes[3].Value);

        dispatch(setUserId(userSub));
        dispatch(setEmail(email));
        dispatch(setName(name));
        dispatch(setSchoolName(schoolName));
        dispatch(setRole(role));
        dispatch(setTempPassword(password));
        let subject="Verification Code (Parent Admin)";
        dispatch(setSubject(subject));

        let otpcode=Math.floor(100000 + Math.random() * 900000);
        dispatch(sendMailToParent(name, email, password, subject,otpcode));
        dispatch(setLongLoading(false));
        dispatch(setStep(1))
        history.push(REGISTER);
        dispatch(authFailure(err.message));
        dispatch(setErrorMsg(err.message));
      }
      else
      {
        dispatch(setLongLoading(false));
        history.push(LOGIN);
        dispatch(authFailure(err.message));
        dispatch(setErrorMsg(err.message));
      }
    }
  };

  export const login_check =
  (email: string, password: string, history: History): AppThunk =>
  async (dispatch) => {
    try {
    

      dispatch(setLongLoading(true));
      let user = await Auth.signIn(email, password);

      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        dispatch(setLoginStep(1));
        dispatch(setEmail(email));
        dispatch(setTempPassword(password));
      } else {
        // other situations
        const data = await Auth.currentSession();
        let accessToken = data.getAccessToken();
        let jwt = accessToken.getJwtToken();

        let { attributes, username } = await Auth.currentUserInfo();
        console.log("attribute user role--->"+attributes['custom:userRole'])
       
      }
      dispatch(authComplete());
    } catch (err: any) {
      dispatch(setLongLoading(false));
      history.push(LOGIN);
      dispatch(authFailure(err.message));
      dispatch(setErrorMsg(err.message));
    }
  };

export const loginWithGraphQl =
  (email: string, username: string, role: string): AppThunk =>
  async (dispatch) => {
    try {
      // console.log(email, 'HITTING');

      //  get the user from backend with the user SUB(id) from Auth
      const user = await API.graphql(
        graphqlOperation(getUser, {
          // id: username,
          email: email,
          // phone: userInfo.attributes.phone_number,
        })
      );
      // @ts-expect-error
      const { data } = user;

      if (data.getUser) {
        // @ts-ignore
        // console.log('LOADINF>>>', data.getUser);
        dispatch(setGraphQlUser({ data: data.getUser }));
        await localStorage.setItem('graphQlUser', JSON.stringify(data.getUser));
        await localStorage.setItem('userRole', role);
        await localStorage.setItem('userEmail', email);
        //await localStorage.setItem('password', password);
        await localStorage.setItem('user_id', username);
        // dispatch(setToken(userInfo.signInUserSession.accessToken.jwtToken));

        // dispatch(authComplete());
        console.log('User is already registered in database!');

        return;
      } else {
        // @ts-ignore
        console.log('User is not registered in database!');
        // if there is no user in DB then create one
        const userInfo = await Auth.currentUserInfo();
        // console.log(userInfo, 'USERINFO');
        dispatch(registerUserWithGraphQl(userInfo, username, role));
      }
    } catch (err: any) {
      dispatch(authFailure(err.message));
      dispatch(setErrorMsg(err.message));
    }
  };

export const updateDetailsForGraphQlUser =
  (
    id: string,
    email: string,
    name: string,
    imageUrl: string,
    username: string,
    about: string
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(authStart());
      const updatedUser = await API.graphql(
        graphqlOperation(updateUser, {
          input: {
            id: id,
            email: email,
            name: name,
            imageUrl: imageUrl,
            username: username,
            about: about,
          },
        })
      );
      dispatch(loadUserForGraphQl(email));
      dispatch(authComplete());
    } catch (err: any) {
      dispatch(authFailure(err.message));
      dispatch(setErrorMsg(err.message));
    }
  };

export const loadUserForGraphQl =
  (email: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(authStart());
      const user = await API.graphql(
        graphqlOperation(getUser, {
          // id: userInfo.id,
          email: email,
          //phone: userInfo.phone,
        })
      );
      // @ts-ignore
      const { data } = user;
      // @ts-ignore
      if (data.getUser) {
        // @ts-ignore
        dispatch(setGraphQlUser({ data: data.getUser }));
        await localStorage.setItem('graphQlUser', JSON.stringify(data.getUser));
       
      }
      dispatch(authComplete());
    } catch (err: any) {
      dispatch(authFailure(err.message));
      dispatch(setErrorMsg(err.message));
      console.log(err);
    }
  };
export const logout =
  (history: History): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(authStart());
     // await Auth.signOut();
     // dispatch(setIsAuthenticated(true));
      //localStorage.clear();
     // await setAuthToken();
      //dispatch(setTeacher(null));
      //dispatch(setStudent(null));
    //  history.push(LOGIN);
      dispatch(authComplete());
    } catch (err: any) {
      dispatch(authFailure(err.message));
      dispatch(setErrorMsg(err.message));
    }
  };
 
  export const logout_next =
  (history: History): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(authStart());
      await Auth.signOut();
      dispatch(setIsAuthenticated(false));
      const userg = localStorage.getItem('userg');
      let program_id="";
      const { program } = store.getState().program;
      const { parent } = store.getState().parent;
      const { counselor } = store.getState().counselor;
      const { programManager } = store.getState().programManager;

      const { superAdmin } = store.getState().superAdmin;

      const authlocal = localStorage.getItem('userRole');
      const user_id = localStorage.getItem('user_id');
      console.log("Login user Id===>",user_id);
      let {brosname } = store.getState().auth;
      let brosdevicename = 'Daily Active Users';
      if(user_id)
      {
        
        program_id=user_id;
      }
     //console.warn("program Id==>",program_id);
      const logoutlogData =  await dispatch(getLogoutLogById(program_id,brosname,brosdevicename));
      console.warn("logout Logs Data",logoutlogData);
      localStorage.clear();
      if(userg)
      localStorage.setItem('userg',userg);
      await setAuthToken();
      dispatch(setParent(null));
      dispatch(setStudent(null));
      dispatch(setStudents({ data: [] }));
      dispatch(setCounselor(null));
      dispatch(setProgramManger(null));
      dispatch(setProgram(null));
      dispatch(setChatRoom(null));
      dispatch(setUser(null));
      dispatch(setChatRoomUser(null));
      dispatch(setChatRoomUsers(null));
      dispatch(setGroupUsers(null));
      history.push(LOGIN);
      dispatch(authComplete());
    } catch (err: any) {
      dispatch(authFailure(err.message));
      dispatch(setErrorMsg(err.message));
    }
  };

export const forgotPassword =
  (email: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(authStart());
      //const data = await Auth.forgotPassword(email);
      let otpcode=Math.floor(100000 + Math.random() * 900000);
      let subject="Verification Code (Forgot Password)";
      console.log(subject);

      dispatch(setSubject(subject));
      dispatch(sendMailToParent("", email, "", subject, otpcode));
      dispatch(setEmail(email));
      dispatch(setSuccessMsg(`Code sent to your email!`));
      dispatch(authComplete());
      dispatch(setForgotPasswordStep(1));
    } catch (err: any) {
      dispatch(authFailure(err.message));
      dispatch(setErrorMsg(err.message));
    }
  };

export const forgotPasswordSubmit =
  (code: string, newPassword: string, history: History): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(authStart());
      const { email } = store.getState().auth;
      //await Auth.forgotPasswordSubmit(email, code, newPassword);
      let check="forgot";
      // let {brosname } = store.getState().auth;
      // let brosdevicename = 'Daily Active Users';
      const updateData = dispatch(updateNewPassword(email, newPassword,check));
      console.warn("Update password data ====>",updateData);
      //dispatch(updatepasswordLogEntry(brosname,brosdevicename))
      dispatch(setSuccessMsg(`Updated password`));
      history.push(LOGIN);
      dispatch(authComplete());
    } catch (err: any) {
      dispatch(authFailure(err.message));
      dispatch(setErrorMsg(err.message));
    }
  };

export const updatePassword =
  (newPassword: string, history: History): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(authStart());
      let { email, tempPassword } = store.getState().auth;
      // let user = await Auth.signIn(email, tempPassword);
      // if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
      //   user = Auth.completeNewPassword(
      //     user, // the Cognito User Object
      //     newPassword // the new password
      //   );
      // }
      let check="update_password";

      dispatch(updateNewPassword(email, newPassword,check));


      dispatch(setSuccessMsg(`Updated password`));
      dispatch(setEmail(''));
      dispatch(setTempPassword(''));
      dispatch(setLoginStep(0));
      history.push(LOGIN);
      dispatch(authComplete());
    } catch (err: any) {
      dispatch(authFailure(err.message));
      dispatch(setErrorMsg(err.message));
    }
  };

  export const getCheckAuthProgramManager =
  (email: string,userId: string,token:string, history: History,chkauth:string): AppThunk =>
  async (dispatch) => {
    
      dispatch(setIsAuthenticated(true));
      setAuthToken(token);
      dispatch(setRole(USER_ROLE.PROGRAM_MANAGER));
      dispatch(getProgramManagerById(userId));
      dispatch(getProgramForProgramManager(userId, history));
   
  };

  export const getCheckAuthParent =
  (email: string,userId: string,token:string, history: History,chkauth:string): AppThunk =>
  async (dispatch) => {
    // if(chkauth==="no")
    // {
      setAuthToken(token);
      dispatch(setRole(USER_ROLE.PARENT));
      dispatch(getParentById(userId));
      dispatch(getChildForParent(userId, history));
      dispatch(setIsAuthenticated(true));
    //}
  };

  export const getCheckAuthCounselor =
  (email: string,userId: string,token:string, history: History,chkauth:string): AppThunk =>
  async (dispatch) => {
    // if(chkauth==="no")
    // {
      setAuthToken(token);
      dispatch(setRole(USER_ROLE.COUNSELOR));
      dispatch(getCounselorById(userId));
      //dispatch(getProgramForCounselor(userId, history));
      dispatch(setIsAuthenticated(true));
    //}
  };

  export const getSigninLogById =
  (student_id: string, brosname:string,brosdevicename:string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(authStart());
      const parentdata = await REQUESTS.getSigninLogById(student_id,brosname,brosdevicename);
      console.warn("Parent sign up logs entry====>",parentdata)
      dispatch(authComplete());
    } catch (error: any) {
      if (error.response) {
        const { data, status } = error.response;
        dispatch(authFailure(data));
      }
    }
  };

  export const getsuperadminSignin =
  (student_id: string, brosname:string,brosdevicename:string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(authStart());
      const superdata = await SUPER_REQUESTS.getsuperadminSignin(student_id,brosname,brosdevicename);
      console.warn("Super Admin sign up logs entry====>",superdata)
      dispatch(authComplete());
    } catch (error: any) {
      if (error.response) {
        const { data, status } = error.response;
        dispatch(authFailure(data));
      }
    }
  };
  export const getLogoutLogById =
  (program_id: string, brosname:string, brosdevicename:string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(authStart());
      //let program_id="";
      const { program } = store.getState().program;
      const { parent } = store.getState().parent;
      const { counselor } = store.getState().counselor;
      const { programManager } = store.getState().programManager;
      const { superAdmin } = store.getState().superAdmin;
      const authlocal = localStorage.getItem('userRole');
      if(superAdmin && authlocal=="4")
      {
        program_id=superAdmin.id;
        //console.warn("super admin program Id==>",program_id);
        
        const data = await REQUESTS.getSuperadminLogoutLog(program_id,brosname,brosdevicename);
        console.warn("logout data",data);
      }
      if(parent && authlocal=="3")
        {
          program_id=parent.id;
          const data = await PARENT_REQUESTS.getParentLogoutLog(program_id,brosname,brosdevicename);
          console.warn("logout data",data);
        }
        if(counselor && authlocal=="1")
        {
          program_id=counselor.id;
          const data = await COUNSELOR_REQUESTS.getCounselorLogoutLog(program_id,brosname,brosdevicename);
          console.warn("logout data",data);
        }
        if(programManager && authlocal=="0")
        {
          program_id=programManager.id;
          const data = await PROGRAM_MANAGER_REQUESTS.getProgramMangerLogoutLog(program_id,brosname,brosdevicename);
          console.warn("logout data",data);
        }

    dispatch(authComplete());
    } catch (error: any) {
      if (error.response) {
        const { data, status } = error.response;
        dispatch(authFailure(data));
      }
    }
  };
import {
  PROGRAM_ROUTE,
  STUDENT_ROUTE,
  COUNSELOR_ROUTE,
} from './../constants/routes';
import { TASK_ROUTE } from '../constants/routes';
import { Task } from './../constants/models/Task';
import API from './api';
import { SubTaskItem } from '../slices/taskSlice';
import moment from 'moment';
import { DateTime } from 'luxon';


export async function getTaskById(id: string) {
  try {
    const res = await API.get<{ data: Task; success: boolean }>(
      `${TASK_ROUTE}/${id}/getTaskById`
    );
    return res.data.data;
    //return res.notiTime.notiTime;
  } catch (err) {
    throw err;
  }
}

export async function getTasksForStudent(studentId: string) {
  try {
    const res = await API.get<{ data: Task[]; success: boolean }>(
      `${STUDENT_ROUTE}/${studentId}${TASK_ROUTE}`
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function getStudentTasksForDate(
  id: string,
  date: Date,
  timeZone: string
) {
  try {
    const res: any = await API.get(`${STUDENT_ROUTE}/${id}/tasks-date-web`, {
      params: {
        date: date,
        timeZone: timeZone,
      },
    });
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function addTaskImageToS3(imageBinary: string) {
  try {
    const res = await API.post<{ data: string; success: boolean }>(
      `${TASK_ROUTE}/add-image`,
      {
        imageBinary,
      }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function createTask(
  name: string,
  imageUrl: string,
  taskLocation: string,
  start: Date,
  end: Date,
  isSpecial: boolean,
  isRecurring: boolean,
  instructions: string[],
  days: number[],
  taskTypeId: string,
  recipeId: string,
  counselorId: string,
  studentIds: string[],
) {
  try {
    const res = await API.post<{ data: Task[]; success: boolean }>(
      `${TASK_ROUTE}`,
      {
        name,
        imageUrl,
        taskLocation,
        start,
        end,
        isSpecial,
        isRecurring,
        instructions,
        days,
        taskTypeId,
        recipeId,
        counselorId,
        studentIds,
      } 
    );
    return res.data.success;
  } catch (err) {
    throw err;
  }
}

export async function createTaskWithSubTaskImages(
  name: string,
  imageUrl: string,
  taskLocation: string,
  start: Date,
  end: Date,
  isSpecial: boolean,
  isRecurring: boolean,
  subTasks: SubTaskItem[],
  days: number[],
  taskTypeId: string,
  recipeId: string,
  counselorId: string,
  studentIds: string[],
  notiTime: string[],
  program_de_id: string,
  brosname: string,
  brosdevicename:string,
) {
  try {
  
    //const start_new = DateTime.fromISO(start.toISOString());////moment(start).toLocaleString();

    //const start_new = (start.toISOString());////moment(start).toLocaleString();
    //const isStartDST = startDateTime.isInDST();
    let startTime = new Date(start);
    startTime.setDate(start.getDate());
    startTime.setFullYear(start.getFullYear());
    startTime.setMonth(start.getMonth());
    console.log("start time",startTime.toISOString());

    let endTime = new Date(end);
    endTime.setDate(end.getDate());
    endTime.setFullYear(end.getFullYear());
    endTime.setMonth(end.getMonth());
    console.log(endTime.toISOString());

    //const end_new =  DateTime.fromISO(end.toISOString());////moment(end).toLocaleString();//
    //const end_new =  (end.toISOString());////moment(end).toLocaleString();//
    //const isEndDST = endDateTime.isInDST();


    const now = DateTime.now();
    // Get the current time zone with consideration for daylight saving time
    const timeZone = now.zoneName;
    console.log("in front timeZone => ",timeZone);
    const res = await API.post<{ data: Task[]; success: boolean }>(
      `${TASK_ROUTE}/with-images`,
      {
        name,
        imageUrl,
        taskLocation,
        start:startTime,
        end:endTime,
        isSpecial,
        isRecurring,
        subTasks,
        days,
        taskTypeId,
        recipeId,
        counselorId,
        studentIds,
        notiTime,
        programId:program_de_id,
        brosname,
        brosdevicename,
		timeZone
      }
    );
    return res.data.success;
  } catch (err) {
    throw err;
  }
}

export async function updateTask(
  id: string,
  name: string,
  imageUrl: string,
  taskLocation: string,
  start: Date,
  end: Date,
  isSpecial: boolean,
  isRecurring: boolean,
  subTasks: SubTaskItem[],
  notiTime: string[],
  days: number[],
  taskTypeId: string,
  recipeId: string,
  studentId: string,
  program_id: string,
  brosname: string,
  brosdevicename:string,
) {
  try {

    const res = await API.post<{ data: Task; success: boolean }>(
      `${TASK_ROUTE}/${id}/updateTask`,
      {
        name,
        imageUrl,
        taskLocation,
        start,   
        end,
        isSpecial,
        isRecurring,
        subTasks,
        notiTime,
        days,
        taskTypeId,
        recipeId,
        studentId,
        programId:program_id,
        brosname,
        brosdevicename,
      }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function deleteTask(id: string,programId:string,brosname:string,brosdevicename:string) {
  try {
    const res = await API.post<{ data: {}; success: boolean }>(
      `${TASK_ROUTE}/${id}/deleteTask`,
      {
        from_userid:programId,
        brosname:brosname,
        brosdevicename:brosdevicename
      }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function completeTask(id: string, completedImageUrl: string) {
  try {
    const res = await API.post<{ data: Task; success: boolean }>(
      `${TASK_ROUTE}/${id}/complete`
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

import React, { useEffect } from 'react';
import { Grid, Container, CssBaseline, makeStyles } from '@material-ui/core';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../app/rootReducer';
import { ADD_STUDENT } from '../../../../constants/routes';
import { getStudentById, setStudent } from '../../../../slices/studentSlice';
import EnhancedEditProfileProgramForm from './EnhancedEditProfileProgramForm';
import {
  PROGRAM_MANAGER,
  COUNSELOR,
  STUDENT,
  USER_ROLE,
} from '../../../../constants/userRoles';
import { getCounselorByIdRefresh } from '../../../../slices/counselorSlice';
import { getProgramManagerByIdRefresh } from '../../../../slices/programManagerSlice';
import { getSuperAdminByIdRefresh } from '../../../../slices/superAdminSlice';

interface IEditProfileProgramProps {}

const EditProfileProgram: React.FC<IEditProfileProgramProps> = ({}) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const {
    counselorLoading,
    parentLoading,
    programManagerLoading,
    role,
    diagnoses,
    counselors,
    programManager,
    counselor,
    superAdmin
  } = useSelector((state: RootState) => {
    return {
      programManagerLoading: state.programManager.loading,
      counselorLoading: state.counselor.loading,
      parentLoading: state.parent.loading,
      diagnoses: state.diagnosis.diagnoses,
      counselors: state.counselor.counselors,
      role: state.auth.role,
      programManager:state.programManager.programManager,
      superAdmin:state.superAdmin.superAdmin,
      counselor: state.counselor.counselor,

    };
  }, shallowEqual);
  const user_id = localStorage.getItem('user_id');
  const userRole = localStorage.getItem('userRole');

  useEffect(() => {
    dispatch(setStudent(null))
    async function fetchMyAPI() {
      if(programManager)
      {
        localStorage.setItem('userData', JSON.stringify(programManager));

      }
      else
      {
        localStorage.setItem('userData', JSON.stringify(counselor));

      }
        if(user_id)
        {
            if (userRole === "4") {
              //dispatch(getSuperAdminByIdRefresh(user_id));
            } 
            else if (userRole === "0") {
              dispatch(getProgramManagerByIdRefresh(user_id));

            } else if (userRole === "1") {
              dispatch(getCounselorByIdRefresh(user_id));
            
            } else if (userRole === "3") {
            
            }
           
        }
}
  
fetchMyAPI()
  return () => {};
}, []);

  let renderedForm;
  if (programManager) {
    const {
      name,
      email,
      schoolName,
      referenceCode,
      address,
      street,
      city,
      country,
      postalCode,
      phoneno,
      countryCode,
      about,
    } = programManager;
    renderedForm = (
      <EnhancedEditProfileProgramForm
        name={name}
        email={email}
        address={address}
        street={street}
        city={city}
        country={country}
        postalCode={postalCode}
        phoneno={phoneno}
      countryCode={countryCode}
      />
    );
  } else {
  renderedForm = <EnhancedEditProfileProgramForm />;
   }

  return (
    <div>
      <Container
        style={{
          paddingLeft: '0.4em',
          paddingRight: '0.4em',
          height: '80vh',
        }}
      >
        {renderedForm}
      </Container>
    </div>
  );
};
export default EditProfileProgram;

import React, { useEffect } from 'react';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../app/rootReducer';
import { getStudentsByOrganisation, getStudentsForParent, getStudentsForProgram, getStudentsForProgramCounselorRefresh, getStudentsForProgramRefresh } from '../../../slices/studentSlice';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import { addStudentId } from '../../../slices/taskSlice';
import store, { AppThunk } from '../../../app/store';
import { USER_ROLE } from '../../../constants/userRoles';


import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  IconButton,
} from '@mui/material';
import { getChildForParentRefresh } from '../../../slices/parentSlice';
import { useHistory, useParams } from 'react-router';

interface IAddStudentsProps {}

export const AddStudentsToTask: React.FC<IAddStudentsProps> = ({}) => {
  const dispatch = useDispatch();
 

  const { students, studentIds, role } = useSelector((state: RootState) => {
    return {
      students: state.student.students,
      studentIds: state.task.studentIds,
      role: state.auth.role,
    };
  }, shallowEqual);

  // useEffect(() => {
  //   // CHANGE FOR COUNSELORS
  //   dispatch(getStudentsForProgram());
  //   return () => {};
  // }, [students.length]);
  const { program } = store.getState().program;
  const { parent } = store.getState().parent;
  const { counselor } = store.getState().counselor;
  const { programManager } = store.getState().programManager;
  const user_id = localStorage.getItem('user_id');
  const history = useHistory();
  const { studentId } = useParams<{ studentId: string | undefined }>();
  const authlocal = localStorage.getItem('userRole');


  useEffect(() => {
    if(role===USER_ROLE.PARENT)
    {
      if(user_id && authlocal=="0")
      {
        //dispatch(getStudentsForProgramRefresh(user_id));
        dispatch(getStudentsByOrganisation(user_id));
        //dispatch(getStudentsForProgramRefresh(user_id));
      }
      else if(user_id && authlocal=="1")
      {
        dispatch(getStudentsForProgramCounselorRefresh(user_id));
      }
    }
    else
    {
      if(user_id && authlocal=="0")
      {
        dispatch(getStudentsByOrganisation(user_id));
      }
      else if(user_id && authlocal=="1")
      {
        dispatch(getStudentsForProgramCounselorRefresh(user_id));
      }
      else if (user_id) 
      {
        dispatch(getStudentsForProgram());
      }
    } 
    if(studentId)
    {
      dispatch(addStudentId(studentId));

    }  
    return () => {};
  }, []);
  const [value, setValue] = React.useState<string>('');

  let renderedStudents: { label: string; value: string; imageUrl: string }[] =
    [];
  if (students.length > 0) {
    renderedStudents = students
      .filter((student) => {
        return !studentIds.includes(student.id);
      })
      .map((student) => {
        return {
          label: student.name,
          value: student.id,
          imageUrl: student.imageUrl,
        };
      });
  } else {
    renderedStudents = [];
  }

  return (
    <Autocomplete
      freeSolo
      autoComplete
      id=''
      placeholder='Jane Doe'
      disableClearable
      options={renderedStudents}
      onChange={(e, option) => {
        if (typeof option !== 'string') {
          dispatch(addStudentId(option.value));
          setValue(option.value);
        }
      }}
      value={value}
      renderInput={(params) => (
        <TextField {...params} label='' variant='outlined' size='small' />
      )}
      getOptionLabel={(option) =>
        typeof option !== 'string' && option.label ? option.label : ''
      }
    />
  );
};

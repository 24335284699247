import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import AsyncSelect from 'react-select/async';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import {
  Grid,
  Container,
  CssBaseline,
  TextField,
  makeStyles,
  Typography,
  FormControl,
  IconButton,
  Button,
  Switch,
  InputAdornment,
} from '@material-ui/core';
import formStyles from '../../../assets/jss/components/formStyles';
import { FormikProps } from 'formik';
import { EnhancedEditTaskDetailsFormValues } from './EnhancedEditTaskDetailsForm';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../app/rootReducer';
import { getTaskTypesForProgram } from '../../../slices/taskTypeSlice';
import { getRecipesAll } from '../../../slices/recipeSlice';

import { DisplayFormikState } from '../../../components/DisplayFormikState';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {
  addInstruction,
  removeInstruction,
  setFileName,
  setImageBinary,
  setAddedAttachment,
  toggleIsSpecial,
  addImageToS3,
  addSubTask,
  setImageUrl,
  resetTaskState,
  setName,
  setDays,
  setEnd,
  setStart,
  setTaskTypeId,
  setTaskLocation,
  setTaskId,
  setIsRecurring,
  setIsSpecial,
  setSubTasks,
  setDaysRadio,
  setNotiTime,
  setRecipeId,
  getTaskById,
  setTasks,
  setInstString,
  setIsEdit
} from '../../../slices/taskSlice';
import { DropzoneDialog } from 'material-ui-dropzone';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { DisplayInstructions } from './DisplayInstructions';
import store, { AppThunk } from '../../../app/store';
import { getStudentsForParent, getStudentsForProgram } from '../../../slices/studentSlice';
import { addRecipeToS3 } from '../../../api/recipeRequests';
import Resizer from "react-image-file-resizer";
import  SIMG  from '../../../assets/images/sampleimg.png'; 


const useStyles = makeStyles(formStyles);

interface IEditTaskDetailsFormProps {}

export const EditTaskDetailsForm: React.FC<
  IEditTaskDetailsFormProps & FormikProps<EnhancedEditTaskDetailsFormValues>
> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  const [instruction, setInstruction] = useState<string>('');
  let debug = false;

  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleBlur,
    handleChange,
    setFieldValue,
  } = props;

  const {
    taskLoading,
    taskTypes,
    attachment,
    imageUrl,
    fileName,
    isRecurring,
    isSpecial,
    instructions,
    recipes,
    subTasks,
    instruction_index,
    inst_string,
    isedit
  } = useSelector((state: RootState) => {
    return {
      taskLoading: state.task.loading,
      taskTypes: state.taskType.taskTypes,
      attachment: state.task.attachment,
      fileName: state.task.fileName,
      imageUrl: state.task.imageUrl,
      isRecurring: state.task.isRecurring,
      isSpecial: state.task.isSpecial,
      instructions: state.task.instructions,
      recipes: state.recipe.recipes,
      subTasks: state.task.subTasks,
      instruction_index: state.task.instruction_index,
      inst_string: state.task.inst_string,
      isedit: state.task.isedit,

    };
  }, shallowEqual);


  const { program } = store.getState().program;
  const { parent } = store.getState().parent;
  const { counselor } = store.getState().counselor;
  const { programManager } = store.getState().programManager;

  useEffect(() => {
    // CHANGE FOR COUNSELORS
    if(parent?.id)
    {
      dispatch(getStudentsForParent());
    }
    else
    {
      dispatch(getStudentsForProgram());
    }
    // dispatch(
    //   setTasks({
    //     data: [],
    //   })
    // );
    return () => {};
  }, []);//students.length, studentIds.length
  // useEffect(() => {
  //   dispatch(getTaskTypesForProgram());
  //   return () => {};
  // }, []);//taskTypes.length

  // useEffect(() => {
  //   dispatch(getRecipesAll());
  //   return () => {};
  // }, []);//recipes.length

  // Custom sorting function
  const customSort = (a: any, b: any) => {
    if (a.name.toLowerCase() === 'other') {
      return 1; // Move 'other' to the end
    } else if (b.name.toLowerCase() === 'other') {
      return -1; // Move 'other' to the end
    } else {
      return a.name.localeCompare(b.name);
    }
  };
  // Sort the array
  const sortedArray = [...taskTypes].sort(customSort);

  let renderedTaskTypes = sortedArray.map((taskType) => {
    return {
      value: taskType.id,
      label: taskType.name,
    };
  });
  const [loading, setLoading] = useState<boolean>(false);

  const userData = localStorage.getItem('userData');
  let arrchk=[1,2,3];
    useEffect(() => {
      if(userData)
      {
       const userDt = JSON.parse(userData);
       setLoading(true);
       dispatch(setName(userDt.name));
       dispatch(setTaskTypeId(userDt.taskType.id));
       dispatch(setStart(userDt.start));
       dispatch(setEnd(userDt.end));
       dispatch(setTaskLocation(userDt.taskLocation));
       dispatch(setTaskId(userDt.id));
       dispatch(setRecipeId(userDt.recipeId));
       dispatch(setIsSpecial(userDt.isSpecial));
       dispatch(setImageUrl(userDt.imageUrl));
       if(userDt.subTasks)
       {
         dispatch(setSubTasks({ data: userDt.subTasks }));
 
       }
       dispatch(setNotiTime({ data: userDt.notiTime }));
       console.log('****');
       console.log(userDt.days);
       console.log('****');
 
       dispatch(setDays({ data: userDt.days.map((i:any) => Number(i)) }));
       dispatch(setIsRecurring(userDt.isRecurring));
       if (userDt.days.length === 7) {
         dispatch(setDaysRadio('1'));
       } else if (userDt.days.length === 1) {
         dispatch(setDaysRadio('2'));
       } else {
         dispatch(setDaysRadio('3'));
       }
 
       setLoading(false);
       //loadDataOnlyOnce(userData);
         // if(studentLoading)
         // {
              //  setFieldValue('name',userDt.name?userDt.name:'')
              //  setFieldValue('taskType',userDt.taskType.id?userDt.taskType.id:'')
              //  setFieldValue('recipeDescription',userDt.recipeDescription?userDt.recipeDescription:'')
              //  setFieldValue('TypeOfRecipe',userDt.TypeOfRecipe?userDt.TypeOfRecipe:'')
              //  setFieldValue('tags',userDt.tags?userDt.tags:'')
              //  setFieldValue('preparationTime',userDt.preparationTime?userDt.preparationTime:'')
              
        // }
      }
       
     }, [arrchk.length]) 

  const customStyles = {
    control: (base: any, state: any) => ({
      ...base,
      background: '#FAFAFA',
      // match with the menu
      borderRadius: '8px',
      padding: '1.5px 1.5px',

      borderColor: state.isFocused ? '#0092BC' : '#C0C0C0',

      // Overwrittes the different states of border
      '&:hover': {
        // Overwrittes the different states of border
        borderColor: state.isFocused ? '#0092BC' : '#C0C0C0',
      },
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
    }),
    menu: (base: any) => ({
      ...base,
      background: '#FAFAFA',
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0,
    }),
    menuList: (base: any) => ({
      ...base,
      background: '#FAFAFA',
      // kill the white space on first and last option
    }),
  };
  function getImageUrl(imguri:any)
  {
    let arr=imguri.split(",");
    let mime=arr[0].match(/:(.*?);/)[1];
    let data=arr[1];

    let dataStr=atob(data);
    let n=dataStr.length;
    let dataArr=new Uint8Array(n);
    while(n--)
    {
      dataArr[n]=dataStr.charCodeAt(n);
    }
    let file = new File([dataArr],'file.jpg',{type:mime});
    //console.log(file);
    return file;
  }
  function editinst(e:any,name:string,completedImageUrl:string)
{
 // console.log(instruction_index)
  if(subTasks.length>0 && isedit==='yes')
  {
    //alert(subTasks[instruction_index]);
    let instogj=JSON.stringify(subTasks[instruction_index]);
    if(instogj)
    {
      let ogjnxt=JSON.parse(instogj)
      console.log(ogjnxt.name)
      let txtnm={
        name: name,
        completedImageUrl: 'no-url',
      }
      
     //create a copy of summer fruits.
      const summerFruitsCopy = [...subTasks];

      //find index of item to be replaced
      const targetIndex = subTasks.findIndex(f=>f.name===ogjnxt.name); 

      //replace the object with a new one.
      summerFruitsCopy[targetIndex] = txtnm;
      dispatch(setSubTasks({data:summerFruitsCopy}))
      console.log("final-->"+JSON.stringify(summerFruitsCopy)); 
      dispatch(setInstString(''))
      dispatch(setIsEdit(''))

     
    }
  }
  else
  {
        dispatch(
        addSubTask({
          name: inst_string,
          completedImageUrl: 'no-url',
        })
      );


      dispatch(setInstString(''));
  }
  
  

}
  return (
    <div style={{scrollBehavior:'smooth' }}>
      <div style={{ margin: '0px 0px 12px' }}>
        <Typography variant='h4' className={classes.welcomeBackText}>
          <em>Edit Task</em>
        </Typography>
      </div>
      <form onSubmit={handleSubmit}>
        <FormControl required className={classes.formControl}>
          <Typography variant='subtitle1' className={classes.label}>
            Task Name{' '}
          </Typography>
          <TextField
            placeholder='Eg. Brush your teeth'
            name='name'
            type='text'
            variant='outlined'
            size='small'
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
            error={(errors.name && touched.name) as boolean}
            helperText={errors.name && touched.name && errors.name}
            InputLabelProps={{
              classes: {
                root: classes.heading,
                focused: classes.cssFocused,
              },
            }}
          />
        </FormControl>
        <FormControl required className={classes.formControl}>
          <Typography variant='subtitle1' className={classes.label}>
            Task Type
          </Typography>
          <AsyncSelect
            cacheOptions
            // @ts-ignore
            defaultOptions={renderedTaskTypes}
            // @ts-ignore
            // options={renderedTaskTypes}
            styles={customStyles}
            // value={values.taskTypeId}
            value={renderedTaskTypes.find(
              (taskType) => taskType.value === values.taskTypeId
            )}
            // defaultInputValue={values.taskTypeId}
            //value={ta}
            onChange={(option) => {
              if (option) {
                // @ts-ignore
                setFieldValue('taskTypeId', option.value);
              }
            }}
          />
        </FormControl>
        <Typography variant='subtitle1' className={classes.label1}>
          Critical Task
        </Typography>
        <Typography variant='subtitle2'>
          <em>Is this is a critical task?</em>
          <Switch
            checked={isSpecial}
            onChange={() => {
              dispatch(toggleIsSpecial());
            }}
            color='primary'
            name=''
          />
        </Typography>
        <Typography variant='subtitle1' className={classes.label1}>
          Attachment
        </Typography>
            <br/>
        <Typography variant='subtitle2'>
          {/* <em>Show how the task is done?</em>
          {!attachment ? (
            <Button
              startIcon={<PhotoCamera />}
              color='primary'
              component='span'
              size='large'
              onClick={() => {
                setOpen(true);
              }}
              disabled={attachment}
            >
              Upload
            </Button>
          ) : (
            <>
              <strong>&nbsp;{fileName}</strong>
            </>
          )} */}
            <div className='row'>
              <div className='col-sm-2'>

                <IconButton onClick={() => setOpen(true)}>
                {/* <Avatar
                  src={renderedAvatar}
                  alt={renderedAltName}
                  style={{ width: 100, height: 100 }}
                /> */}
                 {imageUrl? 
                        <img src={imageUrl} style={{ width: 56, height: 56 }}/>:
                        <img src={SIMG} style={{ width: 56, height: 56 }}/>
                      }
              </IconButton>

            </div>
            <div className='col-sm-10'>
            <em>Show how the task is done?</em><br/>
            <p style={{fontSize:'12px',color:'gray'}}>Image file should be .jpg and .png only and Size should be less than 5MB.</p>

            </div>
           
          </div><br/>
        </Typography>
        <DropzoneDialog
          acceptedFiles={['image/jpeg,image/png,image/jpg']}
          cancelButtonText={'Cancel'}
          submitButtonText={'Submit'}
          maxFileSize={5242880}
          filesLimit={1}
          open={open}
          onClose={() => setOpen(false)}
          onSave={(files) => {
            if (files.length > 0) {
              let file = files[0];
              Resizer.imageFileResizer(
                file,
                680,
                420,
                "JPEG",
                100,
                0,
                async(uri:any) => {
                  //console.log(uri);
                  console.warn("Form data view--->" + JSON.stringify(uri));
                 let fdata= getImageUrl(uri);
                 //setImg(fdata);
                 dispatch(setFileName(file.name));
                 const formData = new FormData();
                 formData.append('file', fdata);
                 //dispatch(addRecipeToS3(formData));
                 let urldatanxt = await addRecipeToS3(formData);
                 dispatch(setImageUrl(urldatanxt));
                 dispatch(setAddedAttachment(true));

                 // setImage(url);
                 // url.push(urldata);
                 //console.log("res data---->"+urldatanxt)
                 //let jurl=JSON.stringify(urldatanxt)

                },
                "base64",
                200,
                200
              );
              // dispatch(setFileName(file.name));
              // const formData = new FormData();
              // formData.append('file', file);
              // dispatch(addImageToS3(formData));

              // let reader = new FileReader();
              // reader.readAsDataURL(file);
              // reader.onloadend = () => {
              //   let base64string = reader.result as string;
              //   dispatch(setImageBinary(base64string));
              //   dispatch(setAddedAttachment(true));
              // };
            }
            setOpen(false);
          }}
          showPreviews={true}
          showFileNamesInPreview={true}
        />
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <Grid item md={12}>
            <FormControl
              required
              style={{ width: '100%', paddingRight: '0.4em' }}
            >
              <Typography variant='subtitle1' className={classes.label}>
                Instructions
              </Typography>
              <Grid container={true}>
                <TextField
                  type='text'
                  size='small'
                  placeholder='Eg. Plug the vacuum cleaner'
                  name='name'
                  variant='outlined'
                  value={inst_string}
                  onChange={(e) => {
                    //setInstruction(e.target.value);
                    dispatch(setInstString(e.target.value));
                  }}
                  style={{ width: '100%' }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          edge='end'
                          color='primary'
                          onClick={(e) => {
                            let instname=inst_string;
                            editinst(e,instname,'no-url')
                          }}
                          disabled={inst_string === ''}
                        >
                          <AddCircleOutlineIcon color='primary' />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      if (instruction !== '') {
                        //dispatch(addInstruction(instruction));
                        dispatch(
                          addSubTask({
                            name: instruction,
                            completedImageUrl: '',
                          })
                        );
                        setInstruction('');
                      }
                    }
                  }}
                  InputLabelProps={{
                    classes: {
                      root: classes.heading,
                      focused: classes.cssFocused,
                    },
                  }}
                  helperText={'Press enter to add an instruction'}
                />
              </Grid>
            </FormControl>
          </Grid>
        </Grid>
        <DisplayInstructions /> 
        <Grid
          container
          direction='row-reverse'
          justifyContent='flex-start'
          alignItems='center'
        >
          <Grid item md={3} xs={12}>
            <Button
              className={classes.secondaryButton}
              variant='contained'
              color='primary'
              type='submit'
              disabled={
                taskLoading || values.taskTypeId === '' || values.name === ''
              }
              // fullWidth
            >
              Next
            </Button>
          </Grid>
        </Grid>
        {debug ? <DisplayFormikState {...props} /> : ''}

      </form>
      <br/><br/><br/><br/><br/><br/>

    </div>
  );
};

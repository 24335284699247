import { withFormik } from 'formik';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { History } from 'history';
import { AppThunk } from '../../../app/store';
import {
  ABOUT_REQUIRED,
  ADDRESS_REQUIRED,
  CITY_REQUIRED,
  COUNTRY_REQUIRED,
  EMAIL_INVALID,
  EMAIL_NOT_LONG_ENOUGH,
  EMAIL_REQUIRED,
  NAME_REQUIRED,
  POSTAL_CODE_REQUIRED,
  REFERENCE_CODE_REQUIRED,
  SCHOOL_NAME_REQUIRED,
  STREET_REQUIRED,
} from '../../../constants/formMessages';
import { EditSuperAdminProfileForm } from './EditSuperAdminProfileForm';
import { updateProgramManager } from '../../../slices/programManagerSlice';
import { updateSuperAdmin } from '../../../slices/superAdminSlice';
import { updateCounselor } from '../../../slices/counselorSlice';
import { updateParent } from '../../../slices/parentSlice';
import { User } from '../../../constants/models/User';
import { RootState } from '../../../app/rootReducer';
import { USER_ROLE } from '../../../constants/userRoles';

interface IDispatchProps {
  updateSuperAdmin: (
    id: string | undefined,
    name: string,
    email: string,
    address: string,
    street: string,
    city: string,
    state: string,
    country: string,
    postalCode: number,
    // about: string,
    imageUrl: string | undefined,
    phoneno: string,
    countryCode: string,
    // diagnoses: string[],
    // counselors: string[],
    history: History
  ) => AppThunk;
  updateParent: (
    id: string | undefined,
    name: string,
    email: string,
    address: string,
    street: string,
    city: string,
    state: string,
    country: string,
    postalCode: number,
    phoneno: string,
    countryCode: string,
    // about: string,
    imageUrl: string | undefined,
    // diagnoses: string[],
    // counselors: string[],
    history: History
  ) => AppThunk;
  updateCounselor: (
    id: string | undefined,
    name: string,
    email: string,
    address: string,
    street: string,
    city: string,
    state: string,
    country: string,
    postalCode: number,
    phoneno: string,
    countryCode: string,
    // about: string,
    imageUrl: string | undefined,
    // diagnoses: string[],
    // counselors: string[],
    history: History,
    useProgramRoute: boolean
  ) => AppThunk;
}

export interface EnhancedEditSuperAdminProfileFormValues {
  name: string;
  email: string;
  address: string;
  street: string;
  city: string;
  state: string;
  country: string;
  postalCode: number;
  imageUrl: string;
  phoneno: string;
  countryCode: string;
  // diagnoses: string[];
  // counselors: string[];
}

export interface EnhancedEditSuperAdminProfileFormProps extends RouteComponentProps {
  name?: string;
  email?: string;
  address?: string;
  street?: string;
  city?: string;
  state?: string;
  country?: string;
  postalCode?: number;
  phoneno?: string;
  countryCode?: string;
  imageUrl?: string;
  superAdmin: User;
  programManager: User;
  counselor: User;
  parent: User;
  role: number;
  updateSuperAdmin: (
    id: string | undefined,
    name: string,
    email: string,
    address: string,
    street: string,
    city: string,
    state: string,
    country: string,
    postalCode: number,
    phoneno: string,
    countryCode: string,
    // about: string,
    imageUrl: string | undefined,
    // diagnoses: string[],
    // counselors: string[],
    history: History
  ) => AppThunk;
  updateCounselor: (
    id: string | undefined,
    name: string,
    email: string,
    address: string,
    street: string,
    city: string,
    state: string,
    country: string,
    postalCode: number,
    phoneno: string,
    countryCode: string,
    // about: string,
    imageUrl: string | undefined,
    // diagnoses: string[],
    // counselors: string[],
    history: History,
    useProgramRoute: boolean
  ) => AppThunk;
  updateParent: (
    id: string | undefined,
    name: string,
    email: string,
    address: string,
    street: string,
    city: string,
    state: string,
    country: string,
    postalCode: number,
    phoneno: string,
    countryCode: string,
    // about: string,
    imageUrl: string | undefined,
    // diagnoses: string[],
    // counselors: string[],
    history: History
  ) => AppThunk;
}

const EnhancedEditSuperAdminProfileForm = withFormik<
  EnhancedEditSuperAdminProfileFormProps,
  EnhancedEditSuperAdminProfileFormValues
>({
  mapPropsToValues: (props) => {
    let role = props.role;
    let user = props.programManager;
    if (role === USER_ROLE.SUPER_ADMIN) {
      user = props.superAdmin;
    } else if (role === USER_ROLE.PROGRAM_MANAGER) {
      user = props.programManager;
    } else if (role === USER_ROLE.COUNSELOR) {
      user = props.counselor;
    } else if (role === USER_ROLE.PARENT) {
      user = props.parent;
    }

    return {
      name: user ? user.name : '',
      email: user ? user.email : '',
      address: user ? user.address : '',
      street: user ? user.street : '',
      city: user ? user.city : '',
      state: user ? user.state : '',
      country: user ? user.country : '',
      phoneno: user ? user.phoneno : '',
      countryCode: user ? user.countryCode : '',
      postalCode: user ? user.postalCode : 0,
      imageUrl: user ? user.imageUrl : 'no-url',
      // diagnoses: [],
      // counselors: [],
    };
  },
  validationSchema: Yup.object().shape({
    name: Yup.string().required(NAME_REQUIRED),
    email: Yup.string()
      .min(3, EMAIL_NOT_LONG_ENOUGH)
      .max(255)
      .email(EMAIL_INVALID)
      .required(EMAIL_REQUIRED),
    address: Yup.string().notRequired(),
    street: Yup.string().notRequired(),
    city: Yup.string().notRequired(),
    country: Yup.string().notRequired(),
    postalCode: Yup.number().notRequired(),
    phoneno: Yup.number().notRequired(),
    countryCode: Yup.number().notRequired(),
    // about: Yup.string().notRequired(),
    imageUrl: Yup.string().notRequired(),
    // diagnoses: Yup.array().of(Yup.string()),
    // counselors: Yup.array().of(Yup.string()),
  }),
  handleSubmit: (values, { setSubmitting, props }) => {
    const {
      history,
      updateSuperAdmin,
      updateCounselor,
      updateParent,
      superAdmin,
      programManager,
      counselor,
      parent,
      role,
    } = props;
    const {
      name,
      email,
      address,
      street,
      city,
      state,
      country,
      postalCode,
      phoneno,
      countryCode,
      imageUrl,
      // diagnoses,
      // counselors,
    } = values;
    if (role === USER_ROLE.SUPER_ADMIN) {
      updateSuperAdmin(
        superAdmin?.id,
        name,
        email,
        address,
        street,
        city,
        state,
        country,
        postalCode,
        phoneno,
        countryCode,
        // about,
        imageUrl,
        // diagnoses,
        // counselors,
        history
      );
    } else if (role === USER_ROLE.PROGRAM_MANAGER) {
      updateProgramManager(
        programManager?.id,
        name,
        email,
        address,
        street,
        city,
        state,
        country,
        postalCode,
        phoneno,
        countryCode,
        // about,
        imageUrl,
        // diagnoses,
        // counselors,
        history
      );
    } else if (role === USER_ROLE.COUNSELOR) {
      updateCounselor(
        counselor?.id,
        name,
        email,
        address,
        street,
        city,
        state,
        country,
        postalCode,
        phoneno,
        countryCode,
        // about,
        imageUrl,
        // diagnoses,
        // counselors,
        history,
        false
      );
    } else if (role === USER_ROLE.PARENT) {
      updateParent(
        parent?.id,
        name,
        email,
        address,
        street,
        city,
        state,
        country,
        postalCode,
        phoneno,
        countryCode,
        // about,
        imageUrl,
        // diagnoses,
        // counselors,
        history
      );
    }
    setSubmitting(false);
  },
  displayName: 'BasicForm',
})(EditSuperAdminProfileForm);

const EnhancedEditSuperAdminProfileFormWithRouter = withRouter(EnhancedEditSuperAdminProfileForm);

const mapStateToProps = (state: RootState) => ({
  programManager: state.programManager.programManager,
  superAdmin: state.superAdmin.superAdmin,
  counselor: state.counselor.counselor,
  parent: state.parent.parent,
  role: state.auth.role,
});

export default connect(mapStateToProps, {
  updateSuperAdmin,
  updateCounselor,
  updateParent,
  // @ts-ignore
})(EnhancedEditSuperAdminProfileFormWithRouter);

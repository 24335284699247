import React, { useEffect, useState } from 'react';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
  IconButton,
  Grid,
  TextField,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from '../../../app/rootReducer';
import { StyledTableCell } from '../../../components/StyledTableCell';
import { StyledTableRow } from '../../../components/StyledTableRow';
import {
  ADD_COUNSELOR,
  ADD_ORGANISATION,
  EDIT_COUNSELOR_PROFILE,
  EDIT_PARENTS_COUNSELOR_PROFILE,
  VIEW_ORGANISATION_DETAILS,
} from '../../../constants/routes';
import {
  getCounselorById,
  getCounselorsForOrganisationProgramRefresh,
  getCounselorsForProgram,
  getCounselorsForProgramRefresh,
  getCounselorsForProgramRefreshParent,
  setCounselor,
} from '../../../slices/counselorSlice';
import AddIcon from '@mui/icons-material/Add';

import formStyles from '../../../assets/jss/components/formStyles';
import EditIcon from '@mui/icons-material/Edit';
import { approvedOrganisation, declineOrganisation, getOrganisationList, getSearchOrganisationById, getSearchOrganisationByName, getSearchOrganisationBySuperAdmin, getSearchOrganisationBySuperAdminorList } from '../../../api/programManagerRequests';
import { TiTick } from 'react-icons/ti';
import { CiSearch } from 'react-icons/ci';
import { MdClear } from 'react-icons/md';
import { AiOutlineEye } from 'react-icons/ai';
import { getProgramManagerByIdRefresh } from '../../../slices/programManagerSlice';
import { Autocomplete } from '@mui/material';
import ReactGA from 'react-ga';



interface IEnquiryListProps {}

const useStyles = makeStyles(formStyles);

const EnquiryList: React.FC<IEnquiryListProps> = ({}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();

  const { counselors } = useSelector((state: RootState) => {
    return {
      counselors: state.counselor.counselors,
    };
  }, shallowEqual);


  localStorage.setItem('urlRoute', "parent_admins");

  const authlocal = localStorage.getItem('userRole');
  const user_id = localStorage.getItem('user_id');
  const [orgarr,setorgarr]=useState([]);
  const [orgname,setorgname]=useState("");
  const [orgarr_nxt,setorgarrNxt]=useState([]);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);

    async function fetchMyAPI() {
    if(user_id)
    {
      const orgData = await getOrganisationList();
      if(orgData)
      {
        setorgarr(orgData)
      }
    }
  }
  fetchMyAPI()
    return () => {};
  }, []);

  async function getApproveOrganisation(e:any,cnt:any,id:any)
  {
    //console.log("chk id-->"+id)
    let status=e.target.value;
    if(status==="Approved")
    {
      setPageLoading(true)
      const orgData_nxt = await approvedOrganisation(id);
      if(orgData_nxt)
      {
        const orgData = await getOrganisationList();
        if(orgData)
        {

          setorgarr(orgData)
          setPageLoading(false)

        }
  
      }
    }
    else  if(status==="Decline")
    {
      setPageLoading(true)

      const orgData_nxt = await declineOrganisation(id);
      if(orgData_nxt)
      {
        const orgData = await getOrganisationList();
        if(orgData)
        {

          setorgarr(orgData)
          setPageLoading(false)

        }
  
      }
    }
    else{

    }
    
  }

  const [orgSelect,setorgSelect]=useState("");
  const [pageLoding,setPageLoading]=useState(false);
  async function getSearchOrganisation(e:any,nm:string)
  {
    //alert(nm)
        setorgSelect(nm)
      let orgnm=nm;
        const orgData = await getSearchOrganisationById(orgnm);
        if(orgData)
        {
          setorgarr(orgData)
          ReactGA.event({
            category:'Organization list',
            action:'Show organization',
            label:'Super admin' 
          });
        }
  
      
  }

  async function getDeclineOrganisation(id:any)
  {
    //console.log("chk id-->"+id)

    const orgData_nxt = await declineOrganisation(id);
      if(orgData_nxt)
      {
        const orgData = await getOrganisationList();
        if(orgData)
        {
          setorgarr(orgData)
        }
  
      }
  }
console.log("org--->"+JSON.stringify(orgarr))
  let renderedCounselors;
  if (orgarr.length > 0) {
    renderedCounselors = orgarr.map((counselor, index) => {
      const { name,
        email, 
        phoneno,
        programname: programname, 
        tellus,
        is_program,
        status,
        id } = counselor;
      return (
        <StyledTableRow key={index} style={{width:'100%',fontSize:'11px'}}>
          <StyledTableCell component='th' scope='row' style={{width:'5%'}}>
            {index + 1}
          </StyledTableCell>
          {/* <StyledTableCell>{referenceCode}</StyledTableCell> */}
          <StyledTableCell style={{width:'10%'}}>{name}</StyledTableCell>
          <StyledTableCell style={{width:'10%'}}>{email}</StyledTableCell>
          <StyledTableCell style={{width:'10%'}}>{phoneno}</StyledTableCell>
          <StyledTableCell style={{width:'10%'}}>{programname}</StyledTableCell>
          <StyledTableCell style={{width:'30%'}}>{tellus}</StyledTableCell>
          <StyledTableCell style={{width:'10%'}}>{
              status==="Approved"?<span style={{color:'green'}}>Approved </span>:status==="Decline"?<span style={{color:'red'}}>Decline </span>:<span style={{color:'navy'}}> Pending</span>
          }</StyledTableCell>
          {/* <StyledTableCell> */}
            {
              // status==="Approved"?<button className='btn btn-xs btn-danger' style={{height:"30px",width:"100%",fontSize:"12px"}} value={"Decline"}
              // onClick={ () => {
              //   getDeclineOrganisation(id)
              // }}
              // >Decline</button>:
            
              // <button className='btn btn-xs btn-warning' style={{height:"30px",width:"100%",fontSize:"12px"}} value={"Approve"}
              // onClick={ () => {
              //   getApproveOrganisation(id)
              // }}
              // >Approve</button>
            }
             <StyledTableCell style={{width:'10%'}}>
                <select value={status} className='form-control' style={{padding:'10px',width:'120px'}} onChange={(e)=>getApproveOrganisation(e,index,id)}>
                    <option value={"pending"} selected={status==="pending"?true:false}>- Select - </option>
                    <option value={"Approved"} selected={status==="Approved"?true:false}>Approved </option>
                    <option value={"Decline"} selected={status==="Decline"?true:false}>Decline </option>
                  </select>
              </StyledTableCell> 
            {/* <IconButton
              onClick={async () => {
                await dispatch(getCounselorById(id));
                if(authlocal==="3")
                {
                  history.push(EDIT_PARENTS_COUNSELOR_PROFILE(id));
                }
                else
                {
                  history.push(EDIT_COUNSELOR_PROFILE(id));
                }
              }}
            >
              <EditIcon />
            </IconButton> */}
          {/* </StyledTableCell> */}
          <StyledTableCell style={{width:'5%'}}>
                <IconButton
                  onClick={async () => {
                    //await dispatch(getProgramManagerByIdRefresh(id))
                    history.push(VIEW_ORGANISATION_DETAILS(id))
                  }}
                >
                  <AiOutlineEye />
                </IconButton> 
            </StyledTableCell>
        </StyledTableRow>
      );
    });
  } else if (counselors.length === 0) {
    renderedCounselors = (
      <StyledTableRow>
        <StyledTableCell component='th' scope='row'>
          <Typography variant='subtitle2' className={classes.label}>
            No Organization found.
            {/* <Link to={ADD_COUNSELOR} className={classes.link}>
              Add Organization?
            </Link> */}
          </Typography>
        </StyledTableCell>
      </StyledTableRow>
    );
  } else {
    renderedCounselors = (
      <StyledTableRow>
        <StyledTableCell component='th' scope='row'></StyledTableCell>
      </StyledTableRow>
    );
  }
  let renderedStudents: { label: string; value: string; imageUrl: string }[] =[];

  let autoorgdata: any[];
  if(orgarr)
  {
              if (orgarr.length > 0) {
                renderedStudents = orgarr
                  .map((org:any) => {
                    return {
                      label: org.name,
                      value: org.id,
                      imageUrl: "",
                    };
                  });
              } else {
                renderedStudents = [];
              }
  }
  console.log("----->"+renderedStudents)
  // if(pageLoding===false)
  // {
          return (
            <div>
                    <Typography variant='h6'>Organization</Typography>
                    <br />
                    <Grid
                        container
                        direction='row'
                        justifyContent='space-between'
                        alignItems='center'

                      >
                        <Grid item sm={3} xs={2}>
                            <Button
                              variant='contained'
                              color='primary'
                              onClick={() => {
                                history.push(ADD_ORGANISATION);
                              }}
                              startIcon={<AddIcon />}
                            >
                              Add Organization
                            </Button>
                          </Grid>
                          <Grid item sm={1} xs={1}>

                          </Grid>
                          <Grid item sm={3} xs={7} style={{padding:'5px'}}>
                              {/* <input type='text' className='form-control' value={orgname} style={{height:'30px'}} placeholder='Enter Organisation Name' onChange={(e)=>setorgname(e.target.value)}/> */}
                              <Autocomplete
                          freeSolo
                          autoComplete
                          id=''
                          // disableClearable={false}
                          options={renderedStudents}
                          onChange={(e, option:any) => {
                            if (typeof option !== 'string') {
                              getSearchOrganisation(e,option.value)
                            }

                          }}
                          //value={orgSelect}
                          renderInput={(params) => (
                            <TextField {...params} label='' variant='outlined' size='small' placeholder='Select Organization' value={orgSelect}/>
                          )}
                          getOptionLabel={(option) =>
                            typeof option !== 'string' && option.label ? option.label : ''
                          }
                        />
                            </Grid>

                            {/* <Grid item md={1} xs={12} className='' style={{padding:'5px'}}>
                              <Button
                                variant='contained'
                                color='primary'
                                onClick={() => {
                                  getSearchOrganisation()
                                }}
                                startIcon={<CiSearch />}
                              >
                                Search 
                              </Button>
                            </Grid>  */}
                            <Grid item sm={2} xs={2} className='' style={{padding:'5px'}}>
                              <Button
                                variant='contained'
                                color='primary'
                                onClick={async() => { 
                                  window.location.reload();
                                  // setorgname("")
                                  // renderedStudents = [];

                                  // const orgData = await getOrganisationList();
                                  // if(orgData)
                                  // {
                                  //   setorgarr(orgData)
                                  // }
                                }}
                                startIcon={<MdClear />}
                              >
                                Clear 
                              </Button>
                            </Grid> 
                      </Grid>
                    <br />
                    <br />
                    <Grid
                        container
                        direction='row'
                        justifyContent='space-between'
                        alignItems='center'

                      >
                      <Grid item sm={12} xs={12} className='' style={{padding:'5px',overflow:'scroll',overflowX:'scroll',overflowY:'scroll',width:'800px',height:'400px'}}>

                        <TableContainer component={Paper}>
                          <Table aria-label='customized table'>
                            <TableHead style={{textAlign:'center'}}>
                              <TableRow style={{width:'100%'}}>
                                <StyledTableCell style={{width:'5%'}}>S.No.</StyledTableCell>
                                {/* <StyledTableCell>Refrence Code</StyledTableCell> */}
                                <StyledTableCell style={{width:'10%'}}>Name</StyledTableCell>
                                <StyledTableCell style={{width:'10%'}}>Email</StyledTableCell>
                                <StyledTableCell style={{width:'10%'}}>Phone no</StyledTableCell>
                                <StyledTableCell style={{width:'10%'}}>Program Manager</StyledTableCell>
                                <StyledTableCell style={{width:'30%'}}>Message</StyledTableCell>
                                <StyledTableCell style={{width:'10%'}}>Status 
                                </StyledTableCell>
                                <StyledTableCell style={{width:'10%'}}>Actions</StyledTableCell>
                                <StyledTableCell style={{width:'5%'}}>View Organization</StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody style={{}}>{renderedCounselors}</TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    </Grid>
            </div>
          );
  //   }
  //   else
  //   {
  //     return <div style={{position:'absolute',top:'50%',left:'60%',transform:'translate(-50%, -50%)'}}>
  //     <div className="spinner-border text-danger" role="status">
  //         <span className="sr-only">Loading...</span>
  //     </div> &nbsp;&nbsp;&nbsp;<span style={{fontSize:'24px',marginTop:'-10px'}}>Loading...</span>
  // </div>;
  //   }
};

export default EnquiryList;

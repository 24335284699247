import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Link, useLocation } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import Drawer, { DrawerProps } from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import HomeIcon from '@material-ui/icons/Home';
import ContactSupport from '@material-ui/icons/ContactSupport';
import Typography from '@material-ui/core/Typography';
import { Chat } from '@material-ui/icons';

import { Omit } from '@material-ui/types';
import navigatorStyles from '../../assets/jss/components/navigatorStyles';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../app/rootReducer';
import { USER_ROLE } from '../../constants/userRoles';
import {
  COUNSELOR_DASHBOARD,
  PARENT_DASHBOARD,
  PROGRAM_MANAGER_DASHBOARD,
  MESSAGING,
  SUPER_ADMIN_DASHBOARD,
} from '../../constants/routes';
import { programManagerRoutes } from './MenuRoutes/programManagerRoutes';
import { counselorRoutes } from './MenuRoutes/counselorRoutes';
import { parentRoutes } from './MenuRoutes/parentRoutes';
import LocalGroceryStoreOutlinedIcon from '@mui/icons-material/LocalGroceryStoreOutlined';
import { FcSupport } from 'react-icons/fc';
import { superAdminRoutes } from './MenuRoutes/superAdminRoutes';

const useStyles = makeStyles(navigatorStyles);

export interface INavigatorProps extends Omit<DrawerProps, 'classes'> {}

export const Navigator: React.FC<INavigatorProps> = (
  props: INavigatorProps
) => {
  const classes = useStyles();
  const location = useLocation();

  const { user, role, programManager } = useSelector((state: RootState) => {
    return {
      user: state.auth.user,
      role: state.auth.role,
      programManager:state.programManager.programManager
    };
  }, shallowEqual);

  const userRole = localStorage.getItem('userRole');
let urole;
if(role)
{
  urole=role;
}
else
{
  urole=userRole;
}

  let routes;
  let homeLink;
console.log("userdata-->"+JSON.stringify(urole));
  if (role === USER_ROLE.SUPER_ADMIN) {
    homeLink = SUPER_ADMIN_DASHBOARD;
    routes = superAdminRoutes;
  }else if (role === USER_ROLE.PROGRAM_MANAGER) {
    homeLink = PROGRAM_MANAGER_DASHBOARD;
    routes = programManagerRoutes;
  } else if (role === USER_ROLE.COUNSELOR) {
    homeLink = COUNSELOR_DASHBOARD;
    routes = counselorRoutes;
  } else {
    homeLink = PARENT_DASHBOARD;
    routes = parentRoutes;
  }
  function redirectUrl()
  {
    //window.open('https://zfrmz.com/qlbsb83rXm8hWJFf1Lyr','_blank');
    window.open('https://nflyte.com/support-form/','_self');

  }
  return (
    <Drawer variant='permanent' {...props}>
      <List disablePadding>
        <ListItem
          className={clsx(classes.title, classes.item, classes.itemRoute)}
        >
          <img src={''} height={'40px'} />
          <Typography
            variant='h6'
            style={{
              fontFamily: 'Gotham',
              padding: '0.4rem',
            }}
          >
            NFlyte
          </Typography>
        </ListItem>

        <Link to={homeLink} style={{ textDecoration: 'none' }}>
          <ListItem
            className={clsx(
              classes.item,
              classes.itemRoute,
              location.pathname === homeLink && classes.itemActiveItem
            )}
          >
            <ListItemIcon className={classes.itemIcon}>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText
              classes={{
                primary: classes.itemPrimary,
              }}
            >
              Dashboard
            </ListItemText>
          </ListItem>
        </Link>
        {routes.map(({ id, children }) => (
          <React.Fragment key={id}>
            <ListItem className={classes.routeHeader}>
              <ListItemText
                classes={{
                  primary: classes.routeHeaderPrimary,
                }}
              >
                {id}
              </ListItemText>
            </ListItem>
            {children.map(({ id: childId, icon, route }) => (
              <Link key={childId} to={route} style={{ textDecoration: 'none' }}>
                <ListItem
                  button
                  className={clsx(
                    classes.item,
                    location.pathname === route && classes.itemActiveItem
                  )}
                >
                  <ListItemIcon className={classes.itemIcon}>
                    {icon}
                  </ListItemIcon>
                  <ListItemText
                    classes={{
                      primary: classes.itemPrimary,
                    }}
                  >
                    {childId}
                  </ListItemText>
                </ListItem>
              </Link>
            ))}
            
            <Divider className={classes.divider} />
          </React.Fragment>
        ))}
        <React.Fragment >
        <Link   onClick={redirectUrl}  
                   style={{ textDecoration: 'none',color:'white'}} to="">
                <ListItem
                  button
                 
                >
                  <ListItemIcon style={{color:'white'}} className={classes.itemIcon}>
                    <ContactSupport style={{color:'white'}}/>
                  </ListItemIcon>
                  <ListItemText
                    classes={{
                      primary: classes.itemPrimary,
                    }}
                    style={{color:'white'}}
                  >
                    Support
                  </ListItemText>
                </ListItem>
              </Link>
        </React.Fragment>
      </List>
    </Drawer>
  );
};

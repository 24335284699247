import React, { useEffect } from 'react';
import { Grid, Container, CssBaseline, makeStyles } from '@material-ui/core';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../app/rootReducer';
import { ADD_STUDENT } from '../../../constants/routes';
import { getStudentById } from '../../../slices/studentSlice';
import EnhancedAddOrEditStudentBySuperAdminForm from './EnhancedAddOrEditStudentBySuperAdminForm';

interface IAddOrEditStudentBySuperAdminProps {}

const AddOrEditStudentBySuperAdmin: React.FC<IAddOrEditStudentBySuperAdminProps> = ({}) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const { studentId } = useParams<{ studentId: string | undefined }>();
  const { student, studentLoading } = useSelector((state: RootState) => {
    return {
      student: state.student.student,
      studentLoading: state.student.loading,
    };
  }, shallowEqual);
  const isAddStudent = location.pathname === ADD_STUDENT ? true : false;
  useEffect(() => {
    if (!isAddStudent && studentId) {
      dispatch(getStudentById(studentId));
    }
    return () => {};
  }, [isAddStudent, studentId, dispatch]);
  let renderedForm;
  if (!isAddStudent && student) {
    const {
      name,
      email,
      schoolName,
      referenceCode,
      address,
      street,
      city,
      state,
      country,
      postalCode,
      about,
      phoneno,
      countryCode,
      diagnoses,
      studentGroups,
    } = student;
    console.warn("students------------------->"+studentGroups);

    renderedForm = (
      <EnhancedAddOrEditStudentBySuperAdminForm
        // name={name}
        // email={email}
        // address={address}
        // street={street}
        // city={city}
        // state={state}
        // country={country}
        // postalCode={postalCode}
        // about={about}
        // diagnoses={diagnoses}
        // counselors={studentGroups}
      />
    );
  } else {
    renderedForm = <EnhancedAddOrEditStudentBySuperAdminForm />;
  }

  return (
    <div>
      <Container
        style={{
          paddingLeft: '0.4em',
          paddingRight: '0.4em',
          height: '80vh',
        }}
      >
        {renderedForm}
      </Container>
    </div>
  );
};
export default AddOrEditStudentBySuperAdmin;

import React, {useState,useEffect } from 'react';
import { RecipeStep } from '../constants/models/RecipeStep';
import { STEP_ROUTE, RECIPE_ROUTE, IMAGE_ROUTE } from '../constants/routes';
import { useParams } from 'react-router';

import API from './api';
// const [rrecipeId,setRecipeId]=useState("");
// const { recipeId } = useParams<{ recipeId: string | undefined }>();
// let rcheck: string | number | readonly string[] | undefined;
// if(recipeId)
// {
//   rcheck=recipeId;
//   setRecipeId(rcheck);

// }

export async function getrecipeStepById(id: string) {
  try {
    const res = await API.post<{ data: RecipeStep; success: boolean }>(
      `${RECIPE_ROUTE}/${id}/getStep`
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function getrecipeStepsForProgram(programId: string) {
  try {
    console.log("program id-->"+programId);
    const res = await API.get<{ data: RecipeStep[]; success: boolean }>(
      `${RECIPE_ROUTE}/${programId}${RECIPE_ROUTE}`
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function createrecipeStep(
    stepDescription: string,
    stepTitle: string,
    stepUrl: string,
    brosname:string,
    brosdevicename:string,
    programId:string,
    recipeId: string
) {

  try {
    const res = await API.post<{ data: RecipeStep; success: boolean }>(
      `${RECIPE_ROUTE}/create-recipeSteps`,
      {
        stepDescription,
        stepTitle,
        stepUrl,
        brosname,
        brosdevicename,
        programId,
        recipeId
      }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function updaterecipeStep(
  id: string | undefined,
  stepTitle: string,
  stepUrl: string,
  stepDescription: string,
  recipeId: string,
) {
  try {
    const res = await API.post<{ data: RecipeStep; success: boolean }>(
      `${RECIPE_ROUTE}/${id}/updateStep`,
      {
        id,  
        stepTitle,
        stepUrl,
        stepDescription,
        recipeId
      }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}
export async function updateStepPositionFromWeb(
  stepPostision: RecipeStep[],
  programId: string,
) {
  try {
    const res = await API.post<{ data: RecipeStep[]; success: boolean }>(
      `${RECIPE_ROUTE}/updateStepPositionFromWeb`,
      {
        step_position:stepPostision,
        programId: programId
      }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}
export async function deleterecipeStep(id: string,recipeId: string) {
  try {
    const res = await API.post<{ data: {}; success: boolean }>(
      `${RECIPE_ROUTE}/${id}/deleteStep`
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function addrecipeStepToS3(formData: FormData) {
  try {
    console.warn("form data ----->"+JSON.stringify(formData));
    //console.warn("form data program id----->"+JSON.stringify(formData.programId));

    const res = await API.post<{ data: string; success: boolean }>(
      `${IMAGE_ROUTE}/add-image`,
      //
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export const getParentStepList = async (recipeId: string) => {
  try {
    const res = await API.get<{ data: any; success: boolean }>(
      `${RECIPE_ROUTE}/${recipeId}/showSteps`
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
};
import React, { useEffect, useState } from 'react';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { StyledTableCell } from '../../../components/StyledTableCell';
import { StyledTableRow } from '../../../components/StyledTableRow';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../app/rootReducer';
import {
  createQuestion,
  deleteQuestion,
  getQuestionById,
  getQuestionsForProgram,
  getQuestionsForProgramRefresh,
  setIsModalOpen,
} from '../../../slices/questionSlice';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import EnhancedQuestionEditorForm from './EnhancedQuestionEditorForm';
import AddIcon from '@mui/icons-material/Add';
import NoImageIcon from '@mui/icons-material/HideImage';

interface IAllQuestionsViewProps {}

export const AllQuestionsView: React.FC<IAllQuestionsViewProps> = ({}) => {
  const dispatch = useDispatch();
  const { questionObj, questionLoading, questions } = useSelector(
    (state: RootState) => {
      return {
        questions: state.question.questions,
        questionLoading: state.question.loading,
        questionObj: state.question.question,
      };
    },
    shallowEqual
  );

  const [updatestr,setupdatestr]=useState("");
  localStorage.setItem('urlRoute', "questions");
  const authlocal = localStorage.getItem('userRole');
  const user_id = localStorage.getItem('user_id');

  useEffect(() => {
    if(authlocal && user_id)
    {
      dispatch(getQuestionsForProgramRefresh(user_id));

    }
    else
    {
      dispatch(getQuestionsForProgram());

    }
    return () => {};
  }, []);//questions.length

  const handleAddButtonClick = (
    e: React.MouseEventHandler<HTMLButtonElement>
  ) => {};

  let renderedQuestions;
  if (questions.length > 0) {
    renderedQuestions = questions.map((questionObj, index) => {
      const { id, questionText, answer,user } = questionObj;
     // let userimg=user?.imageUrl?user?.imageUrl:"https://nflyte-1.s3.amazonaws.com/ef12f40b-f758-4e9c-ab7c-acebc7e240c6.jpg";
      let userimg=user?.imageUrl==="no-url"?"https://nflyte-1.s3.amazonaws.com/ef12f40b-f758-4e9c-ab7c-acebc7e240c6.jpg":user?.imageUrl;

      return (
        <StyledTableRow key={index}>
          <StyledTableCell component="th" scope="row">
          {userimg !== '' ? (
                              <a href={userimg} target='_blank'>
                                <img
                                  src={userimg}
                                  width="50" height="50"
                                  className="rounded"
                                />
                              </a>
                            ) : (
                              <span>-</span>
                            )}
          </StyledTableCell>
          <StyledTableCell component="th" scope="row">
            {user?.name!== '' ?user?.name:"-"}
          </StyledTableCell>
          <StyledTableCell>{questionText}</StyledTableCell>
          <StyledTableCell>{answer}</StyledTableCell>
          <StyledTableCell>
          <IconButton
              edge="end"
              aria-label="Edit"
              title="Edit"
              onClick={() => {
                setupdatestr("update");
                dispatch(getQuestionById(id));
                dispatch(setIsModalOpen(true));
              }}
            >
              <EditIcon />
            </IconButton>
            {/* {user_id===user?.id?
            <IconButton
              edge="end"
              aria-label="Edit"
              title="Edit"
              onClick={() => {
                setupdatestr("update");
                dispatch(getQuestionById(id));
                dispatch(setIsModalOpen(true));
              }}
            >
              <EditIcon />
            </IconButton>:""
            } */}
          </StyledTableCell>
          <StyledTableCell>
          <IconButton
              edge="end"
              aria-label="delete"
              title="Delete"
              onClick={() => {
                dispatch(deleteQuestion(id));
              }}
            >
              <DeleteIcon />
            </IconButton>
          {/* {user_id===user?.id?

            <IconButton
              edge="end"
              aria-label="delete"
              title="Delete"
              onClick={() => {
                dispatch(deleteQuestion(id));
              }}
            >
              <DeleteIcon />
            </IconButton>:""
          } */}
          </StyledTableCell>
        </StyledTableRow>
      );
    });
  } else if (questions.length === 0) {
  } else {
  }

  return (
    <div>
      <Typography variant="h6">Library Questions</Typography>
      <br />
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          dispatch(createQuestion('', ''));
        }}
        startIcon={<AddIcon />}
      >
        Add Question
      </Button>
      <br />
      <br />

      {questions.length > 0 && (
        <TableContainer component={Paper}>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Image</StyledTableCell>
                <StyledTableCell>Added By</StyledTableCell>
                <StyledTableCell>Question</StyledTableCell>
                <StyledTableCell>Answer</StyledTableCell>
                <StyledTableCell>Edit</StyledTableCell>
                <StyledTableCell>Delete</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>{renderedQuestions}</TableBody>
          </Table>
        </TableContainer>
      )}

      {questionObj ? <EnhancedQuestionEditorForm question={questionObj} /> : ''}
    </div>
  );
};

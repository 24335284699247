import { USER_ROLE } from './../constants/userRoles';
import { COUNSELOR_PROFILES, COUNSELOR_DASHBOARD, ADD_SUPPORT_ADMIN, SUPERADMIN_COUNSELOR_PROFILES } from './../constants/routes';
import store, { AppThunk } from '../app/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from '../constants/models/User';
import { History } from 'history';

import * as REQUESTS from '../api/counselorRequests';
import { setErrorMsg, setSuccessMsg } from './alertSlice';
import { updateDetailsForGraphQlUser } from './authSlice';

export interface counselorState {
  loading: boolean;
  error: string | null;
  counselor: User | null;
  counselors: User[];
}

export const initialState: counselorState = {
  loading: false,
  error: null,
  counselor: null,
  counselors: [],
};

const counselorSlice = createSlice({
  name: 'counselor',
  initialState,
  reducers: {
    counselorStart(state) {
      state.loading = true;
      state.error = null;
    },
    setCounselor(state, action: PayloadAction<User | null>) {
      state.counselor = action.payload;
    },
    setCounselors(state, action: PayloadAction<{ data: User[] }>) {
      const { data } = action.payload;
      state.counselors = data.map((counselor) => counselor);
    },
    counselorFailure(state, action: PayloadAction<string | null>) {
      state.loading = false;
      state.error = action.payload ? action.payload : 'some error';
    },
    counselorComplete(state) {
      state.loading = false;
    },
  },
});

export const {
  counselorStart,
  setCounselor,
  setCounselors,
  counselorComplete,
  counselorFailure,
} = counselorSlice.actions;

export default counselorSlice.reducer;

export const getCounselorById =
  (counselorId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(counselorStart());
      const counselor = await REQUESTS.getCounselorById(counselorId);
      dispatch(setCounselor(counselor));
      dispatch(counselorComplete());
    } catch (error: any) {
      if (error.response) {
        const { data, status } = error.response;
        dispatch(counselorFailure(data));
      }
    }
  };

  export const getCounselorLogById =
  (counselorId: string, brosname:string,brosdevicename: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(counselorStart());
      const counselor = await REQUESTS.getCounselorLogById(counselorId,brosname,brosdevicename);
      //dispatch(setCounselor(counselor));
      dispatch(counselorComplete());
    } catch (error: any) {
      if (error.response) {
        const { data, status } = error.response;
        dispatch(counselorFailure(data));
      }
    }
  };

  export const getCounselorByIdRefresh =
  (counselorId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(counselorStart());
      const counselor = await REQUESTS.getCounselorById(counselorId);
      dispatch(setCounselor(counselor));
      localStorage.setItem('userData', JSON.stringify(counselor));

      dispatch(counselorComplete());
    } catch (error: any) {
      if (error.response) {
        const { data, status } = error.response;
        dispatch(counselorFailure(data));
      }
    }
  };
export const createCounselor =
  (id: string, name: string, email: string, schoolName: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(counselorStart());
      let text = email;
            email = text.toLowerCase();
      const counselor = await REQUESTS.createCounselor(
        id,
        name,
        email,
        schoolName
      );
      dispatch(setCounselor(counselor));
      dispatch(counselorComplete());
    } catch (error: any) {
      if (error.response) {
        const { data, status } = error.response;
        dispatch(counselorFailure(data));
      }
    }
  };

export const updateCounselor =
  (
    id: string | undefined,
    name: string,
    email: string,
    address: string,
    street: string,
    city: string,
    state: string,
    country: string,
    postalCode: number,
    phoneno: string,
    countryCode: string,
    // about: string,
    imageUrl: string | undefined,
    history: History,
    userProgramRoute: boolean = false
  ): AppThunk =>
  async (dispatch, getStore) => {
    try {
      dispatch(counselorStart());
      let text = email;
      email = text.toLowerCase();
      const { counselor } = store.getState().counselor;
     // let {brosname } = store.getState().auth;
      let cdata;
      if(counselor)
      {
        cdata=counselor.id;
      }
      const counselorData = await REQUESTS.updateCounselor(
        id!,
        name,
        email,
        address,
        street,
        city,
        state,
        country,
        postalCode,
        phoneno,
        countryCode,
        // about,
        imageUrl,
        //brosname
      );
      dispatch(setSuccessMsg(`Profile updated!`));
      history.push(COUNSELOR_PROFILES);

      dispatch(getCounselorById(id!));
      const { role } = getStore().auth;
      // if (role === USER_ROLE.COUNSELOR) {
      const { graphQlUser } = getStore().auth;
      dispatch(
        updateDetailsForGraphQlUser(
          id!,
          email,
          name,
          imageUrl ?? graphQlUser.imageUrl,
          email,
          graphQlUser.about
        )
      );
      // }

      if (userProgramRoute) {
        history.push(COUNSELOR_PROFILES);
      } else {
        history.push(COUNSELOR_DASHBOARD);
      }

      dispatch(counselorComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        // dispatch(studentFailure(error));
        dispatch(setErrorMsg(error));
        dispatch(counselorFailure(error));
      }
    }
  };

export const createCounselorAsProgramManager =
  (
    name: string,
    email: string,
    schoolName: string,
    referenceCode: string,
    address: string,
    street: string,
    city: string,
    state: string,
    country: string,
    postalCode: number,
    about: string,
    phoneno: string,
    countryCode: string,
    history: History
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(counselorStart());
      let text = email;
            email = text.toLowerCase();
      // const { program } = store.getState().program;

      // if (program && program.id) {
      //   let programId = program.id;
      const { program } = store.getState().program;
      const { parent } = store.getState().parent;
      const { programManager } = store.getState().programManager;
      const { superAdmin } = store.getState().superAdmin;
      const { counselor } = store.getState().counselor;
      const authlocal = localStorage.getItem('userRole');
      const user_id = localStorage.getItem('user_id');
    
      
       
      let program_de_id="";
      if(superAdmin && authlocal=="4")
      {
        program_de_id=superAdmin.id;
      }
      if(parent && authlocal=="3")
      {
        program_de_id=parent.id;
      }
      if(counselor && authlocal=="1")
      {
        program_de_id=counselor.id;
      }
      if(programManager && authlocal=="0")
      {
        program_de_id=programManager.id;
      }
      else 
      {
        if(program?.id){
          program_de_id=program.id;
        }
      }
    console.log("pid--->"+program_de_id);
    let {brosname } = store.getState().auth;
    let brosdevicename = 'Daily Active Users';
      if (program_de_id) {
      let programId = program_de_id;//program.id;
        const counselor = await REQUESTS.createCounselorAsProgramManger(
          name,
          email,
          schoolName,
          referenceCode,
          address,
          street,
          city,
          state,
          country,
          postalCode,
          about,
          phoneno,
          countryCode,
          brosname,
          brosdevicename,
          programId
        );
        dispatch(setSuccessMsg(`Admin added successfully!`));
      }
      history.push(COUNSELOR_PROFILES);
      //dispatch(setCounselor(counselor));
      dispatch(counselorComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(counselorFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };
  export const createCounselorBySuperAdmin =
  (
    name: string,
    email: string,
    schoolName: string,
    referenceCode: string,
    address: string,
    street: string,
    city: string,
    state: string,
    country: string,
    postalCode: number,
    about: string,
    phoneno: string,
    countryCode: string,
    history: History
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(counselorStart());
      let text = email;
            email = text.toLowerCase();
      // const { program } = store.getState().program;

      // if (program && program.id) {
      //   let programId = program.id;
      const { program } = store.getState().program;
      const { parent } = store.getState().parent;
      const { programManager } = store.getState().programManager;
      const { superAdmin } = store.getState().superAdmin;
      const { counselor } = store.getState().counselor;
      const authlocal = localStorage.getItem('userRole');
      const user_id = localStorage.getItem('user_id');
      let orgid;
      const userData = localStorage.getItem('userData');
      if(userData)
      {
  
       const userDt = JSON.parse(userData);
       orgid=userDt.id;
      }
      
       
      let program_de_id=user_id;
      // if(superAdmin && authlocal=="4")
      // {
      //   program_de_id=superAdmin.id;
      // }
      // if(parent && authlocal=="3")
      // {
      //   program_de_id=parent.id;
      // }
      // if(counselor && authlocal=="1")
      // {
      //   program_de_id=counselor.id;
      // }
      // if(programManager && authlocal=="0")
      // {
      //   program_de_id=programManager.id;
      // }
      // else 
      // {
      //   if(program?.id){
      //     program_de_id=program.id;
      //   }
      // }
    console.log("pid--->"+program_de_id);
    let {brosname } = store.getState().auth;
    let brosdevicename = 'Daily Active Users';
      if (program_de_id) {
      let programId = program_de_id;//program.id;
        const counselor = await REQUESTS.createCounselorBySuperAdmin(
          name,
          email,
          schoolName,
          referenceCode,
          address,
          street,
          city,
          state,
          country,
          postalCode,
          about,
          phoneno,
          countryCode,
          programId,
          brosname,
          brosdevicename
        );
        dispatch(setSuccessMsg(`Admin added successfully!`));
      }
      history.push(SUPERADMIN_COUNSELOR_PROFILES);
      //dispatch(setCounselor(counselor));
      dispatch(counselorComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(counselorFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };

  export const createUserBySuperAdmin =
  (
    name: string,
    email: string,
    schoolName: string,
    referenceCode: string,
    address: string,
    street: string,
    city: string,
    state: string,
    country: string,
    postalCode: number,
    about: string,
    history: History
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(counselorStart());
      let text = email;
            email = text.toLowerCase();
      // const { program } = store.getState().program;

      // if (program && program.id) {
      //   let programId = program.id;
      const { program } = store.getState().program;
      const { parent } = store.getState().parent;
      const { programManager } = store.getState().programManager;
      const { superAdmin } = store.getState().superAdmin;
      const { counselor } = store.getState().counselor;
      const authlocal = localStorage.getItem('userRole');
      const user_id = localStorage.getItem('user_id');
      let orgid;
      const userData = localStorage.getItem('userData');
      if(userData)
      {
  
       const userDt = JSON.parse(userData);
       orgid=userDt.id;
      }
      
       
      let program_de_id=user_id;
      // if(superAdmin && authlocal=="4")
      // {
      //   program_de_id=superAdmin.id;
      // }
      // if(parent && authlocal=="3")
      // {
      //   program_de_id=parent.id;
      // }
      // if(counselor && authlocal=="1")
      // {
      //   program_de_id=counselor.id;
      // }
      // if(programManager && authlocal=="0")
      // {
      //   program_de_id=programManager.id;
      // }
      // else 
      // {
      //   if(program?.id){
      //     program_de_id=program.id;
      //   }
      // }
    console.log("pid--->"+program_de_id);
      if (program_de_id) {
      let programId = program_de_id;//program.id;
        const counselor = await REQUESTS.createUserBySuperAdmin(
          name,
          email,
          schoolName,
          referenceCode,
          address,
          street,
          city,
          state,
          country,
          postalCode,
          about,
          programId
        );
        dispatch(setSuccessMsg(`Admin added successfully!`));
      }
      history.push(SUPERADMIN_COUNSELOR_PROFILES);
      //dispatch(setCounselor(counselor));
      dispatch(counselorComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        dispatch(counselorFailure(error));
        dispatch(setErrorMsg(error));
      }
    }
  };
export const getCounselorsForProgram = (): AppThunk => async (dispatch) => {
  try {
    dispatch(counselorStart());
    // const { program } = store.getState().program;

    // if (program && program.id) {
    //   let programId = program.id;
    const { program } = store.getState().program;
      const { parent } = store.getState().parent;
      const { programManager } = store.getState().programManager;
      const { counselor } = store.getState().counselor;
      const { role } = store.getState().auth;

      let program_de_id="";
      if(role===USER_ROLE.PARENT &&  parent?.id)
      {
        program_de_id=parent.id;
      }
      else if(role===USER_ROLE.COUNSELOR && counselor?.id)
      {
        program_de_id=counselor.id;
      }
      else if(role===USER_ROLE.PROGRAM_MANAGER && programManager?.id)
      {
        program_de_id=programManager.id;
      }
      else if(program?.id)
      {
        program_de_id=program.id;
      }
      console.log("program_id--->"+program_de_id)
    if (program_de_id) {
    let programId = program_de_id;//program.id;
    //if(parent?.id || programManager?.id )
    if(role===USER_ROLE.SUPER_ADMIN)
    {
      const counselors = await REQUESTS.getCounselorsForProgram(programId);
      dispatch(setCounselors({ data: counselors }));

      
    }
    else
    {
      const counselors = await REQUESTS.getCounselorsForParentPanel(programId);
      dispatch(setCounselors({ data: counselors }));
    }

    }
    dispatch(counselorComplete());
  } catch (err: any) {
    if (err.response) {
      const { error } = err.response.data;
      dispatch(counselorFailure(error));
      dispatch(setErrorMsg(error));
    }
  }
};

export const getCounselorsForProgramRefresh = (parentId:string): AppThunk => async (dispatch) => {
  try {
    dispatch(counselorStart());
    

      let program_de_id=parentId;
     
      console.log("program_id--->"+program_de_id)
    if (program_de_id) {
    let programId = program_de_id;//program.id;
    //if(parent?.id || programManager?.id )
   
      const counselors = await REQUESTS.getCounselorsForProgram(programId);
      dispatch(setCounselors({ data: counselors }));

  
    }
    dispatch(counselorComplete());
  } catch (err: any) {
    if (err.response) {
      const { error } = err.response.data;
      dispatch(counselorFailure(error));
      dispatch(setErrorMsg(error));
    }
  }
};
export const getCounselorsForProgramRefreshParent = (parentId:string): AppThunk => async (dispatch) => {
  try {
      dispatch(counselorStart());
      const counselors = await REQUESTS.getCounselorsForParentPanel(parentId);
      dispatch(setCounselors({ data: counselors }));
      dispatch(counselorComplete());
  } catch (err: any) {
    if (err.response) {
      const { error } = err.response.data;
      dispatch(counselorFailure(error));
      dispatch(setErrorMsg(error));
    }
  }
};
export const getCounselorsForProgramRefreshOrganization = (parentId:string): AppThunk => async (dispatch) => {
  try {
      dispatch(counselorStart());
      const counselors = await REQUESTS.getCounselorsForParent(parentId);
      dispatch(setCounselors({ data: counselors }));
      dispatch(counselorComplete());
  } catch (err: any) {
    if (err.response) {
      const { error } = err.response.data;
      dispatch(counselorFailure(error));
      dispatch(setErrorMsg(error));
    }
  }
};

export const getCounselorsForOrganisationProgramRefresh = (parentId:string): AppThunk => async (dispatch) => {
  try {
      dispatch(counselorStart());
      const counselors = await REQUESTS.getCounselorsForOrganisationProgram(parentId);
      dispatch(setCounselors({ data: counselors }));
      dispatch(counselorComplete());
  } catch (err: any) {
    if (err.response) {
      const { error } = err.response.data;
      dispatch(counselorFailure(error));
      dispatch(setErrorMsg(error));
    }
  }
};
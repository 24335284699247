import { updateDetailsForGraphQlUser } from './authSlice';
import { USER_ROLE } from './../constants/userRoles';
import {
  PROGRAM_MANAGER_INTAKE,
  PROGRAM_MANAGER_DASHBOARD,
  DASHBOARD_ROUTE,
  COUNSELOR_DASHBOARD,
  SUPER_ADMIN_DASHBOARD
} from './../constants/routes';
import { AppThunk } from './../app/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from './../constants/models/User';
import { History } from 'history';

import * as REQUESTS from '../api/superAdminRequests';
import * as COUNSELOR_REQUESTS from '../api/counselorRequests';
import * as SUPER_REQUESTS from '../api/superAdminRequests';
import { setProgram } from './programSlice';
import { setSuccessMsg, setErrorMsg } from './alertSlice';
import { setCounselor } from './counselorSlice';

export interface superAdminState {
  loading: boolean;
  error: string | null;
  hasProgram: boolean;
  superAdmin: User | null;
  superAdmins: User[];
}

export const initialState: superAdminState = {
  loading: false,
  error: null,
  hasProgram: false,
  superAdmin: null,
  superAdmins: [],
};

const superAdminSlice = createSlice({
  name: 'superAdmin',
  initialState,
  reducers: {
    superAdminStart(state) {
      state.loading = true;
      state.error = null;
    },
    setHasProgram(state, action: PayloadAction<boolean>) {
      state.hasProgram = action.payload;
    },
    setSuperAdmin(state, action: PayloadAction<User | null>) {
      state.superAdmin = action.payload;
    },
    superAdminFailure(state, action: PayloadAction<string | null>) {
      state.loading = false;
      state.error = action.payload ? action.payload : 'some error';
    },
    superAdminComplete(state) {
      state.loading = false;
    },
  },
});

export const {
  superAdminStart,
  setHasProgram,
  setSuperAdmin,
  superAdminComplete,
  superAdminFailure,
} = superAdminSlice.actions;

export default superAdminSlice.reducer;

export const getSuperAdminLogById =
  (superAdminId: string, brosname: string, brosdevicename:string, history: History
    ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(superAdminStart());
      const superAdmin = await REQUESTS.getSuperAdminlogById(
        superAdminId,
        brosname,
        brosdevicename
      );
      //dispatch(setSuperAdmin(superAdmin));
      if (history) {
        // history.push(SUPER_ADMIN_DASHBOARD); 
      }
      dispatch(superAdminComplete());
    } catch (error: any) {
      if (error.response) {
        const { data, status } = error.response;
        dispatch(superAdminFailure(data));
      }
    }
  };
  export const getSuperAdminById =
  (superAdminId: string,   history: History
    ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(superAdminStart());
      const superAdmin = await REQUESTS.getSuperAdminById(
        superAdminId,
      );
      dispatch(setSuperAdmin(superAdmin));
      if (history) {
        // history.push(SUPER_ADMIN_DASHBOARD); 
      }
      dispatch(superAdminComplete());
    } catch (error: any) {
      if (error.response) {
        const { data, status } = error.response;
        dispatch(superAdminFailure(data));
      }
    }
  };

  export const getSuperAdminByIdRefresh =
  (superAdminId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(superAdminStart());
      const superAdmin = await REQUESTS.getSuperAdminById(
        superAdminId
      );
      dispatch(setSuperAdmin(superAdmin));
      dispatch(superAdminComplete());
    } catch (error: any) {
      if (error.response) {
        const { data, status } = error.response;
        dispatch(superAdminFailure(data));
      }
    }
  };

export const createSuperAdmin =
  (id: string, name: string, email: string, schoolName: string, password:string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(superAdminStart());
      let text = email;
            email = text.toLowerCase();
      const superAdmin = await REQUESTS.createSuperAdmin(
        id,
        name,
        email,
        schoolName,
        password
      );
      dispatch(superAdminComplete());
    } catch (error: any) {
      if (error.response) {
        const { data, status } = error.response;
        dispatch(superAdminFailure(data));
      }
    }
  };

export const updateSuperAdmin =
  (
    id: string | undefined,
    name: string,
    email: string,
    address: string,
    street: string,
    city: string,
    state: string,
    country: string,
    postalCode: number,
    phoneno: string,
    countryCode: string,
    // about: string,
    imageUrl: string | undefined,
    history: History
  ): AppThunk =>
  async (dispatch, getStore) => {
    try {
      dispatch(superAdminStart());
      let text = email;
      email = text.toLowerCase();
      const superAdmin = await REQUESTS.updateSuperAdmin(
        id!,
        name,
        email,
        address,
        street,
        city,
        state,
        country,
        postalCode,
        phoneno,
        countryCode,
        // about,
        imageUrl
      );
      const { role } = getStore().auth;
      if (role === USER_ROLE.PROGRAM_MANAGER) {
        const { graphQlUser } = getStore().auth;
        dispatch(
          updateDetailsForGraphQlUser(
            id!,
            email,
            name,
            imageUrl ?? graphQlUser.imageUrl,
            email,
            graphQlUser.about
          )
        );
      }
      dispatch(setSuccessMsg(`Profile updated!`));
      //dispatch(getSuperAdminById(id!));

      history.push(SUPER_ADMIN_DASHBOARD);

      dispatch(superAdminComplete());
    } catch (err: any) {
      if (err.response) {
        const { error } = err.response.data;
        // dispatch(studentFailure(error));
        dispatch(setErrorMsg(error));
        dispatch(superAdminFailure(error));
      }
    }
  };

export const getProgramForSuperAdmin =
  (superAdminId: string, history?: History): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(superAdminStart());
      const programs = await REQUESTS.getProgramForSuperAdmin(
        superAdminId
      );
      if (programs.length > 0) {
        dispatch(setProgram(programs[0]));
        dispatch(setHasProgram(true));
       
      } else {
        dispatch(setHasProgram(false));
        if (history) {
          history.push(SUPER_ADMIN_DASHBOARD);
        }
      }
      dispatch(superAdminComplete());
    } catch (error: any) {
      if (error.response) {
        const { data, status } = error.response;
        dispatch(superAdminFailure(data));
      }
    }
  };

  export const getProgramForSuperAdminRefresh =
  (superAdminId: string, history?: History): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(superAdminStart());
      const programs = await REQUESTS.getProgramForSuperAdmin(
        superAdminId
      );
      if (programs.length > 0) {
       // dispatch(setSuperAdmin(programs[0]));
        dispatch(setHasProgram(true));
        if (history) {
          history.push(PROGRAM_MANAGER_DASHBOARD);
        }
      } else {
        dispatch(setHasProgram(false));
        // if (history) {
        //   history.push(PROGRAM_MANAGER_INTAKE);
        // }
      }
      dispatch(superAdminComplete());
    } catch (error: any) {
      if (error.response) {
        const { data, status } = error.response;
        dispatch(superAdminFailure(data));
      }
    }
  };

  
export const getProgramForCounselor =
  (counselorId: string, history?: History): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(superAdminStart());
      const counselor = await COUNSELOR_REQUESTS.getCounselorById(counselorId);
      dispatch(setCounselor(counselor));

      dispatch(setProgram(counselor.programCounselling));
      if (history) {
        history.push(COUNSELOR_DASHBOARD);
      }

      dispatch(superAdminComplete());
    } catch (error: any) {
      if (error.response) {
        const { data, status } = error.response;
        dispatch(superAdminFailure(data));
      }
    }
  };

 

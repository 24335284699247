import { FormikProps } from 'formik';
import {
  Button,
  FormControl,
  InputLabel,
  InputAdornment,
  makeStyles,
  TextField,
  Typography,
  Grid,
} from '@material-ui/core';
import React from 'react';
import formStyles from '../../../assets/jss/components/formStyles';
import { EnhancedAddTaskTypeFormValues } from './EnhancedAddTaskTypeForm';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { RootState } from '../../../app/rootReducer';

interface IAddTaskTypeFormProps {}
const useStyles = makeStyles(formStyles);

export const AddTaskTypeForm: React.FC<
  IAddTaskTypeFormProps & FormikProps<EnhancedAddTaskTypeFormValues>
> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { values, errors, touched, handleSubmit, handleBlur, handleChange } =
    props;
  const { taskTypeLoading } = useSelector((state: RootState) => {
    return {
      taskTypeLoading: state.taskType.loading,
    };
  }, shallowEqual);

  return (
    <div>
      <div style={{ margin: '0px 0px 32px' }}>
        <Typography variant="body1" className={classes.loginText}>
          ADD TASK TYPES
        </Typography>
        <Typography variant="h4" className={classes.welcomeBackText}>
          Task Type List
        </Typography>
        <Typography variant="body1" className={classes.loginText}>
          Manage Task Types for your program.
        </Typography>
      </div>
      <form onSubmit={handleSubmit}>
        <FormControl
          required
          className={classes.formControl}
          style={{ width: '100%', paddingRight: '0.4em' }}
        >
          <Typography variant="subtitle1" className={classes.label}>
            Enter Task Type <span style={{color:'red'}}>*</span>
          </Typography>
          <Grid container={true}>
            <TextField
              label="Task Type"
              type="text"
              placeholder=""
              name="name"
              variant="outlined"
              size="small"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              error={(errors.name && touched.name) as boolean}
              helperText={errors.name && touched.name && errors.name}
              style={{ marginRight: '0.4em' }}
              InputLabelProps={{
                classes: {
                  root: classes.heading,
                  focused: classes.cssFocused,
                },
              }}
            />
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={taskTypeLoading}
            >
              Add
            </Button>
          </Grid>
        </FormControl>
      </form>
    </div>
  );
};

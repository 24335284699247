import React, { useEffect } from 'react';
import { Grid, Container, CssBaseline, makeStyles } from '@material-ui/core';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import EnhancedEditCounselorProfileForm from './EnhancedEditCounselorProfileForm';
import { getCounselorById, getCounselorByIdRefresh, setCounselor } from '../../../../slices/counselorSlice';
import { RootState } from '../../../../app/rootReducer';
import * as REQUESTS from '../../../../api/counselorRequests';


interface IEditCounselorProfileProps {}

const EditCounselorProfile: React.FC<IEditCounselorProfileProps> = ({}) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const authlocal = localStorage.getItem('userRole');
  const user_id = localStorage.getItem('user_id');
  const { couselorId } = useParams<{ couselorId: string | undefined }>();

  const { counselor } = useSelector(
    (state: RootState) => {
      return {
        counselor: state.counselor.counselor,
      };
    },
    shallowEqual
  );

  useEffect(() => {
    async function fetchMyAPI() {
    if(couselorId)
    {
      
        dispatch(getCounselorByIdRefresh(couselorId));
      //  const counselor = await REQUESTS.getCounselorById(couselorId);
      // dispatch(setCounselor(counselor));
      
    }
    else
    {
      
        //dispatch(getCounselorById());
      
    }
  }
  fetchMyAPI()
    return () => {};
  }, []);//[isAddStudent, groceryId, dispatch]

  
  // if(user_id)
  //   {
  //     dispatch(setGroceryList(groceryList));
  //   }
  if(counselor)
  {
    console.warn("grc title"+JSON.stringify(counselor));
    localStorage.setItem('userData', JSON.stringify(counselor));
  }
  
  // if(user_id && authlocal=="1")
  // {
  //   dispatch(getCounselorByIdRefresh(user_id));

  // }

  let renderedForm;
  if (counselor) {
    localStorage.setItem('userData', JSON.stringify(counselor));

    const {
      name,
      email,
      schoolName,
      referenceCode,
      address,
      street,
      city,
      country,
      postalCode,
      about,
      phoneno,
      countryCode,
    } = counselor;
    renderedForm = (
      <EnhancedEditCounselorProfileForm
        name={name}
        email={email}
        address={address}
        street={street}
        city={city}
        country={country}
        phoneno={phoneno}
        countryCode={countryCode}
        postalCode={postalCode}
      />
    );
  } else {
  renderedForm = <EnhancedEditCounselorProfileForm />;
  }

  return (
    <div>
      <Container
        style={{
          paddingLeft: '0.4em',
          paddingRight: '0.4em',
          height: '80vh',
        }}
      >
        {renderedForm}
      </Container>
    </div>
  );
};
export default EditCounselorProfile;

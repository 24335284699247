import React from 'react';
import {
  Button,
  FormControl,
  InputLabel,
  InputAdornment,
  makeStyles,
  TextField,
  Typography,
  Grid,
} from '@material-ui/core';
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { FormikProps } from 'formik';
import formStyles from '../../../assets/jss/components/formStyles';
import { EnhancedAddDiagnosisFormValues } from './EnhancedAddDiagnosisForm';
import { RootState } from '../../../app/rootReducer';

const useStyles = makeStyles(formStyles);

interface IAddDiagnosisFormProps {}

export const AddDiagnosisForm: React.FC<
  IAddDiagnosisFormProps & FormikProps<EnhancedAddDiagnosisFormValues>
> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { values, errors, touched, handleSubmit, handleBlur, handleChange } =
    props;
  const { diagnosisLoading } = useSelector((state: RootState) => {
    return {
      diagnosisLoading: state.diagnosis.loading,
    };
  }, shallowEqual);

  return (
    <div>
      <div style={{ margin: '0px 0px 32px' }}>
        <Typography variant="body1" className={classes.loginText}>
          ADD DIAGNOSIS
        </Typography>
        <Typography variant="h4" className={classes.welcomeBackText}>
          Diagnosis List
        </Typography>
        <Typography variant="body1" className={classes.loginText}>
          Manage Diagnoses for your program.
        </Typography>
      </div>
      <form onSubmit={handleSubmit}>
        <FormControl
          required
          className={classes.formControl}
          style={{ width: '100%', paddingRight: '0.4em' }}
        >
          <Typography variant="subtitle1" className={classes.label}>
            Enter Diagnosis <span style={{color:'red'}}>*</span>
          </Typography>
          <Grid
                        container
                        direction='row'
                        justifyContent='space-between'
                        alignItems='center'

                      >
                      <Grid item md={6} xs={8} >
                          <TextField
                            label="Diagnosis"
                            type="text"
                            placeholder=""
                            name="name"
                            variant="outlined"
                            size="small"
                            value={values.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={(errors.name && touched.name) as boolean}
                            helperText={errors.name && touched.name && errors.name}
                            style={{ marginRight: '0.4em' }}
                            InputLabelProps={{
                              classes: {
                                root: classes.heading,
                                focused: classes.cssFocused,
                              },
                            }}
                          />
                        </Grid>
                        <Grid item sm={6} xs={4} >
                            <Button
                              variant="contained"
                              color="primary"
                              type="submit"
                              disabled={diagnosisLoading}
                            >
                              Add
                            </Button>
                          </Grid>
          </Grid>
        </FormControl>
      </form>
    </div>
  );
};

import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  TextField,
  FormControlLabel,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { RootState } from '../../../app/rootReducer';
import {
  deleteTask,
  setIsDeleteModalOpen,
  deleteRecurringTask,
} from '../../../slices/taskSlice';
import theme from '../../../app/theme';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { useParams } from 'react-router';
interface IDeleteTaskModalProps {}

export const DeleteTaskModal: React.FC<IDeleteTaskModalProps> = ({}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { isDeleteModalOpen, taskId, isRecurring } = useSelector(
    (state: RootState) => {
      return {
        isDeleteModalOpen: state.task.isDeleteModalOpen,
        taskId: state.task.taskId,
        isRecurring: state.task.task?.isRecurring,
      };
    },
    shallowEqual
  );
  const [value, setValue] = React.useState('0');
  const { studentId } = useParams<{ studentId: string | undefined }>();
let stid="";
if(studentId)
{}
  const handleClose = () => {
    dispatch(setIsDeleteModalOpen(false));
  };

  const handleChangeRadio = (event: any) => {
    let value = event.target.value;
    setValue(value);
  };

  return (
    <div>
      <Dialog
        open={isDeleteModalOpen}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle
          id='alert-dialog-title'
          style={{
            color: theme.palette.primary.main,
            backgroundColor: 'white',
          }}
        >
          Delete Task
        </DialogTitle>
        <DialogContent
          style={{
            backgroundColor: 'white',
          }}
        >
          <DialogContentText id='alert-dialog-description'>
            Are you sure you want to delete this task?
          </DialogContentText>

          {isRecurring && (
            <RadioGroup
              aria-label='gender'
              name='gender1'
              value={value}
              onChange={handleChangeRadio}
              color='primary'
            >
              <FormControlLabel
                value='0'
                control={<Radio color='primary' />}
                label='Delete current instance'
              />
              <FormControlLabel
                value='1'
                control={<Radio color='primary' />}
                label='Delete all instances'
              />
            </RadioGroup>
          )}
        </DialogContent>
        <DialogActions
          style={{
            backgroundColor: 'white',
          }}
        >
          <Button variant='text' onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant='contained'
            color='primary'
            disabled={loading}
            onClick={async (e) => {
              setLoading(true);
              if (value === '1') {
                await dispatch(deleteRecurringTask(taskId));
              } else {
                await dispatch(deleteTask(taskId));
              }
              setLoading(false);
            }}
          >
            {loading && (
              <CircularProgress
                style={{ color: '#A3A3A3', marginRight: '0.5rem' }}
                size='0.8rem'
              />
            )}{' '}
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

import { Program } from './../constants/models/Program';
import {
  PROGRAM_MANAGER_ROUTE,
  PROGRAM_ROUTE,
  COUNSELOR_ROUTE,
  PARENT_ROUTE,
  STUDENT_ROUTE,
} from './../constants/routes';
import { User } from '../constants/models/User';
import API from './api';
import { Organisation } from '../constants/models/Organisation';

export async function getProgramManagerById(id: string) {
  try {
    const res = await API.get<{ data: User; success: boolean }>(
      `${PROGRAM_MANAGER_ROUTE}/${id}`
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function getProgramManagerLogById(id: string, brosname: string,brosdevicename: string) {
  try {
    const res = await API.post<{ data: User; success: boolean }>(
      `${PROGRAM_MANAGER_ROUTE}/getProgramManagerLogById`,
      {
        id,
        brosname,
        brosdevicename
      }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function getOrganisationById(id: string) {
  try {
    const res = await API.post<{ data: Organisation; success: boolean }>(
      `${PROGRAM_MANAGER_ROUTE}/getOrganisationById`,
      {
        id
      }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}
export async function getProgramManagerByOrgId(id: string) {
  try {
    const res = await API.post<{ data: []; success: boolean }>(
      `${PROGRAM_MANAGER_ROUTE}/getProgramManagerByOrgId`,
      {
        id
      }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}
export async function getOrganisationList() {
  try {
    const res = await API.post<{ data: []; success: boolean }>(
      `${PROGRAM_MANAGER_ROUTE}/getOrganisationList`
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function getEntryLogList(rpage:number,size:number) {
  try {
    const res = await API.post<{ data: []; success: boolean,total:number;page:number;size:number; }>(
      `${PROGRAM_MANAGER_ROUTE}/getEntryLogList`,
      {
        page:rpage,
        size:size
      }
    );
    //return res.data.data;
    return res;
  } catch (err) {
    throw err;
  }
}
export async function getOnboardingCompletionLogList(rpage:number,size:number) {
  try {
    const res = await API.post<{ data: []; success: boolean,total:number;page:number;size:number; }>(
      `${PROGRAM_MANAGER_ROUTE}/getOnboardingConpletionList`,
      {
        page:rpage,
        size:size
      }
    );
    //return res.data.data;
    return res;
  } catch (err) {
    throw err;
  }
}
export async function getDailyActiveUsersList(rpage:number,size:number) {
  try {
    const res = await API.post<{ data: []; success: boolean,total:number;page:number;size:number; }>(
      `${PROGRAM_MANAGER_ROUTE}/getDailyActiveUsersList`,
      {
        page:rpage,
        size:size
      }
    );
    //return res.data.data;
    return res;
  } catch (err) {
    throw err;
  }
}

export async function getDailyActiveUsersEntryTypes(rpage:number,size:number) {
  try {
    const res = await API.post<{ data: []; success: boolean,total:number;page:number;size:number; }>(
      `${PROGRAM_MANAGER_ROUTE}/getDailyActiveUsersEntryTypes`,
      {
        page:rpage,
        size:size
      }
    );
    //return res.data.data;
    return res;
  } catch (err) {
    throw err;
  }
}

export async function getWeeklyTaskCompletedUsers(rpage:number,size:number) {
  try {
    const res = await API.post<{ data: []; success: boolean,total:number;page:number;size:number; }>(
      `${PROGRAM_MANAGER_ROUTE}/getWeeklyTaskCompletedUsers`,
      {
        page:rpage,
        size:size
      }
    );
    //return res.data.data;
    return res;
  } catch (err) {
    throw err;
  }
}

export async function getWeeklyReturningUsersList(rpage:number,size:number) {
  try {
    const res = await API.post<{ data: []; success: boolean,total:number;page:number;size:number; }>(
      `${PROGRAM_MANAGER_ROUTE}/getWeeklyReturningUsersList`,
      {
        page:rpage,
        size:size
      }
    );
    //return res.data.data;
    return res;
  } catch (err) {
    throw err;
  }
}
export async function getLoginLogList(rpage:number,size:number) {
  try {
    const res = await API.post<{ data: []; success: boolean,total:number;page:number;size:number; }>(
      `${PROGRAM_MANAGER_ROUTE}/getLoginLogList`,
      {
        page:rpage,
        size:size
      }
    );
    //return res.data.data;
    return res;
  } catch (err) {
    throw err;
  }
}

export async function getDateWiseReports(fromDate:Date,toDate:Date,selectedOption:string,rpage:number,size:number) {
  try {
    const res = await API.post<{ data: []; success: boolean,total:number;page:number;size:number; }>(
      `${PROGRAM_MANAGER_ROUTE}/getDateWiseReports`,
      {
        fromDate:fromDate,
        toDate:toDate,
        selectedOption,
        page:rpage,
        size:size
      }
    );
    //return res.data.data;
    return res;
  } catch (err) {
    throw err;
  }
}

export async function getDateWiseOnboardingReports(fromDate:Date,toDate:Date,rpage:number,size:number) {
  try {
    const res = await API.post<{ data: []; success: boolean,total:number;page:number;size:number; }>(
      `${PROGRAM_MANAGER_ROUTE}/getDateWiseOnboardingReports`,
      {
        fromDate:fromDate,
        toDate:toDate,
        page:rpage,
        size:size
      }
    );
    //return res.data.data;
    return res;
  } catch (err) {
    throw err;
  }
}

export async function getDateWiseTaskCompletedActiveUsersReports(fromDate:Date,toDate:Date,rpage:number,size:number) {
  try {
    const res = await API.post<{ data: []; success: boolean,total:number;page:number;size:number; }>(
      `${PROGRAM_MANAGER_ROUTE}/getDateWiseTaskCompletedActiveUsersReports`,
      {
        fromDate:fromDate,
        toDate:toDate,
        page:rpage,
        size:size
      }
    );
    //return res.data.data;
    return res;
  } catch (err) {
    throw err;
  }
}
export async function getSearchOrganisationBySuperAdmin( 
  name: string,
  ) {
  try {
    const res = await API.post<{ data: []; success: boolean }>(
      `${PROGRAM_MANAGER_ROUTE}/getSearchOrganisationBySuperAdmin`,
      {
        name
      }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function getSearchOrganisationByEntryLog(rpage:number,size:number,name:string) {
  try {
    const res = await API.post<{ data: []; success: boolean,total:number;page:number;size:number; }>(
      `${PROGRAM_MANAGER_ROUTE}/getDailyActiveUsersListByOrg`,
      {
        page:rpage,
        size:size,
        id:name
      }
    );
    //return res.data.data;
    return res;
  } catch (err) {
    throw err;
  }
}
export async function getSearchOrgByWeeklyTaskCompletedUsers(rpage:number,size:number,name:string) {
  try {
    const res = await API.post<{ data: []; success: boolean,total:number;page:number;size:number; }>(
      `${PROGRAM_MANAGER_ROUTE}/getSearchOrgByWeeklyTaskCompletedUsers`,
      {
        page:rpage,
        size:size,
        id:name
      }
    );
    //return res.data.data;
    return res;
  } catch (err) {
    throw err;
  }
}
export async function getSearchOrgByOnboardingCompletion(rpage:number,size:number,name:string) {
  try {
    const res = await API.post<{ data: []; success: boolean,total:number;page:number;size:number; }>(
      `${PROGRAM_MANAGER_ROUTE}/getSearchOrgByOnboardingCompletion`,
      {
        page:rpage,
        size:size,
        id:name
      }
    );
    //return res.data.data;
    return res;
  } catch (err) {
    throw err;
  }
}
export async function searchParentByName( 
  name: string,
  ) {
  try {
    const res = await API.post<{ data: User[]; success: boolean }>(
      `${PARENT_ROUTE}/searchParentByName`,
      {
        name
      }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}
export async function getSearchOrganisationBySuperAdminorList( 
  name: string,
  ) {
  try {
    const res = await API.post<{ data: []; success: boolean }>(
      `${PROGRAM_MANAGER_ROUTE}/getSearchOrganisationBySuperAdminorList`,
      {
        name
      }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function getSearchSuperAdminListByUser( 
  name: string,
  ) {
  try {
    const res = await API.post<{ data: []; success: boolean }>(
      `${PROGRAM_MANAGER_ROUTE}/getSearchSuperAdminListByUser`,
      {
        name
      }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}
export async function getDeleteUserAllData( 
  id: string,
  ) {
  try {
    const res = await API.get<{ data: []; success: boolean }>(
      `${STUDENT_ROUTE}/${id}/deleteawsuseralldata`
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}
export async function getDeleteProgramUserAllData( 
  id: string,
  ) {
  try {
    const res = await API.get<{ data: []; success: boolean }>(
      `${STUDENT_ROUTE}/${id}/deleteawsprogramuseralldata`
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}
export async function getSearchOrganisationByName( 
  name: string,
  ) {
  try {
    const res = await API.post<{ data: []; success: boolean }>(
      `${PROGRAM_MANAGER_ROUTE}/getSearchOrganisationByName`,
      {
        name
      }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function getSearchOrganisationById( 
  id: string,
  ) {
  try {
    const res = await API.post<{ data: []; success: boolean }>(
      `${PROGRAM_MANAGER_ROUTE}/getSearchOrganisationById`,
      {
        id
      }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}
export async function getProgramManagerList() {
  try {
    const res = await API.post<{ data: []; success: boolean }>(
      `${PROGRAM_MANAGER_ROUTE}/getProgramManagerList`
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}
export async function getSupportAdminList() {
  try {
    const res = await API.post<{ data: []; success: boolean }>(
      `${PROGRAM_MANAGER_ROUTE}/getSupportAdminList`
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}
export async function createProgramManager(
  id: string,
  name: string,
  email: string,
  schoolName: string,
) {
  try {
    let text = email;
    email = text.toLowerCase();
    const res = await API.post<{ data: User; success: boolean }>(
      `${PROGRAM_MANAGER_ROUTE}`,
      {
        id,
        name,
        email,
        schoolName
      }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}
export async function getDeclineStatus(
  email: string,
  brosname: string,
) {
  try {
    const res = await API.post<{ data: string; success: boolean }>(
      `${PROGRAM_MANAGER_ROUTE}/getDeclineStatus`,
      {
        email:email,
        brosname,
      }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}
export async function getChkActiveStatus(
  email: string,
) {
  try {
    const res = await API.post<{ data:User; success: boolean }>(
      `${PROGRAM_MANAGER_ROUTE}/getChkActiveStatus`,
      {
        email:email,
        
      }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}
export async function declineOrganisation(
  id: string,
) {
  try {
    const res = await API.post<{ data: []; success: boolean }>(
      `${PROGRAM_MANAGER_ROUTE}/declineOrganisation`,
      {
        orgid:id,
        
      }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function approvedOrganisation(
  id: string,
) {
  try {
    const res = await API.post<{ data: []; success: boolean }>(
      `${PROGRAM_MANAGER_ROUTE}/approvedOrganisation`,
      {
        orgid:id,
        
      }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}
export async function changeProgramStatus(
  id: string,status: string,
) {
  try {
    const res = await API.post<{ data: []; success: boolean }>(
      `${PROGRAM_MANAGER_ROUTE}/changeProgramStatus`,
      {
        id:id,
        status:status
        
      }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}
export async function chkProgramStatus(
  id: string,status: string,programId: string,
) {
  try {
    const res = await API.post<{ data: []; success: boolean }>(
      `${PROGRAM_MANAGER_ROUTE}/chkProgramStatus`,
      {
        id:id,
        status:status,
        programId
        
      }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}
export async function createOrganisation(
  phoneno: string,
  name: string,
  email: string,
  programname: string,
  tellus: string,
  firstchk: boolean,
  countryCode: string,
) {
  try {
    let text = email;
            email = text.toLowerCase();
    const res = await API.post<{ data: []; success: boolean }>(
      `${PROGRAM_MANAGER_ROUTE}/creatEnquiry`,
      {
        phoneno,
        name,
        email,
        programname,
        tellus,
        firstchk,
        countryCode
      }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function createOrganisationBySuperAmin(
  phoneno: string,
  name: string,
  email: string,
  programname: string,
  tellus: string,
  firstchk: boolean,
) {
  try {
    let text = email;
            email = text.toLowerCase();
    const res = await API.post<{ data: []; success: boolean }>(
      `${PROGRAM_MANAGER_ROUTE}/creatOrganisationBySuperAdmin`,
      {
        phoneno,
        name,
        email,
        programname,
        tellus,
        firstchk
      }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function updateOrganisationBySuperAmin(
  firstname: string,
  lastname: string,
  tellus: string,
  phoneno: string,
  programMangerId: string,
 
) {
  try {
    const res = await API.post<{ data: []; success: boolean }>(
      `${PROGRAM_MANAGER_ROUTE}/updateOrganisationBySuperAmin`,
      {
        firstname,
        lastname,
        tellus,
        phoneno,
        programMangerId,
      
      }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function getProgramForProgramManager(programManagerId: string) {
  try {
    const res = await API.get<{ data: Program[]; success: boolean }>(
      `${PROGRAM_MANAGER_ROUTE}/${programManagerId}${PROGRAM_ROUTE}`
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function getProgramForCounselor(counselorId: string) {
  try {
    const res = await API.get<{ data: Program[]; success: boolean }>(
      `${COUNSELOR_ROUTE}/${counselorId}${PROGRAM_ROUTE}`
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function updateProgramManager(
  id: string,
  name: string,
  email: string,
  address: string,
  street: string,
  city: string,
  state: string,
  country: string,
  postalCode: number,
  phoneno: string,
  countryCode: string,
  // about: string,
  imageUrl: string | undefined
) {
  try {
    const res = await API.post<{ data: User; success: boolean }>(
      `${PROGRAM_MANAGER_ROUTE}/${id}/updateProgramManager`,
      {
        name,
        email,
        address,
        street,
        city,
        state,
        country,
        postalCode,
        phoneno,
        countryCode,
        // about,
        imageUrl: imageUrl ?? 'no-url',
      }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function createProgramManagerBySuperAdmin(
  id: string,
  name: string,
  email: string,
  program_id: string,
  brosname: string,
  brosdevicename: string,

) {
  try {
    const res = await API.post<{ data: User; success: boolean }>(
      `${PROGRAM_MANAGER_ROUTE}/createProgramManagerBySuperAdmin`,
      {
        id,
        name,
        email,
        program_id,
        brosname,
        brosdevicename
      }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}

export async function getProgramMangerLogoutLog(program_id:string, brosname:string, brosdevicename:string) {
  try {
    const res = await API.post<{ data: User; success: boolean }>(
      `${PROGRAM_MANAGER_ROUTE}/programmanagerLogoutEntry`,
      {
        program_id:program_id,
        brosname: brosname,
        brosdevicename
      }
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
}
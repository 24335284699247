import React, { useEffect, useState } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
  IconButton,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import formStyles from '../../../assets/jss/components/formStyles';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../app/rootReducer';
import { useHistory } from 'react-router-dom';
import {
  ViewTaskLogs,
  getStudentById,
  getStudentsForParent,
  getStudentsForProgramRefresh,
  getStudentsForProgramSuperadminRefresh,
} from '../../../slices/studentSlice';
import { StyledTableRow } from '../../../components/StyledTableRow';
import { StyledTableCell } from '../../../components/StyledTableCell';
import Button from '@mui/material/Button';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import EditIcon from '@mui/icons-material/Edit';
import {
  ADD_STUDENT,
  editStudent,
  viewStudentGroceryList,
  viewStudentTask,
} from '../../../constants/routes';
import {
  getTasksForStudent,
  getStudentTasksForDate,
  setSelectedDate,
  setRecipeId,
  setNotiTime,
} from '../../../slices/taskSlice';
import AddIcon from '@mui/icons-material/Add';
import LocalGroceryStoreOutlinedIcon from '@mui/icons-material/LocalGroceryStoreOutlined';
import { USER_ROLE } from '../../../constants/userRoles';
import { getChildForParentRefresh } from '../../../slices/parentSlice';
import NoImageIcon from '@mui/icons-material/HideImage';
import { setBrowName } from '../../../slices/authSlice';

interface IStudentProfilesProps {}

const useStyles = makeStyles(formStyles);

const StudentProfiles: React.FC<IStudentProfilesProps> = ({}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const { students, selectedDate, role, counselor } = useSelector(
    (state: RootState) => {
      return {
        students: state.student.students,
        selectedDate: state.task.selectedDate,
        role: state.auth.role,
        counselor: state.counselor.counselor,
      };
    },
    shallowEqual
  );

  useEffect(() => {
    dispatch(setRecipeId(""));
    dispatch(setNotiTime({data:[]}));
  }, []);
console.warn("chk login");

const authlocal = localStorage.getItem('userRole');
const user_id = localStorage.getItem('user_id');

useEffect(() => {
  if(user_id && authlocal=="4")
  {
    dispatch(getStudentsForProgramSuperadminRefresh(user_id));
  }
  else if(user_id && authlocal=="3")
  {
    dispatch(getChildForParentRefresh(user_id, history));
  }
  else
  {
    dispatch(getStudentsForParent());
  }
  return () => {};
}, []);

localStorage.setItem('urlRoute', "parent_users");


  useEffect(() => {
    dispatch(setSelectedDate(new Date()));
  }, []);
  console.log("student details - > "+students);
  // useEffect(() => {
  //   if(students.length>0)
  //   {
  //     window.location.reload();
  //   }
  // }, []);

  useEffect(() => {
    addBrowser();
        return () => {};
      }, []);
    
     const [browserName, setBrowserName] = useState('');
     function addBrowser()
     {
       const userAgent = window.navigator.userAgent;
          if (userAgent.indexOf('Chrome') > -1) {
           setBrowserName('Google Chrome');
           console.log("test brows-->'Google Chrome'");
           dispatch(setBrowName('Google Chrome'));
  
         } else if (userAgent.indexOf('Firefox') > -1) {
           setBrowserName('Mozilla Firefox');
           console.log("test brows-->'Mozilla Firefox'");
           dispatch(setBrowName('Mozilla Firefox'));
     
         } else if (userAgent.indexOf('Safari') > -1) {
           setBrowserName('Apple Safari');
           console.log("test brows-->'Apple Safari'");
           dispatch(setBrowName('Apple Safari'));
    
         } else if (userAgent.indexOf('Edge') > -1) {
           setBrowserName('Microsoft Edge');
           console.log("test brows-->'Edge'");
           dispatch(setBrowName('Edge')); 
     
         } else  if (userAgent.indexOf('Opera') > -1 || userAgent.indexOf('OPR') > -1) {
           setBrowserName('Opera');
           console.log("test brows-->'Opera'");
           dispatch(setBrowName('Opera'));
     
         } else if (userAgent.indexOf('MSIE') > -1 || userAgent.indexOf('Trident/') > -1) {
           setBrowserName('Internet Explorer');
           console.log("test brows-->'Internet Explorer'");
           dispatch(setBrowName('Internet Explorer'));
     
         } else {
           setBrowserName('Unknown browser');
           dispatch(setBrowName('Unknown browser'));
     
         }
   
         console.log("test-->"+browserName);
         //handleSubmit();
     }
  let renderedStudents;
  if (students.length > 0) {
    renderedStudents =
      role === USER_ROLE.COUNSELOR
        ? students
            .filter((student) =>
              student.studentGroups
                .map((group) => group.name)
                .includes(counselor!.name)
            )
            .map((student, index) => {
              const { name, email, id,imageUrl, diagnoses, studentGroups } = student;
              let userimg=imageUrl==="no-url"?"https://nflyte-1.s3.amazonaws.com/ef12f40b-f758-4e9c-ab7c-acebc7e240c6.jpg":imageUrl;

              return (
                <StyledTableRow key={index} style={{width:'100%'}}>
                  {/* <StyledTableCell component='th' scope='row'>
                    {index + 1}
                  </StyledTableCell> */}
                    <StyledTableCell component="th" scope="row">
          {userimg !== '' ? (
                              <a href={userimg} target='_blank'>
                                <img
                                  src={userimg}
                                  width="50" height="50"
                                  className="rounded"
                                />
                              </a>
                            ) : (
                              <span>-</span>
                            )}
          </StyledTableCell>
                  <StyledTableCell>{name}</StyledTableCell>
                  {/* <StyledTableCell>{email}</StyledTableCell> */}
                  {/* <StyledTableCell>
                    {diagnoses &&
                      diagnoses
                        .map((diagnosis) => {
                          return diagnosis.name;
                        })
                        .toString()}
                  </StyledTableCell> */}
                  <StyledTableCell style={{textOverflow:'ellipsis'}}>
                    {studentGroups &&
                      studentGroups
                        .map((studentGroup) => {
                          return studentGroup.name;
                        })
                        .toString()}
                  </StyledTableCell>
                  <StyledTableCell>
                    <Button
                    style={{fontSize:"9px"}}
                      variant='outlined'
                      startIcon={<RemoveRedEyeIcon />}
                      onClick={(e) => {
                        // dispatch(getTasksForStudent(id));
                        dispatch(ViewTaskLogs(id));
                        dispatch(getStudentTasksForDate(id, selectedDate));
                        dispatch(getStudentById(id));
                        history.push(viewStudentTask(id));
                      }}
                    >
                      View User Task
                    </Button>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Button
                      style={{fontSize:"9px"}}

                      variant='outlined'
                      startIcon={<EditIcon />}
                      onClick={async () => {
                        await dispatch(getStudentById(id));
                        localStorage.setItem('urlRoute', "edit_user");
                        localStorage.setItem('urlId', id);
                        history.push(editStudent(id));
                      }}
                    >
                      Edit User Profile
                    </Button>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Button
                      style={{fontSize:"9px"}}
                      variant='outlined'
                      startIcon={<LocalGroceryStoreOutlinedIcon />}
                      onClick={(e) => {
                        // dispatch(getTasksForStudent(id));

                        // dispatch(getStudentTasksForDate(id, selectedDate));
                        dispatch(getStudentById(id));
                        history.push(viewStudentGroceryList(id));
                      }}
                    >
                      View Grocery List
                    </Button>
                  </StyledTableCell>
                </StyledTableRow>
              );
            })
        : students.map((student, index) => {
            const { name, email, id, diagnoses,imageUrl, studentGroups } = student;
            let userimg=imageUrl==="no-url"?"https://nflyte-1.s3.amazonaws.com/ef12f40b-f758-4e9c-ab7c-acebc7e240c6.jpg":imageUrl;

            return (
              <StyledTableRow key={index} style={{width:'100%'}}>
                {/* <StyledTableCell component='th' scope='row'>
                  {index + 1}
                </StyledTableCell> */}
                 <StyledTableCell component="th" scope="row">
                      {userimg !== '' ? (
                              <a href={userimg} target='_blank'>
                                <img
                                  src={userimg}
                                  width="50" height="50"
                                  className="rounded"
                                />
                              </a>
                            ) : (
                              <span>-</span>
                            )}
          </StyledTableCell>
                <StyledTableCell style={{textOverflow:'ellipsis'}}>{name}</StyledTableCell>
                {/* <StyledTableCell>{email}</StyledTableCell> */}
                {/* <StyledTableCell>
                  {diagnoses &&
                    diagnoses
                      .map((diagnosis) => {
                        return diagnosis.name;
                      })
                      .toString()}
                </StyledTableCell> */}
                <StyledTableCell style={{textOverflow:'ellipsis'}}>
                  {studentGroups &&
                    studentGroups
                      .map((studentGroup) => {
                        return studentGroup.name;
                      })
                      .toString()}
                </StyledTableCell>
                <StyledTableCell>
                  <Button
                      style={{fontSize:"9px"}}
                      variant='outlined'
                    startIcon={<RemoveRedEyeIcon />}
                    onClick={(e) => {
                      // dispatch(getTasksForStudent(id));
                      dispatch(ViewTaskLogs(id));
                      dispatch(getStudentTasksForDate(id, selectedDate));
                      dispatch(getStudentById(id));
                      history.push(viewStudentTask(id));
                    }}
                  >
                    View User Task
                  </Button>
                </StyledTableCell>
                <StyledTableCell>
                  <Button
                      style={{fontSize:"9px"}}
                      variant='outlined'
                    startIcon={<EditIcon />}
                    onClick={async () => {
                      await dispatch(getStudentById(id));
                      localStorage.setItem('urlRoute', "edit_user");
                      localStorage.setItem('urlId', id);
                      history.push(editStudent(id));
                    }}
                  >
                    Edit User Profile
                  </Button>
                </StyledTableCell>
                <StyledTableCell>
                  <Button
                      style={{fontSize:"9px"}}
                      variant='outlined'
                    startIcon={<LocalGroceryStoreOutlinedIcon />}
                    onClick={(e) => {
                      // dispatch(getTasksForStudent(id));

                      // dispatch(getStudentTasksForDate(id, selectedDate));
                      dispatch(getStudentById(id));
                      history.push(viewStudentGroceryList(id));
                    }}
                  >
                    View Grocery List
                  </Button>
                </StyledTableCell>
              </StyledTableRow>
            );
          });
  } else if (students.length === 0) {
    renderedStudents = (
      <StyledTableRow style={{width:'100%'}}>
        <StyledTableCell component='th' scope='row'>
          <Typography variant='subtitle2' className={classes.label}>
            no user found.
            <Link to={ADD_STUDENT} className={classes.link}>
              Add User?
            </Link>
          </Typography>
        </StyledTableCell>
      </StyledTableRow>
    );
  } else {
    renderedStudents = (
      <StyledTableRow style={{width:'100%'}}>
        <StyledTableCell component='th' scope='row'></StyledTableCell>
      </StyledTableRow>
    );
  }

  return (
    <div style={{maxWidth:'100%'}}>
      <Typography variant='h6'>
        {/* Students */}
        Users
      </Typography>
      <br />
      <Button
        variant='contained'
        color='primary'
        onClick={() => {
          history.push(ADD_STUDENT);
        }}
        startIcon={<AddIcon />}
      >
        {/* Add Student */}
        Add User
      </Button>
      <br />
      <br />
      <TableContainer component={Paper} >
        <Table aria-label='customized table'>
          <TableHead>
            <TableRow style={{width:'100%'}}>
              {/* <StyledTableCell>S.No.</StyledTableCell> */}
              <StyledTableCell>Image</StyledTableCell>
              <StyledTableCell>Name</StyledTableCell>
              {/* <StyledTableCell>Email</StyledTableCell> */}
              {/* <StyledTableCell>Diagnoses</StyledTableCell> */}
              {/* <StyledTableCell>Counselors</StyledTableCell> */}
              <StyledTableCell>Admins</StyledTableCell>
              <StyledTableCell>View User Task</StyledTableCell>
              <StyledTableCell>Edit User Profile</StyledTableCell>
              <StyledTableCell>View Grocery List</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>{renderedStudents}</TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
export default StudentProfiles;

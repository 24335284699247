import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from '../../../app/rootReducer';
import {
  ADD_RECIPE,
  ADD_TASK,
  ADD_TASK_WITH_USER,
  COUNSELOR_PROFILES,
  MESSAGING,
  PROGRAM_MANAGER_INTAKE,
  STUDENT_PROFILES,
  VIEW_CALENDAR,
} from '../../../constants/routes';
import { Button, Dialog, Grid, IconButton, ImageListItem, ImageListItemBar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import dashboardCardStyles from '../../../assets/jss/components/dashboardCardStyles';
import { getProgramForProgramManager, setProgramManger } from '../../../slices/programManagerSlice';
import { setLongLoading } from '../../../slices/authSlice';
import { setSuccessMsg } from '../../../slices/alertSlice';
import { Typography } from '@material-ui/core';
import { Auth } from 'aws-amplify';
import { AllQuestionsView } from '../../Library/Question/AllQuestionsView';
import GRAPHIC from '../../../assets/images/login_graphic.svg';
import NOTIFICATION from '../../../assets/images/Notification.svg';

import NotificationsIcon from '@mui/icons-material/Notifications';
import ChatIcon from '@mui/icons-material/Chat';
import DescriptionIcon from '@mui/icons-material/Description';
import PersonIcon from '@mui/icons-material/Person';
import FaceIcon from '@mui/icons-material/Face';
import EventIcon from '@mui/icons-material/Event';
import CookbookIcon from '@mui/icons-material/CollectionsBookmark';

import GRAPHIC_1 from '../../../assets/images/Menu/1.svg';
import GRAPHIC_2 from '../../../assets/images/Menu/2.svg';
import GRAPHIC_3 from '../../../assets/images/Menu/3.svg';
import GRAPHIC_4 from '../../../assets/images/Menu/4.svg';
import GRAPHIC_5 from '../../../assets/images/Menu/5.svg';
//import GRAPHIC_6 from '../../../assets/images/Menu/6.svg';
import GRAPHIC_6 from '../../../assets/images/Menu/cookbook.svg';

import GRAPHIC_7 from '../../../assets/images/Menu/frame1.svg';
//import GRAPHIC_7 from '../../../assets/images/Menu/77.png';

import VIDEO_1 from '../../../../src/assets/videos/programuser.mp4';
import VIDEO_2 from '../../../assets/videos/programadmin.mp4';
import VIDEO_3 from '../../../assets/videos/programtask.mp4';
import VIDEO_4 from '../../../assets/videos/programrecipe.mp4';
import { height } from '@mui/system';
import { getUserUpdateUserguid } from '../../../api/parentRequests';
import { getOnboardingLogsEntry } from '../../../slices/parentSlice';

interface IUserGuidVideosProps {}

const useStyles = makeStyles(dashboardCardStyles);

const UserGuidVideos: React.FC<
  IUserGuidVideosProps
> = ({}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();

  const [openModal, setOpenModal] = useState(false);
  //const [video, setVideo] = useState("");

  const handleClose = () => {
    setOpenModal(false);

    //(setIngredient(null));
  };
  return (
    <div>
      <Grid container direction='row'>
      <Grid
          item
          xs={12}
          md={12}
          
        >
        <Typography variant={'h6'} style={{color:'#000',padding:'15px'}}>              
                
           How To Use NFlyte
              </Typography>
            {/* <h2 style={{ fontWeight:'bold',padding:'15px'}}>How To Use NFlyte</h2> */}
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor:'pointer'
          }}
          
        >
          <div style={{ border:'1px solid #eee', padding:'15px',borderRadius:'5px',margin:'15px' }}>
            {/* <div className={classes.davBox} >
              <Typography variant={'h4'} className={classes.title}>
                
                Add User
              </Typography>
             
            </div> */}
            <video 
                width= "90%" 
                height={'300px'} 
                onClick={(e:any) => {
                  setOpenModal(true);
                 // setVideo(VIDEO_1);
                }}
                controls
              >
              <source src={VIDEO_1} type='video/mp4' />
            </video>

            <h3 style={{fontSize:'20px',textAlign:'center',
                        marginBottom:'0px',fontWeight:'bold',marginTop:'15px'}}
            >Add User</h3> 
          </div>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor:'pointer'
          }}
         
        >
          <div style={{ border:'1px solid #eee', padding:'15px',borderRadius:'5px',margin:'15px' }}>
            {/* <div className={classes.davBox}>
              <Typography variant={'h4'} className={classes.title}>
                Add Admin
              </Typography>
             
            </div> */}
            <video 
                width= "90%" 
                height={'300px'} 
                onClick={(e:any) => {
                  setOpenModal(true);
                 // setVideo(VIDEO_2);
                }}
                controls
            >
            <source src={VIDEO_2} type='video/mp4' />
            </video>
            <h3 style={{fontSize:'20px',textAlign:'center',
                        marginBottom:'0px',fontWeight:'bold',
                        marginTop:'15px'}}
            >Add Admin</h3>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor:'pointer'
          }}
   
        >
          <div style={{ border:'1px solid #eee', padding:'15px',borderRadius:'5px',margin:'15px' }}>
            <video 
                width= "90%" 
                height={'300px'} 
                onClick={(e:any) => {
                  setOpenModal(true);
                 // setVideo(VIDEO_3);
                }}
                controls
            >
            <source src={VIDEO_3} type='video/mp4' />
            </video>
            <h3 style={{fontSize:'20px',textAlign:'center',
                        marginBottom:'0px',fontWeight:'bold',
                        marginTop:'15px'}}
            >Add Task</h3>

          </div>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor:'pointer'
          }}
         
        >
          <div style={{ border:'1px solid #eee', padding:'15px',borderRadius:'5px',margin:'15px' }}>           
           <video width= "90%" 
                  height={'300px'} 
                  onClick={(e:any) => {
                   setOpenModal(true);
                   //setVideo(VIDEO_4);
              }}
              controls
          >
          <source src={VIDEO_4} type='video/mp4' /></video>
          <h3 style={{fontSize:'20px',textAlign:'center',
                      marginBottom:'0px',fontWeight:'bold',
                      marginTop:'15px'}}
          >Add Recipe</h3>
          </div>
        </Grid>
       
      </Grid>

      {/* <Dialog
      
      open={openModal}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      style={{padding: '1rem',maxWidth: '1200px'}}
      
    >
<div className='row' style={{ padding: '1rem', width: '100%',height:"100%",maxWidth: '1200px' }}>
<Grid
   container
   direction='row'
   justifyContent='space-between'
   alignItems='center'
   style={{padding:'10px'}}
 >
  
  <video src={video} style={{width:"inherit", position:'relative'}} itemType='mp4' controls />
   
</Grid>

 <div className='row' style={{ padding: '1rem', width: '100%' }}>
      
         <div className='col-sm-3 text-center'>
         <Button
           //className={classes.secondaryButton}
           variant='contained'
           color='primary'
           onClick={(e) => {setOpenModal(false)}}
         >
           Cancel
         </Button>
         </div>
   </div>
</div>

      </Dialog> */}
    </div>
  );
}; 

export default UserGuidVideos;
